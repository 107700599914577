import { gql } from "@apollo/client";

const FACEBOOKBUSINESSLOGIN = gql`
  mutation facebookBusinessLogin($accesstoken: String!) {
    facebookBusinessLogin(accesstoken: $accesstoken) {
      success
      error
    }
  }
`;
export default FACEBOOKBUSINESSLOGIN;
