/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
import Box from "src/components/shared/atoms/box";
import GET_RESPONSE from "src/graphql/query/settings/getResponse";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState, useCallback } from "react";
import { useSnackbar } from "notistack";
import LinesSkeleton from "src/components/shared/molecules/linesSkeleton";
import KuikwitDialog from "src/components/shared/atoms/dialog";
import _ from "lodash";
import useActiveUserDetail from "src/utils/shared/useActiveUserDetail";
import removeIcon from "src/assets/images/settings/close-02.svg";
import CardContent from "@mui/material/CardContent";
import { useNavigate, useSearchParams } from "react-router-dom";
import ADD_USER_CANNED_RESPONSE from "src/graphql/mutation/settings/addResponse";
import UPDATE_RESPONSE_QUERY from "src/graphql/mutation/settings/updateResponse";
import REMOVE_USER_CANNED_RESPONSE from "src/graphql/mutation/settings/removeUserCannedResponse";
import useStyles from "./Styles";
import Header from "./header";
import ListItems from "./listItems";
import Count from "./count";
import UpdateResponses from "../updateResponse";

const CannedResponses = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { mainSuperAdminId, agentId } = useActiveUserDetail();
  const [data, setData] = useState<any>([]);
  const [text, setText] = useState("");
  const [removeModal, setRemoveModal] = useState(false);
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const mode = searchParams.get("mode");

  const [
    getResponse,
    { loading: ResponseQueryLoading, data: ResponseQueryResult },
  ] = useLazyQuery(GET_RESPONSE, {
    fetchPolicy: "network-only",
  });

  const debouncedGetCannedResponses = useCallback(
    _.debounce(async (searchText) => {
      try {
        getResponse({
          variables: {
            mainSuperAdminId,
            agentId,
            type: "all",
            search: searchText,
          },
        });
      } catch {
        enqueueSnackbar("Something went wrong! Try again.", {
          variant: "error",
        });
      }
    }, 500),
    [mainSuperAdminId, agentId],
  );

  useEffect(() => {
    debouncedGetCannedResponses(text);
  }, [text]);

  useEffect(() => {
    if (ResponseQueryResult && ResponseQueryResult.getUserCannedResponses) {
      const decodeResult = ResponseQueryResult.getUserCannedResponses ?? [];

      setData(decodeResult);
    }
  }, [ResponseQueryResult]);

  const [
    updateresponse,
    { data: updateresponseQueryResult, loading: updateresponseQueryLoading },
  ] = useMutation(UPDATE_RESPONSE_QUERY);

  useEffect(() => {
    if (
      updateresponseQueryResult &&
      updateresponseQueryResult.updateUserCannedResponse
    ) {
      if (updateresponseQueryResult.updateUserCannedResponse.success) {
        const result = JSON.parse(
          updateresponseQueryResult.updateUserCannedResponse.result,
        );
        // eslint-disable-next-line eqeqeq
        setData((prev: any[]) =>
          prev.map((item: { id: string | number }) =>
            Number(item.id) === Number(result.id) ? result : item,
          ),
        );
        navigate("/settings/canned-response");
      } else {
        enqueueSnackbar(
          JSON.parse(updateresponseQueryResult.updateUserCannedResponse.error),
          {
            variant: "error",
          },
        );
      }
    }
  }, [updateresponseQueryResult]);

  // delete response
  const [
    removeUserCannedResponse,
    {
      data: removeUserCannedResponseResult,
      loading: removeUserCannedResponseLoading,
    },
  ] = useMutation(REMOVE_USER_CANNED_RESPONSE);

  useEffect(() => {
    if (
      removeUserCannedResponseResult &&
      removeUserCannedResponseResult.removeUserCannedResponse
    ) {
      if (removeUserCannedResponseResult.removeUserCannedResponse.success) {
        enqueueSnackbar("response deleted.", {
          variant: "success",
        });
        const result = JSON.parse(
          removeUserCannedResponseResult.removeUserCannedResponse.result,
        );
        // eslint-disable-next-line eqeqeq
        setData((prev: any) =>
          prev.filter((item: { id: number | string }) => item.id !== result.id),
        );
        setRemoveModal(false);
      } else {
        enqueueSnackbar(
          JSON.parse(
            removeUserCannedResponseResult.removeUserCannedResponse.error,
          ),
          {
            variant: "error",
          },
        );
      }
    }
  }, [removeUserCannedResponseResult]);

  // delete response id
  const [deleteResponseId, setDeleteResponseId] = useState(0);
  const handleDeleteResponse = async (id: number) => {
    setDeleteResponseId(id);
  };
  const handleDeleteModal = async () => {
    try {
      await removeUserCannedResponse({
        variables: {
          responseId: deleteResponseId,
        },
      });
    } catch (err: any) {
      enqueueSnackbar("Something went wrong! Try again.", {
        variant: "error",
      });
    }
  };

  // add response api
  const [
    addUserCannedResponse,
    {
      data: addUserCannedResponseQueryResult,
      loading: addUserCannedResponseloading,
    },
  ] = useMutation(ADD_USER_CANNED_RESPONSE);

  useEffect(() => {
    if (
      addUserCannedResponseQueryResult &&
      addUserCannedResponseQueryResult.addUserCannedResponse
    ) {
      if (addUserCannedResponseQueryResult.addUserCannedResponse.success) {
        enqueueSnackbar("response added", {
          variant: "success",
        });
        const result = JSON.parse(
          addUserCannedResponseQueryResult.addUserCannedResponse.result,
        );
        setData((prev: any) => [result, ...prev]);
        navigate("/settings/canned-response");
      } else {
        enqueueSnackbar(
          JSON.parse(
            addUserCannedResponseQueryResult.addUserCannedResponse.error,
          ),
          {
            variant: "error",
          },
        );
      }
    }
  }, [addUserCannedResponseQueryResult]);

  const addOrUpdateHandle = async (data: any) => {
    const { short, long, id, type } = data;
    if (mode === "add") {
      addUserCannedResponse({
        variables: {
          mainSuperAdminId,
          agentId,
          type,
          short,
          long,
        },
      });
    } else {
      updateresponse({
        variables: {
          responseId: id,
          short,
          long,
        },
      });
    }
  };

  return (
    <>
      {!mode ? (
        <Box sx={{ marginRight: 1, height: "100%", m: 0, p: 0 }}>
          <Header
            text={text}
            loading={ResponseQueryLoading}
            onSearchTextChange={setText}
          />
          <CardContent className={classes.cardContent}>
            <Count count={data.length} />
            <Box className={classes.bottomBox}>
              {ResponseQueryLoading ? (
                <LinesSkeleton noOfLines={6} />
              ) : (
                <ListItems
                  data={data}
                  loading={removeUserCannedResponseLoading}
                  handleDeleteResponse={handleDeleteResponse}
                  setRemoveModal={setRemoveModal}
                />
              )}
            </Box>
          </CardContent>
        </Box>
      ) : (
        <UpdateResponses
          handle={addOrUpdateHandle}
          mode={mode}
          loading={addUserCannedResponseloading || updateresponseQueryLoading}
        />
      )}
      {removeModal && (
        <KuikwitDialog
          btnText="Delete"
          img={removeIcon}
          modalClose={() => setRemoveModal(false)}
          modalOpen={removeModal}
          removeItem={handleDeleteModal}
          loader={removeUserCannedResponseLoading}
          text="Do you really want to delete this response?"
        />
      )}
    </>
  );
};

export default CannedResponses;
