/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable func-names */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import axios from "axios";
import ADD_PAGE from "src/graphql/mutation/settings/addPage";
import GET_PAGES from "src/graphql/query/settings/getPages";
import { useMutation, useLazyQuery } from "@apollo/client";
import NoRecordFound from "src/components/shared/molecules/NoRecordFound";
import useActiveUserDetail from "src/utils/shared/useActiveUserDetail";
import { useSnackbar } from "notistack";
import expressConfig from "src/config/express.json";
import {
  Box,
  Button,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/material";

import { FacebookFunc } from "src/utils/auth/Facebook";
import useStyle from "./Styles";
import InternalPage from "./lowerComponent";
import ExternalPage from "./upperComponent";

const Whatsapp = () => {
  const env = process.env.NODE_ENV || "development";
  const { enqueueSnackbar } = useSnackbar();
  const { classes } = useStyle();
  const [windowLoading, setWindowLoading] = useState<boolean>(true);
  const [pagesData, setPagesData] = useState<any[]>([]);

  const config = expressConfig[env];
  const { mainSuperAdminId } = useActiveUserDetail();
  const [addPages, { data: addPagesMutationResult }] = useMutation(ADD_PAGE);

  const [whatsappAdded, setWhatsappAdded] = useState(false);

  useEffect(() => {
    FacebookFunc.fbInt();

    const sessionInfoListener = (event: any) => {
      if (
        event.origin !== "https://www.facebook.com" &&
        event.origin !== "https://web.facebook.com"
      ) {
        return;
      }

      try {
        const data = JSON.parse(event.data);
        if (data.type === "WA_EMBEDDED_SIGNUP") {
          // if user finishes the Embedded Signup flow
          if (data.event === "FINISH") {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { phone_number_id, waba_id } = data.data;
            // addpages
            setPagesData([
              {
                name: "Whatsapp",
                pageId: phone_number_id,
                accesstoken: "access_token",
                picture: "",
                mainSuperAdminId,
                source: "whatsapp",
              },
            ]);

            setWhatsappAdded(true);
            setWindowLoading(false);
          }
          // if user cancels the Embedded Signup flow
          else {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { current_step } = data.data;
          }
        }
      } catch {
        // Don’t parse info that’s not a JSON
        console.log("Non JSON Response", event.data);
      }
    };

    window.addEventListener("message", sessionInfoListener);

    return () => {
      window.removeEventListener("message", sessionInfoListener);
    };
  }, []);

  useEffect(() => {
    if (addPagesMutationResult && addPagesMutationResult.addpages) {
      if (addPagesMutationResult.addpages.error) {
        enqueueSnackbar(addPagesMutationResult.addpages.error, {
          variant: "error",
        });
      }
      if (addPagesMutationResult.addpages.success) {
        enqueueSnackbar("Page(s) added", {
          variant: "success",
        });
      }
    }
  }, [addPagesMutationResult]);

  //

  const idsDataForAccessToken = async (token: any) => {
    try {
      const data = await axios.get(
        `https://graph.facebook.com/v19.0/debug_token`,
        {
          params: {
            input_token: token,
            access_token: `${config.facebook_app_id}|${config.FACEBOOK_APP_SECRET}`,
          },
        },
      );
      return data.data;
    } catch (error) {
      enqueueSnackbar("Something went wrong.", {
        variant: "error",
      });
    }
  };

  const getAccessTokenByCode = async (code: any) => {
    try {
      const data = await axios.get(
        `https://graph.facebook.com/v17.0/oauth/access_token?client_id=${config.facebook_app_id}&client_secret=${config.FACEBOOK_APP_SECRET}&code=${code}`,
      );
      console.log("acessToken in exchange of code", data.data);
      const idsData = await idsDataForAccessToken(data.data.access_token);
      console.log("ids data for accessToken", idsData.data);
    } catch (error) {
      console.log("error in token access", error);
    }
  };

  const getLoginStatus = async () => {
    if (window.FB) {
      window.FB.getLoginStatus(async function (response: any) {
        console.log("getLoginStatus__response", response);
        if (response.status === "connected") {
          const { accessToken } = response.authResponse;
          //
          if (accessToken) await idsDataForAccessToken(accessToken);
          //
        } else if (response.status === "not_authorized") {
          console.log(
            "fb_authentication_accessToken error not authorized",
            response,
          );
        } else {
          console.log("fb_authentication_accessToken error");
        }
      }, true);
    }
  };
  const launchWhatsAppSignup = async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      (await window.fbq) &&
        window.fbq("trackCustom", "WhatsAppOnboardingStart", {
          appId: config.facebook_app_id,
          feature: "whatsapp_embedded_signup",
        });

      // Define an async function for the response handling
      const handleFBResponse = async (response: any) => {
        console.log("response after flow", response);
        if (response.authResponse) {
          const { code } = response.authResponse;
          if (code) {
            await getAccessTokenByCode(code);
          }
          await getLoginStatus();
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      };

      // Launch Facebook login with a regular function that calls the async handler
      window.FB.login(
        function (response: any) {
          handleFBResponse(response).catch((e) => {
            console.error("Error handling FB response", e);
          });
        },
        {
          config_id: config.WHATSAPP_CONFIG_ID,
          response_type: "code", // must be set to 'code' for System User access token
          override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
          extras: {
            feature: "whatsapp_embedded_signup",
            sessionInfoVersion: 2, //  Receive Session Logging Info
          },
        },
      );
    } catch (e) {
      enqueueSnackbar("Something went wrong.", {
        variant: "error",
      });
    }
  };
  const [
    getPages,
    { loading: getPagesQueryLoading, data: getPagesQueryResult },
  ] = useLazyQuery(GET_PAGES, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    getPages({
      variables: {
        mainSuperAdminId,
        source: "whatsapp",
        onlySelected: false,
      },
    });
  }, [mainSuperAdminId]);

  useEffect(() => {
    const pages: any = [];
    if (getPagesQueryResult && getPagesQueryResult.getAllPages) {
      getPagesQueryResult.getAllPages.forEach((curr: any, index: number) =>
        pages.push({
          order: index,
          ...curr,
        }),
      );
      setPagesData(pages);
    }
  }, [getPagesQueryResult]);
  return (
    <>
      {getPagesQueryLoading ? (
        <Box
          sx={{
            height: "calc(100vh - 200px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <ExternalPage handleClick={launchWhatsAppSignup} />
          {!getPagesQueryLoading && pagesData.length > 0 && (
            <InternalPage pagesData={pagesData} setPagesData={setPagesData} />
          )}
          <Box mt={6}>
            {!getPagesQueryLoading && pagesData.length === 0 && (
              <NoRecordFound />
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default Whatsapp;
