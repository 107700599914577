/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import useActiveUserDetail from "src/utils/shared/useActiveUserDetail";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Badge, Tab, Tabs, Typography } from "@mui/material";
import { useLazyQuery, useSubscription } from "@apollo/client";
import NOTIFICATION_FOUND from "src/graphql/subscriptions/chat/notificationFound";
import GETNOTIFICATIONS from "src/graphql/query/chat/getNotifications";
import { ContextProvider } from "src/context/chats";
import audioSrc from "src/assets/sounds/light-hearted-message-tone.mp3";
import { enqueueSnackbar } from "notistack";
import NotificationsList from "./notificationList";
import useStyles from "./Styles";

const Notification = () => {
  const { classes } = useStyles();
  const { agentId } = useActiveUserDetail();
  const [audio] = useState(new Audio(audioSrc));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [allNotificationData, setAllNotificationData] = useState<any[]>([]);
  const [unreadNotificationData, setUnreadNotificationData] = useState<any[]>(
    [],
  );

  const [tabValue, setTabValue] = useState(0);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const menuHandle = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const { data: notificationFoundSubscriptionResult } =
    useSubscription(NOTIFICATION_FOUND);
  useEffect(() => {
    if (
      notificationFoundSubscriptionResult &&
      notificationFoundSubscriptionResult.notificationFound
    )
      if (notificationFoundSubscriptionResult.notificationFound.success) {
        const newNotificationArray = JSON.parse(
          notificationFoundSubscriptionResult.notificationFound.result,
        );
        let newNotification: any[] = [];
        for (const item of newNotificationArray) {
          if (Number(agentId) === item.agentId) {
            audio.play();
            newNotification = [...newNotification, item];
          }
        }
        if (newNotification.length === 0) return;
        setAllNotificationData([...allNotificationData, ...newNotification]);
        setUnreadNotificationData([
          ...unreadNotificationData,
          ...newNotification,
        ]);
      } else {
        enqueueSnackbar(
          notificationFoundSubscriptionResult.notificationFound.error,
          {
            variant: "error",
          },
        );
      }
  }, [notificationFoundSubscriptionResult]);

  const [
    getNotifications,
    { loading: getNotificationsLoading, data: getNotificationsResult },
  ] = useLazyQuery(GETNOTIFICATIONS, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    if (open) getNotifications();
  }, [open]);

  useEffect(() => {
    if (getNotificationsResult?.getNotifications) {
      if (getNotificationsResult.getNotifications.success) {
        const data = JSON.parse(getNotificationsResult.getNotifications.result);
        setAllNotificationData(data);
        setUnreadNotificationData(
          data.filter((item: { read: boolean }) => item.read === false),
        );
      } else {
        enqueueSnackbar(getNotificationsResult.getNotifications.error, {
          variant: "error",
        });
      }
    }
  }, [getNotificationsResult]);
  return (
    <ContextProvider>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <IconButton
          disableTouchRipple
          disableRipple
          onClick={handleClick}
          id="notification"
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Badge color="primary" badgeContent={unreadNotificationData.length}>
            <NotificationsIcon className={classes.icon} />
          </Badge>
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="notification"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            width: "300px",
            height: "500px",
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={menuHandle}
          sx={{ "&:hover": { backgroundColor: "white" } }}
        >
          <Typography>Notifications</Typography>
          <Divider />
        </MenuItem>{" "}
        <MenuItem
          disableTouchRipple
          onClick={menuHandle}
          sx={{ "&:hover": { backgroundColor: "white" } }}
        >
          <Tabs value={tabValue} onChange={handleChange}>
            <Tab sx={{ width: 10 }} label="Unread" disableTouchRipple />
            <Tab sx={{ width: 10 }} label="All" disableTouchRipple />
          </Tabs>
        </MenuItem>
        <Divider />
        <MenuItem
          disableTouchRipple
          disableRipple
          sx={{ "&:hover": { backgroundColor: "white" } }}
        >
          {tabValue === 0 && (
            <NotificationsList
              name="unread"
              notificationData={unreadNotificationData}
              setNotificationData={setUnreadNotificationData}
              loading={getNotificationsLoading}
            />
          )}
          {tabValue === 1 && (
            <NotificationsList
              name="all"
              setUnreadNotificationData={setUnreadNotificationData}
              notificationData={allNotificationData}
              setNotificationData={setAllNotificationData}
              loading={getNotificationsLoading}
            />
          )}
        </MenuItem>
      </Menu>
    </ContextProvider>
  );
};

export default Notification;
