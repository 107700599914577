import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useStyle from "../generalLabels/Styles";

interface Props {
  text: string;
}

const Header: React.FC<Props> = ({ text }) => {
  const { classes } = useStyle();

  return (
    <Box>
      <Typography component="p" className={classes.subHeading}>
        {text}
      </Typography>
    </Box>
  );
};

export default Header;
