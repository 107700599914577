import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  connection: true,
};

const connectionSlice = createSlice({
  initialState,
  name: "CONNECTION",
  reducers: {
    connectionHandler: (state, action) => {
      return {
        ...state,
        connection: action.payload,
      };
    },
  },
});

export const { connectionHandler } = connectionSlice.actions;
export default connectionSlice.reducer;
