import noDataImg from "src/assets/images/dashboard/no-data.jpg";
import { Typography, Box } from "@mui/material";

const NotSelected = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "80%",
        alignItems: "center",
        maxWidth: "270px",
        margin: "auto",
      }}
    >
      <img
        src={noDataImg}
        alt="no data found"
        style={{
          width: "100%",
          marginBottom: "-35px",
          pointerEvents: "none",
        }}
      />
      <Typography sx={{ fontSize: "14px", color: "#B2B2B2" }}>
        No Section Selected
      </Typography>
    </Box>
  );
};

export default NotSelected;
