import * as React from "react";
import { Typography, ListItem, Box, ListItemText, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
// import removeIcon from "src/assets/images/settings/close-02.svg";
// import KuikwitDialog from "src/components/shared/atoms/dialog";
import DeleteIcon from "@mui/icons-material/Delete";
import useActiveUserDetail from "src/utils/shared/useActiveUserDetail";
import { useState } from "react";
import clsx from "clsx";
import useStyle from "./Styles";
// import AddNewModal from "../../header/modal";

interface Props {
  loading: boolean;
  // removeModal: boolean;
  setRemoveModal: any;
  response: any;
  // handleResponse: any;
  handleDeleteResponse: any;
}

const Response: React.FC<Props> = ({
  response,
  // handleResponse,
  // loading,
  handleDeleteResponse,
  // removeModal,
  setRemoveModal,
}) => {
  const [hovered, setHovered] = useState(false);
  const { panelType } = useActiveUserDetail();
  const navigate = useNavigate();
  const { id, long, short, type } = response ?? "";
  const PERSONAL_TYPE = "personal";
  const SUPERADMIN = "SUPERADMIN";
  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  // const deleteItem = () => {
  //   handleDeleteResponse(id);
  // };

  const editResponse = () => {
    navigate(`/settings/canned-response?mode=edit`, {
      state: { response },
    });
    // setOpenModal(true);
  };

  const deleteFunc = () => {
    setRemoveModal(true);
    handleDeleteResponse(id);
  };

  const { classes } = useStyle();
  return (
    <>
      <ListItem
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={clsx(
          classes.listStyle,
          type === PERSONAL_TYPE
            ? classes.backgroundPersonal
            : classes.backgroundGeneric,
        )}
      >
        <ListItemText
          primary={
            <Typography
              sx={{
                display: "block",
                padding: 1,
                fontWeight: 400,
                fontSize: "14px",
              }}
              variant="body2"
            >
              {long}
            </Typography>
          }
          secondary={
            <Chip
              label={`# ${short}`}
              variant="outlined"
              sx={{
                marginLeft: 1,
                fontWeight: 500,
                fontSize: "14px",
                color: "#272525",
                borderColor: "#272525",
                backgroundColor: "transparent", // Ensures Chip background color stays transparent
                borderRadius: 1, // Adjust as needed
                padding: "4px 8px", // Adjust as needed
                "& .MuiChip-label": {
                  padding: 0, // Removes default padding from the label
                },
                // Ensure the Chip does not inherit hover styles
                "&:hover": {
                  backgroundColor: "transparent",
                  borderColor: "#272525",
                },
              }}
            />
          }
        />
        {(panelType === SUPERADMIN || type === PERSONAL_TYPE) && (
          <Box className={`${classes.iconBox} ${!hovered && classes.hover2}`}>
            <EditIcon onClick={editResponse} className={classes.icon} />
            <DeleteIcon className={classes.icon} onClick={deleteFunc} />
          </Box>
        )}
      </ListItem>
      {/* {openModal && (
        <AddNewModal
          type="edit"
          handleResponse={handleResponse}
          response={response}
          openModal={openModal}
          setOpenModal={setOpenModal}
          loading={loading}
        />
      )} */}
      {/* {removeModal && (
        <KuikwitDialog
          btnText="Delete"
          img={removeIcon}
          modalClose={() => setRemoveModal(false)}
          modalOpen={removeModal}
          removeItem={deleteItem}
          loader={loading}
          text="Do you really want to delete this response?"
        />
      )} */}
    </>
  );
};

export default Response;
