const breakpoints = {
  values: {
    xs: 0,
    sxm: 325,
    pxm: 375,
    lxm: 425,
    xsm: 490,
    sm: 600,
    xmd: 750,
    md: 900,
    lg: 1200,
    mxl: 1400,
    xl: 1536,
  },
};

export default breakpoints;
