/* eslint-disable prettier/prettier */
import React, { useRef } from "react";
import { Search } from "@mui/icons-material";
import { Box } from "@mui/material";
import clsx from "clsx";
import useStyles, { StyledInputBase } from "./Styles";

interface Props {
  type?: string;
  placeholder?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  fieldClass?: string;
  searchBoxClass?: any;
  showEndIcon?: boolean;
  endIcon?: any;
  crossHandle?: () => void;
  //
  value?: string;
  //
  onkeydown?: any;
}

const SearchField: React.FC<Props> = ({
  type,
  onChange,
  placeholder,
  fieldClass,
  searchBoxClass,
  showEndIcon,
  endIcon,
  crossHandle,
  //
  value,
  //
  onkeydown,
}: Props) => {
  const { classes } = useStyles();

  const handleClose = () => {
    crossHandle?.();
  };
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    inputRef.current?.focus();
  };
  return (
    <Box className={clsx(classes.search, searchBoxClass)} onClick={handleClick}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: "8px",
          width: "100%",
          height: "40px",
        }}
      >
        <Box>
          <Box className={classes.searchIcon}>
            <Search />
          </Box>

          <StyledInputBase
            inputRef={inputRef}
            autoFocus
            onKeyDown={onkeydown}
            value={value}
            placeholder={placeholder}
            className={fieldClass}
            type={type}
            onChange={onChange}
            inputProps={{
              "aria-label": "search",
              style: {
                width: "100%",
                paddingRight: "10px",
                //
              },
            }}
          />
        </Box>
        <Box>
          {showEndIcon && (
            <Box className={classes.closeIcon} onClick={handleClose}>
              {endIcon}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SearchField;

SearchField.defaultProps = {
  value: "",
  //
  type: "",
  searchBoxClass: "",
  placeholder: "",
  //
  showEndIcon: false,
  endIcon: undefined,
  crossHandle: () => {},
  //
  onkeydown: () => {},
};
