import React from "react";
import { Box, Typography, Button } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useTheme } from "@mui/material/styles";
import expressConfig from "src/config/express.json";

const NotConnectedBox = () => {
  const theme = useTheme();
  const env = process.env.NODE_ENV || "development";
  const config = expressConfig[env];

  const instagramLoginUrl = `https://www.facebook.com/v20.0/dialog/oauth?client_id=${config.facebook_app_id}&display=page&extras={"setup":{"channel":"IG_API_ONBOARDING"}}&redirect_uri=${config.INSTAGRAM_LOGIN_FLOW_REDIRECT_URL}&response_type=token&scope=instagram_basic,instagram_manage_messages,pages_show_list,pages_read_engagement,pages_manage_metadata`;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        textAlign: "center",
        gap: theme.spacing(2),
      }}
    >
      <InstagramIcon sx={{ fontSize: 60, color: "#cf02cb" }} />
      <Typography variant="h6">Connect your Instagram account</Typography>
      <Button
        variant="contained"
        href={instagramLoginUrl}
        sx={{
          backgroundColor: "#cf02cb",
          "&:hover": {
            backgroundColor: "#cf02cb",
          },
        }}
      >
        Connect To Instagram
      </Button>
    </Box>
  );
};

export default NotConnectedBox;
