/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import CardContent from "@mui/material/CardContent";
import "react-phone-input-2/lib/style.css";
import {
  Box,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { useMutation } from "@apollo/client";
import DISCONNECT_TWILIO from "src/graphql/mutation/settings/disconnectTwilio";
import Button from "src/components/shared/atoms/button";
import NoRecordFound from "src/components/shared/molecules/NoRecordFound";
import { useSnackbar } from "notistack";
import { TypesForPhoneNumber, PropsTwilioPhoneConfiguration } from "../Types";
import TwilioBtn from "./button";
import useStyle from "./Styles";

const TwilioPhoneConfiguration: React.FC<PropsTwilioPhoneConfiguration> = ({
  setIsAuth,
  twiliodata,
}) => {
  const {
    accountSID = "",
    authToken = "",
    phoneNumber = "",
  } = twiliodata || {};
  const [phnNumber, setPhnNumber] = useState<TypesForPhoneNumber[] | string>(
    phoneNumber,
  );
  const { classes } = useStyle();
  const { enqueueSnackbar } = useSnackbar();

  const [
    disconnectTwilioConfiguration,
    {
      data: disconnectTwilioConfigurationQueryResult,
      loading: disconnectTwilioConfigurationLoading,
    },
  ] = useMutation(DISCONNECT_TWILIO);

  useEffect(() => {
    if (
      disconnectTwilioConfigurationQueryResult &&
      disconnectTwilioConfigurationQueryResult.disconnectTwilioConfiguration
    ) {
      if (
        disconnectTwilioConfigurationQueryResult.disconnectTwilioConfiguration
          .success
      ) {
        setIsAuth(false);
      } else {
        enqueueSnackbar(
          disconnectTwilioConfigurationQueryResult.disconnectTwilioConfiguration
            .error,
          {
            variant: "error",
          },
        );
      }
    }
  }, [disconnectTwilioConfigurationQueryResult]);

  const disconnectTwilioConf = async () => {
    try {
      await disconnectTwilioConfiguration({
        variables: {
          accountSID,
          authToken,
        },
      });
    } catch (err: any) {
      enqueueSnackbar("Something went wrong! Try again.", {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Box className={classes.heading}>Twilio | Messages</Box>
      <CardContent className={classes.cardContent}>
        <Box className={classes.buttonBox}>
          <Grid container spacing={2} alignItems="center" justifyContent="space-between">
            <Grid item xs={12} sm={6}>
              <Box className={classes.heading}>
                Status: <span className={classes.connected}>connected</span>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} container justifyContent={{ sm: 'flex-end' }}>
              <Button
                variant="contained"
                className={classes.disconnectButton}
                handleClick={disconnectTwilioConf}
                circular={disconnectTwilioConfigurationLoading}
              >
                Disconnect
              </Button>
            </Grid>
          </Grid>

        </Box>

        <Box mt={2}>
          <Divider sx={{ mt: 2 }} />
          <TableContainer className={classes.tableContainer}>
            <Table className={classes.tableStyle}>
              <TableBody>
                {phnNumber &&
                  Array.isArray(phnNumber) &&
                  phnNumber.length > 0 ? (
                  phnNumber.map((curr: TypesForPhoneNumber) => (
                    <TableRow key={curr.phoneNumber} sx={{ height: 50, ml: 0 }}>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <PhoneIphoneIcon color="primary" sx={{ mr: 1 }} />
                        {curr.phoneNumber}
                      </TableCell>
                      <TableCell sx={{ p: 0, m: 0, }} align="right">
                        <TwilioBtn
                          phnNumber={phnNumber}
                          setPhnNumber={setPhnNumber}
                          data={curr}
                          accountSID={accountSID}
                          authToken={authToken}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <Box mt={5}>
                    <NoRecordFound />
                  </Box>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </CardContent>
    </>
  );
};

export default TwilioPhoneConfiguration;
