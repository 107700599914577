/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable consistent-return */
/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import axios from "axios";
import ADD_PAGE from "src/graphql/mutation/settings/addPage";
import { useMutation, useLazyQuery } from "@apollo/client";
import NoRecordFound from "src/components/shared/molecules/NoRecordFound";
import Alert from "@mui/material/Alert";
import GET_PAGES from "src/graphql/query/settings/getPages";
import useActiveUserDetail from "src/utils/shared/useActiveUserDetail";
import { useSnackbar } from "notistack";
import { Box } from "@mui/material";
import CircularProgress from "src/components/shared/atoms/circularProgress/CircularProgress";
import useStyle from "./Styles";
import InstagramInternalPage from "./lowerComponent";
import ExternalPage from "./upperComponent";
import { User } from "../facebook/upperComponent/types";

const Instagram = () => {
  const SOURCE = "instagram";
  const { enqueueSnackbar } = useSnackbar();
  const { classes } = useStyle();
  const [pagesData, setPagesData] = useState<any[]>([]);
  const [loggedInUserDetail, setloggedInUserDetail] = useState<User | null>(
    null,
  );
  const [pageExistingError, setPageExistingError] = useState<string | null>(
    null,
  );

  const { mainSuperAdminId } = useActiveUserDetail();
  const [addPages, { data: addPagesMutationResult }] = useMutation(ADD_PAGE);
  useEffect(() => {
    if (addPagesMutationResult && addPagesMutationResult.addpages) {
      if (addPagesMutationResult.addpages.error) {
        setPageExistingError(JSON.parse(addPagesMutationResult.addpages.error));
      }
    }
  }, [addPagesMutationResult]);

  const getDetails = async (token: string) => {
    try {
      const response = await axios.get(
        `https://graph.facebook.com/v20.0/me/accounts?fields=id%2Cname%2Caccess_token%2Cinstagram_business_account{id,profile_picture_url}&access_token=${token}`,
      );
      return response.data.data;
    } catch (error) {
      enqueueSnackbar("Something went wrong. in getDetails", {
        variant: "error",
      });
    }
  };

  const subscribeToInstagramWebhooks = async (id: any, accessToken: any) => {
    try {
      await axios.post(
        `https://graph.facebook.com/${id}/subscribed_apps?subscribed_fields=messages,messaging_postbacks,message_reads,messaging_payments,message_deliveries,message_echoes,messaging_checkout_updates,standby,messaging_handovers,message_reactions,feed,inbox_labels,messaging_feedback&access_token=${accessToken}`,
      );
    } catch (error) {
      enqueueSnackbar("Something went wrong. in subscribeToInstagramWebhooks", {
        variant: "error",
      });
    }
  };
  useEffect(() => {
    const fetchData = async (long_lived_token: string) => {
      try {
        const user = await axios.get(
          `https://graph.facebook.com/me?access_token=${long_lived_token}&fields=id,name,email,picture.width(640).height(640)`,
        );
        setloggedInUserDetail(user.data);
        console.log(user, "user");
        const res: any = await getDetails(long_lived_token);
        const addedPageObjectValue: any = [];
        await res.forEach(async (item: any) => {
          if ("instagram_business_account" in item) {
            await subscribeToInstagramWebhooks(item.id, item.access_token);
          }
        });
        console.log(res, "res");

        res.forEach((item: any) => {
          if ("instagram_business_account" in item) {
            // setInstagramPageAdded(true);
            addedPageObjectValue.push({
              name: item.name,
              pageId: item.instagram_business_account.id,
              accesstoken: item.access_token,
              picture: item?.picture?.data?.url,
              mainSuperAdminId,
              source: SOURCE,
              email: user.data.email,
            });
          }
        });
        console.log(addedPageObjectValue, "addedPageObjectValue");

        if (addedPageObjectValue.length > 0) {
          addPages({
            variables: {
              input: addedPageObjectValue,
            },
          });
          setPagesData((prev: any[]) => {
            // Filter new page IDs that are not in the previous state
            const newPages = addedPageObjectValue.filter(
              (item: { pageId: any }) => !prev.some((i: any) => i.pageId === item.pageId)
            );

            // Get removed page IDs for the logged-in user
            const removedPageIds = prev
              .filter((item: { email: string, pageId: string }) => item.email === user.data.email)
              .filter((item: { pageId: string }) => !addedPageObjectValue.some((j: { pageId: string }) => j.pageId === item.pageId))
              .map((item: { pageId: string }) => item.pageId);

            // Update the existing pages with the new `isSelected` status
            const updatedPages = prev.map((j: { pageId: any }) => {
              if (removedPageIds.includes(j.pageId)) {
                return {
                  ...j,
                  isSelected: false,
                };
              }
              return j;
            });

            // Return the combined array of new and updated pages
            return [...newPages, ...updatedPages];
          });
        }
      } catch (error) {
        enqueueSnackbar("Something went wrong. in fetchData", {
          variant: "error",
        });
      }
    };

    // Extract the hash part of the URL
    const hash = window.location.hash.substring(1); // Remove the leading `#`

    // Split the parameters
    const paramsArray = hash.split("&");

    // Convert to an object
    const paramsObject = paramsArray.reduce((acc: any, current: any) => {
      const [key, value] = current.split("=");
      acc[key] = decodeURIComponent(value);
      return acc;
    }, {});

    const token = paramsObject?.long_lived_token ?? paramsObject.access_token;
    if (token) {
      fetchData(token);
    }
  }, []);

  const [
    getPages,
    { loading: getPagesQueryLoading, data: getPagesQueryResult },
  ] = useLazyQuery(GET_PAGES, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    getPages({
      variables: {
        mainSuperAdminId,
        source: "instagram",
        onlySelected: false,
      },
    });
  }, [mainSuperAdminId, pageExistingError]);

  useEffect(() => {
    const pages: any = [];
    if (getPagesQueryResult && getPagesQueryResult.getAllPages) {
      getPagesQueryResult.getAllPages.forEach((curr: any, index: number) =>
        pages.push({
          order: index,
          ...curr,
        }),
      );
      setPagesData(pages);
    }
  }, [getPagesQueryResult]);

  return (

    <>
      {getPagesQueryLoading ? (
        <Box
          sx={{
            height: "calc(100vh - 200px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <ExternalPage
            loggedInUserDetail={loggedInUserDetail}
            setloggedInUserDetail={setloggedInUserDetail}
          />
          {pageExistingError && (
            <Alert severity="warning">{pageExistingError}</Alert>
          )}
          {!getPagesQueryLoading && pagesData.length > 0 && (
            <Box
              className={loggedInUserDetail ? classes.dynamicHeightLoggedIn : classes.dynamicHeightLoggedOut}>
              <InstagramInternalPage
                pagesData={pagesData}
                setPagesData={setPagesData}
              />
            </Box>
          )}

          {!getPagesQueryLoading && pagesData.length === 0 && (
            <Box mt={6}>
              <NoRecordFound />
            </Box>
          )}
        </>)}
    </>
  );
};

export default Instagram;
