/* eslint-disable prettier/prettier */
import { Grid, Typography } from "@mui/material";
import React from "react";
import Box from "src/components/shared/atoms/box";
import useStyles from "./Styles";
import Logo from "./logo";
import PreloadedBackground from "./Prel";

const LoginLeftContainer: React.FC = () => {
  const { classes } = useStyles();

  return (
    <Grid
      container
      sx={{ height: "100vh", width: "100vw", overflow: "hidden" }}
    >
      <PreloadedBackground>
        <Logo />
        <Box className={classes.box}>
          <Typography className={classes.text}>
            <span className={classes.heading}>Get Started in a few clicks</span>
            <span className={classes.green}>
              Start your 14-day free trial today
            </span>{" "}
            to boost your customer service while discovering about Kuikwit's
            capabilities. &nbsp; Chats from all channels at one place.
          </Typography>
        </Box>
      </PreloadedBackground>
    </Grid>
  );
};

export default LoginLeftContainer;
