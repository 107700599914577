/* eslint-disable prettier/prettier */
import AddIcon from "@mui/icons-material/Add";
import Box from "src/components/shared/atoms/box";
import SearchField from "src/components/shared/atoms/SearchField";
import Button from "src/components/shared/atoms/button";
import { CircularProgress, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useStyles from "../Styles";

interface Props {
  onSearchTextChange: (value: string) => void;
  loading: boolean;
  text: string;
}
const Header: React.FC<Props> = ({ onSearchTextChange, loading, text }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  // for search bar
  const searchBarHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSearchTextChange(e.target.value);
  };

  const searchFieldProps = {
    value: text,
    onChange: searchBarHandle,
    placeholder: "search",
    fieldClass: classes.fieldStyle,
    showEndIcon: true,
    endIcon: text && loading && (
      <CircularProgress size={20} sx={{ marginRight: "10px" }} />
    ),
  };

  const handleAddResponse = () => {
    navigate(`/settings/canned-response?mode=add`);
  };
  return (
    <>
      <Box className={classes.box}>Canned | Responses</Box>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        mt={1}
      >
        <Grid item xs={12} sm={6} lg={3}>
          <Button
            variant="contained"
            type="submit"
            sx={{ width: "100%", height: 40 }}
            handleClick={handleAddResponse}
          >
            <AddIcon className={classes.addIcon} />
            <span className={classes.span}>Add new response</span>
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Box sx={{ width: "100%", height: 40 }}>
            <SearchField {...searchFieldProps} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Header;
