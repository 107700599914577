import { gql } from "@apollo/client";

const ResponseQuery = gql`
  query getUserCannedResponses(
    $mainSuperAdminId: ID!
    $agentId: ID!
    $type: String!
    $search: String
  ) {
    getUserCannedResponses(
      mainSuperAdminId: $mainSuperAdminId
      agentId: $agentId
      type: $type
      search: $search
    ) {
      id
      short
      long
      mainSuperAdminId
      agentId
      createdAt
      type
    }
  }
`;
export default ResponseQuery;
