import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  backButton: {
    marginRight: 2,
    padding: 0,
    cursor: "pointer",
    border: "2px solid transparent",
    color: "black",
    "&:hover": {
      opacity: 0.7,
    },
  },
  // box: { p: 0, m: 0, fontWeight: "bold", fontSize: 16 },
  box: {
    display: "flex",
    alignItems: "center",
    p: 0,
    m: 0,
    fontWeight: "bold",
    fontSize: 16,
    cursor: "pointer",
  },
  root: {
    marginTop: "2px",
    paddingBottom: 5,
    maxHeight: "80vh",
    minHeight: "60vh",
    // marginRight: 30,
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc",
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
  },
  labelContainer: {
    height: "auto",
  },
}));
export default useStyles;
