import { gql } from "@apollo/client";

const REGISTER_MUTATION = gql`
  mutation register(
    $username: String!
    $password: String!
    $confirmedPassword: String!
    $email: String!
  ) {
    register(
      username: $username
      password: $password
      confirmedPassword: $confirmedPassword
      email: $email
    ) {
      success
      error
    }
  }
`;
export default REGISTER_MUTATION;
