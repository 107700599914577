import React from "react";
import { Box, Typography, Button } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useTheme } from "@mui/material/styles";

const ExternalPage = ({ handleClick }: { handleClick: any }) => {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          p: 0,
          m: 0,
          fontWeight: "bold",
          fontSize: 16,
        }}
      >
        Whatsapp | Messenger
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: theme.spacing(1),
          backgroundColor: theme.palette.background.paper,
          textAlign: "center",
          gap: theme.spacing(2),
        }}
      >
        <WhatsAppIcon sx={{ fontSize: 60, color: "#25D366" }} />
        <Typography variant="h6">Connect your WhatsApp account</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClick}
          sx={{
            backgroundColor: "#25D366",
            "&:hover": {
              backgroundColor: "#128C7E",
            },
          }}
        >
          Connect To WhatsApp
        </Button>
      </Box>
    </>
  );
};

export default ExternalPage;
