import { gql } from "@apollo/client";

const CONNECT_PAGE = gql`
  mutation connectPage($pageId: String!) {
    connectPage(pageId: $pageId) {
      success
      error
      result
    }
  }
`;

export default CONNECT_PAGE;
