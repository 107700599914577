import { gql } from "@apollo/client";

const DISCONNECT_TWILIO_PHONE = gql`
  mutation disconnectTwilioPhoneNumber(
    $accountSID: String!
    $authToken: String!
    $phoneNumber: String!
  ) {
    disconnectTwilioPhoneNumber(
      accountSID: $accountSID
      authToken: $authToken
      phoneNumber: $phoneNumber
    ) {
      success
      error
    }
  }
`;

export default DISCONNECT_TWILIO_PHONE;
