import { useState, useEffect, ReactNode } from "react";
import background from "src/assets/images/auth/Backgraound.svg";

interface IProps {
  children: ReactNode;
}
const PreloadedBackground: React.FC<IProps> = ({ children }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };
    img.src = background;

    return () => {
      img.onload = null;
    };
  }, []);

  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        opacity: imageLoaded ? 1 : 0, // Show the image when it's loaded
        transition: "opacity 0.5s ease-out",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", // Ensures the image covers the entire container
        width: "100%",
        height: "100vh",
      }}
    >
      {children}
    </div>
  );
};

export default PreloadedBackground;
