import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  helperText: {
    color: "red !important",
  },
  textField: {
    width: "100%",
    "&: focus": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));
export default useStyles;
