import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  root: {
    height: "40px",
    margin: "10px 0px 10px 0px",
    borderRadius: "5px",
    textTransform: "capitalize",
  },
}));
export default useStyles;
