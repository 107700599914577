import { gql } from "@apollo/client";

const DELETE_PAGE = gql`
  mutation deletepage($pageId: String!) {
    deletepage(pageId: $pageId) {
      success
      error
    }
  }
`;

export default DELETE_PAGE;
