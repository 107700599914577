import { gql } from "@apollo/client";

const GET_TWILIO_CONFIG = gql`
  query getTwilioConfiguration {
    getTwilioConfiguration {
      authToken
      accountSID
      phoneNumber {
        phoneNumber
        active
      }
    }
  }
`;

export default GET_TWILIO_CONFIG;
