import { gql } from "@apollo/client";

const DIS_CONNECT_PAGE = gql`
  mutation disconnectPage($pageId: String!) {
    disconnectPage(pageId: $pageId) {
      success
      error
      result
    }
  }
`;

export default DIS_CONNECT_PAGE;
