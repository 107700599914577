import { gql } from "@apollo/client";

const REMOVE_USER_CANNED_RESPONSE = gql`
  mutation removeUserCannedResponse($responseId: ID!) {
    removeUserCannedResponse(responseId: $responseId) {
      success
      error
      result
    }
  }
`;

export default REMOVE_USER_CANNED_RESPONSE;
