import { makeStyles } from "tss-react/mui";

const useStyle = makeStyles()((theme) => ({
  outlinedInput: {
    maxWidth: "200px",
    width: "100%",
    height: "40px",
    "& input::-webkit-input-placeholder": {
      fontSize: "12px",
      lineHeight: "18px",
      fontWeight: "400",
    },
  },
  closeIcon: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
  connected: {
    color: "green",
  },
  heading: {
    p: 0,
    m: 0,
    fontWeight: "bold",
    fontSize: 16,
  },
  tableContainer: {
    maxHeight: "calc(100vh - 300px)",
  },
  addIcon: {
    fontSize: "15px",
    marginRight: "6.8px",
  },
  buttonBox: {
    display: "flex",
    marginTop: 20,
  },
  cardContent: {
    padding: 0,
    paddingTop: "10px",
  },
  formikBox: {
    display: "flex",
    flexDirections: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  addButton: {
    fontSize: "12px",
    fontWeight: "500",
    marginLeft: 10,
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
      marginLeft: 0,
    },
  },
  tableStyle: {
    width: "100%;",
    // [theme.breakpoints.down("md")]: {
    //   width: "95%",
    // },
  },
  disconnectButton: {
    width: "131px",
    height: "32px",
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: "500",
    textTransform: "none",
    // background: "rgba(227, 77, 89, 1)",
  },
}));

export default useStyle;
