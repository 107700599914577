import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  skeletonBox: {
    width: "100%",
    padding: "5px",
  },
  itemSkeleton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: "5px",
    padding: "5px",
    marginBottom: "5px",
    height: "70px",
  },
  closedIcon: {
    alignSelf: "end",
    width: "45%",
    borderRadius: "50%",
  },
  avatar: {
    borderRadius: "50%",
    padding: "12%",
    justifySelf: "center",
    alignSelf: "center",
  },
  textBox: {
    display: "grid",
    gridTemplateRows: "repeat(3,1fr)",
    rowGap: "5px",
    marginRight: "10px",
  },
  lastBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  icon: {
    width: "32px",
    height: "32px",
    color: theme.palette.primary.main,
  },
  noRecordBox: {
    height: "28vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  noRecordText: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#4d1277",
  },
  time: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "15px",
    color: "#777777",
  },
}));
export default useStyles;
