/* eslint-disable react/no-array-index-key */
import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import { useLocation, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import PeopleIcon from "@mui/icons-material/People";
import clsx from "clsx";
import DescriptionIcon from "@mui/icons-material/Description";
import SettingsIcon from "@mui/icons-material/Settings";
import ForumIcon from "@mui/icons-material/Forum";
import Box from "@mui/material/Box";
import useActiveUserDetail from "src/utils/shared/useActiveUserDetail";
// import { useState } from "react";
import useStyles from "../Styles";
import { ListProps } from "../Types";

const UpperList: React.FC<ListProps> = ({ open }) => {
  const location = useLocation();
  const { classes } = useStyles();
  const navigate = useNavigate();

  let upperItems = [
    {
      name: "Chats",
      icon: (
        <IconButton>
          <ForumIcon
            className={clsx(classes.icon, {
              [classes.linkSelectedtext]: location.pathname.includes(`/chats`),
            })}
          />
        </IconButton>
      ),
    },
    {
      name: "Users",
      icon: (
        <IconButton>
          <PeopleIcon
            className={clsx(classes.icon, {
              [classes.linkSelectedtext]: location.pathname.includes(`/users`),
            })}
          />
        </IconButton>
      ),
    },

    {
      name: "Reports",
      icon: (
        <IconButton>
          <DescriptionIcon
            className={clsx(classes.icon, {
              [classes.linkSelectedtext]:
                location.pathname.includes(`/reports`),
            })}
          />
        </IconButton>
      ),
    },

    {
      name: "Settings",
      icon: (
        <IconButton>
          <SettingsIcon
            className={clsx(classes.icon, {
              [classes.linkSelectedtext]:
                location.pathname.includes(`/settings`),
            })}
          />
        </IconButton>
      ),
    },
  ];
  const { panelType } = useActiveUserDetail();
  // removing user icon from manager and agent

  if (panelType === "AGENT" || !panelType) {
    upperItems = upperItems.filter((item) => item.name !== "Users");
  }

  const handleClick = (route: string) => {
    navigate(`/${route.toLowerCase()}`);
  };
  return (
    <List className={classes.list}>
      <ListItem key={1} disablePadding className={classes.upperListItem}>
        <ListItemButton
          onClick={() => handleClick("")}
          disableRipple
          sx={{
            width: !open ? "15px" : "91%",
          }}
          className={clsx(classes.listItemButton, {
            [classes.back]: location.pathname === `/`,
          })}
        >
          <ListItemIcon
            sx={{
              mr: open ? 5 : "auto",
            }}
            className={classes.listItemIcon}
          >
            <Box
              className={clsx(classes.top, {
                [classes.back]: location.pathname === `/`,
              })}
            >
              <HomeIcon />
            </Box>
          </ListItemIcon>
          <ListItemText
            primary="Dashboard"
            className={clsx(classes.listLink, {
              [classes.back]: location.pathname === `/`,
            })}
            sx={{ opacity: open ? 1 : 0 }}
          />
        </ListItemButton>
      </ListItem>
      {upperItems.map((item, index) => (
        <ListItem key={index} disablePadding className={classes.upperListItem}>
          <ListItemButton
            onClick={() => handleClick(item.name)}
            disableRipple
            sx={{
              width: !open ? "15px" : "91%",
            }}
            className={clsx(classes.listItemButton, {
              [classes.back]: location.pathname.includes(
                `/${item.name.toLowerCase()}`,
              ),
            })}
          >
            <ListItemIcon
              sx={{
                mr: open ? 5 : "auto",
              }}
              className={classes.listItemIcon}
            >
              <Box
                className={clsx(classes.top, {
                  [classes.back]: location.pathname.includes(
                    `/${item.name.toLowerCase()}`,
                  ),
                })}
              >
                {item.icon}
              </Box>
            </ListItemIcon>

            <ListItemText
              primary={item.name}
              className={clsx(classes.listLink, {
                [classes.back]: location.pathname.includes(
                  `/${item.name.toLowerCase()}`,
                ),
              })}
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default UpperList;
