/* eslint-disable prettier/prettier */
import { Box, Skeleton } from "@mui/material";

const LinesSkeleton = ({ noOfLines = 1 }) => {
    const data = Array.from({ length: noOfLines }, (_, i) => i + 1);
    return (
        <Box>
            {data.map((item) => (
                <Box
                    key={item}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        margin: 1,
                        marginBottom: 2,
                        opacity: 0.5,
                    }}
                >
                    <Skeleton
                        animation="wave"
                        variant="text"
                        width="100%"
                        height={35}
                        // sx={{ marginLeft: 2 }}
                    />
                </Box>
            ))}
        </Box>
    );
};

export default LinesSkeleton;
