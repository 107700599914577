import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import NoData from "src/assets/images/dashboard/no-data.jpg";

const index = () => {
  return (
    <Box
      sx={{
        height: "100%",
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={NoData} alt="" style={{ height: "100px", width: "100px" }} />
      <Typography
        sx={{
          fontSize: "14px",
          color: "#B2B2B2",
        }}
      >
        No record found
      </Typography>
    </Box>
  );
};

export default index;
