import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  grid2: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    height: "75px",
    color: theme.palette.common.black,
    justifyContent: "space-between",
    alignItems: "center",
  },
  typography: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "24px",
    display: "flex",
  },
  text: {
    //
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: 1,
      marginTop: 0,
    },
    //
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    display: "flex",
    lineHeight: "48px",
    marginTop: -15,
  },
  appbar: {
    zIndex: 100,
    background: "#E6D9EC",
  },
}));

export default useStyles;
