import { makeStyles } from "tss-react/mui";

const useStyle = makeStyles()((theme) => ({
  listStyle: {
    position: "relative",
    // backgroundColor: "#FFFFFF",
    marginBottom: 10,
    "&:hover": {
      // change for production (.css-7ef7ac)
      "& .css-1j9g5iq-MuiChip-root": {
        backgroundColor: "#fff",
      },
    },
    display: "flex",
    justifyContent: "space-between",
  },
  backgroundPersonal: {
    backgroundColor: "#D1E9F6",
  },
  backgroundGeneric: {
    backgroundColor: "#F1D3CE",
  },
  iconBox: {
    display: "flex",
    paddingRight: "15px",
    height: "37px",
    paddingTop: "25px",
  },
  hover: {
    backgroundColor: "#FFFFFF",
    transition: "opacity 0.2s",
    "&:hover": {
      backgroundColor: "#ddd5d5",
    },
  },

  hover2: {
    opacity: 0,
    transition: "opacity 0.2s",
    "&:hover": {
      opacity: 1,
    },
  },
  box: {
    display: "flex",
    flexDirection: "column",
  },
  typograhy: {
    width: "100%",
    fontWeight: "400",
    fontFamily: "poppins",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#272525",
  },
  labelTypograhy: {
    width: "100%",
    height: "18px",
    marginTop: "8px",
    fontFamily: "poppins",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#838383",
  },
  span: {
    background: "#F5F5F5",
    borderRadius: "2px",
    padding: "2px 3px",
  },
  icon: {
    height: "24px",
    width: "24px",
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
}));

export default useStyle;
