import React from "react";
import Divider from "@mui/material/Divider";
import Box from "src/components/shared/atoms/box";
import Heading from "src/components/shared/atoms/heading/Heading";
import { Text } from "src/components/shared/atoms/text/Text";
import SocialLoginButton from "src/components/pages/auth/shared/socialLoginButton";
import BoxDesign from "src/components/pages/auth/shared/boxDesign";
import DynamicHead from "src/components/shared/atoms/helmet";
import Footer from "src/components/pages/auth/signup/footer";
import Form from "src/components/pages/auth/signup/form";
import useStyles from "./Styles";

const SignUpRightContainer: React.FC = () => {
  const { classes } = useStyles();

  return (
    <>
      <DynamicHead title="Create Account" />
      <Box className={classes.root}>
        <BoxDesign />
        <Heading heading="Hey, welcome To Kuikwit" />
        <Text text="Get started today by entering just a few details" />
        <Box className={classes.form}>
          <Form />
          <Box className={classes.divider}>
            {" "}
            <Divider className={classes.dividerText}>or</Divider>
          </Box>

          <Box className={classes.style}>
            <SocialLoginButton type="google" />
            <SocialLoginButton type="facebook" />
          </Box>
          <Footer />
        </Box>
      </Box>
    </>
  );
};

export default SignUpRightContainer;
