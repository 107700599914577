/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import GET_TWILIO_CONFIG from "src/graphql/query/settings/getTwilioConfig";
import { useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { Box } from "@mui/material";
import Configuration from "./configuration";
import { ResultType } from "./Types";
import PhoneConfiguration from "./phoneConfiguration";
import TwilioSkeleton from "./skeleton";

const Twilio = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [twilioConfiguration, setTwilioConfiguration] = useState<
    ResultType | null | undefined
  >(null);
  const
    {
      loading: getTwilioConfigurationQueryLoading,
      error: getTwilioConfigurationQueryError,
      data: getTwilioConfigurationQueryResult,
    } = useQuery(GET_TWILIO_CONFIG, {
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    if (getTwilioConfigurationQueryError) {
      getTwilioConfigurationQueryError.graphQLErrors?.map(({ message }) => {
        enqueueSnackbar(message, { variant: "error" });
      });
    }
  }, [getTwilioConfigurationQueryError]);

  // useEffect(() => {
  //   getTwilioConfigurationApi();
  // }, []);

  useEffect(() => {
    if (
      getTwilioConfigurationQueryResult &&
      getTwilioConfigurationQueryResult.getTwilioConfiguration
    ) {
      const { getTwilioConfiguration } = getTwilioConfigurationQueryResult;
      if (
        getTwilioConfiguration.authToken &&
        getTwilioConfiguration.accountSID
      ) {
        setTwilioConfiguration(getTwilioConfiguration);
        setIsAuthorized(true);
      }
      setIsLoading(false);
    }
  }, [getTwilioConfigurationQueryResult]);

  return (
    <Box sx={{ minHeight: 300, position: "relative" }}>
      {(isLoading || getTwilioConfigurationQueryLoading) && <TwilioSkeleton />}

      {!getTwilioConfigurationQueryLoading && !isLoading &&
        (isAuthorized ? (
          <PhoneConfiguration
            setIsAuth={setIsAuthorized}
            twiliodata={twilioConfiguration}
          />
        ) : (
          <Configuration
            setTwilioConfiguration={setTwilioConfiguration}
            setIsAuth={setIsAuthorized}
          />
        ))}
    </Box>
  );
};

export default Twilio;
