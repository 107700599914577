import { useEffect, useState } from "react";
import breakpoints from "src/theme/base/breakpoints";

const useDeviceOrientation = () => {
  const [Orientation, setOrientation] = useState("large");

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.xmd
        ? setOrientation("small")
        : setOrientation("large");
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [Orientation]);

  return Orientation;
};

export default useDeviceOrientation;
