import { createSlice } from "@reduxjs/toolkit";

interface INotification {
  id: number;
  customerId: string;
  pageId: string;
  dateandtime: string;
  read: false;
  customerName: string;
  customerPicture: string;
  createdAt: string;
  updatedAt: string;
  agentId: number;
}

export interface INotificationData {
  notification: INotification | null;
}

const initialState: INotificationData = {
  notification: null,
};

const notificationSlice = createSlice({
  initialState,
  name: "NotificationData",
  reducers: {
    notificationHandler: (state, action) => {
      return {
        ...state,
        notification: action.payload,
      };
    },
  },
});

export const { notificationHandler } = notificationSlice.actions;
export default notificationSlice.reducer;
