import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  hide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  bottomNavDif: {
    marginLeft: -20,
    padding: 0,
    maxWidth: 100,
  },
  bottomNav: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 30,
    marginLeft: 30,
  },
  paper: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 99,
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  mainBox: {
    borderRadius: "10px",
    height: "calc(100vh - 110px)",
    flex: "1",
    overflowY: "auto",
    overflowX: "hidden",
    [theme.breakpoints.down("md")]: {
      height: "calc(100vh - 100px)",
    },
  },
  gridMain: {
    flex: "auto",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 6,
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: 6,
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
    },
    // marginBottom: { xs: 6, sm: 6, md: 6, lg: 0 },
  },
  gridHeader: {
    position: "relative",
  },
  gridg: {
    marginLeft: "65px",
    display: "flex",
    flexDirection: "column",
    padding: "15px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0px",
    },
  },
  box: {
    maxHeight: "100vh",
    overflow: "hidden",
  },
  grid: {
    width: "65px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  spin: {
    height: "100vh",
    width: "100wh",
    backgroundColor: "#e6d9ec",
    color: theme.palette.primary.main,
  },
}));
export default useStyles;
