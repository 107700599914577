interface UserDetail {
  id: string;
  name: string;
  mainSuperAdminId: string;
  managerId: null | string;
  picture: null | string;
  number: null | string;
  paneltype: string;
  pseudonym: string;
  username: string;
}

export default function useActiveUserDetail() {
  const activeUserDetail = localStorage.getItem("ActiveUserdetail");
  const data: UserDetail = activeUserDetail
    ? JSON.parse(activeUserDetail)
    : null;

  return {
    agentId: data?.id,
    name: data?.name,
    username: data?.username,
    mainSuperAdminId: Number(data?.mainSuperAdminId),
    managerId: data?.managerId,
    panelType: data?.paneltype,
    number: data?.number,
    picture: data?.picture,
    pseudonym: data?.pseudonym,
  };
}
