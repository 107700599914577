import { gql } from "@apollo/client";

const UPDATE_RESPONSE_QUERY = gql`
  mutation updateUserCannedResponse(
    $responseId: ID!
    # $type: String
    $short: String!
    $long: String!
  ) {
    updateUserCannedResponse(
      responseId: $responseId
      # type: $type
      short: $short
      long: $long
    ) {
      success
      error
      result
    }
  }
`;

export default UPDATE_RESPONSE_QUERY;
