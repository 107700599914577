/* eslint-disable prettier/prettier */
import * as React from "react";
import {
  OutlinedInput,
  Box,
  Typography,
  Grid,
  InputLabel,
} from "@mui/material";
import Select from "@mui/material/Select";
import { Formik } from "formik";
import * as Yup from "yup";
import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Button from "src/components/shared/atoms/button";
import useActiveUserDetail from "src/utils/shared/useActiveUserDetail";
import useStyle from "../generalLabels/Styles";

interface Props {
  addLabelHandler: (value: any) => void;
  updateLabelsQueryLoading: boolean;
  labelText: string;
  children?: React.ReactNode;
}
interface FormValues {
  label: number | string | null;
}

const Input: React.FC<Props> = ({
  addLabelHandler,
  updateLabelsQueryLoading,
  labelText,
  children,
}) => {
  const { classes } = useStyle();
  const SUPERADMIN = "SUPERADMIN";
  const { panelType } = useActiveUserDetail();
  const [initialValues, setInitialValues] = React.useState({
    label: labelText,
    type: "personal",
  });

  React.useEffect(() => {
    setInitialValues({
      label: labelText,
      type: "personal",
    });
  }, [labelText]);

  const handleClick = async (values: FormValues, { setSubmitting }: any) => {
    setSubmitting(true);
    addLabelHandler(values);
    // values.label = "";
    setSubmitting(false);
  };

  const validationSchema = Yup.object().shape({
    label: Yup.string()
      .required("Label cannot be empty")
      .max(25, "not more than 25 characters"),
  });
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "3px",
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 600,
          }}
        >
          Add a Label
        </Typography>

        <Box sx={{ height: 40 }}>{children}</Box>
      </Box>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleClick}
      >
        {(formik: any) => (
          <Box
            mt={2}
            component="form"
            onSubmit={formik.handleSubmit}
            alignItems="center"
          >
            <Grid
              container
              spacing={0}
              alignItems="flex-start"
              justifyContent="space-between"
              sx={{ padding: 0, margin: 0, gap: 1 }}
            >
              <Grid item xs={12} md={4} sx={{ padding: 0, margin: 0 }}>
                <InputLabel sx={{ marginBottom: 1 }}>Label</InputLabel>
                <OutlinedInput
                  type="text"
                  placeholder="Add a Label"
                  value={formik.values.label}
                  name="label"
                  id="label"
                  onChange={formik.handleChange}
                  className={classes.labelInput}
                />
                <Box
                  sx={{
                    height: 20,
                    marginLeft: 2,
                  }}
                >
                  <FormHelperText error id="accountId-error">
                    {formik.touched.label && formik.errors.label}
                  </FormHelperText>
                </Box>
              </Grid>
              {panelType === SUPERADMIN && (
                <Grid item xs={12} md={4} sx={{ padding: 0, margin: 0 }}>
                  <InputLabel sx={{ marginBottom: 1 }}>Select Type</InputLabel>
                  <Select
                    name="type"
                    value={formik.values.type}
                    className={classes.fieldStyle}
                    onChange={formik.handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    renderValue={(selected) => (
                      <Box
                        sx={{ display: "flex", alignItems: "center", ml: 1 }}
                      >
                        {selected === "personal" && (
                          <>
                            <PersonIcon color="primary" sx={{ mr: 3 }} />
                            <Typography variant="body2">My Response</Typography>
                          </>
                        )}
                        {selected === "generic" && (
                          <>
                            <GroupsIcon color="primary" sx={{ mr: 3 }} />
                            <Typography variant="body2">
                              Generic Response
                            </Typography>
                          </>
                        )}
                      </Box>
                    )}
                  >
                    <MenuItem value="personal">
                      <PersonIcon color="primary" sx={{ mr: 3 }} />
                      <Typography variant="body2">My Response</Typography>
                    </MenuItem>
                    <MenuItem value="generic">
                      <GroupsIcon color="primary" sx={{ mr: 3 }} />
                      <Typography variant="body2">Generic Response</Typography>
                    </MenuItem>
                  </Select>
                </Grid>
              )}
              <Grid item xs={12} md={3} mt={3} sx={{ padding: 0, margin: 0 }}>
                <Box
                  sx={{
                    height: 30,
                  }}
                />
                <Button
                  variant="contained"
                  className={classes.addButton}
                  type="submit"
                  circular={updateLabelsQueryLoading}
                >
                  Create Label
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Formik>
    </>
  );
};

export default Input;
