// import { width } from '@mui/system'
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  root: {
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc",
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    overflowY: "auto",

    flexDirection: "column",
    boxSizing: "border-box",
    padding: "35px 35px 35px 35px",
  },
  form: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
    flexDirection: "column",
  },
  divider: {
    width: "100%",
    alignItems: "center",
    color: "#B7B7B7",
  },
  dividerText: {
    color: theme.palette.text.secondary,
  },
  style: {
    width: "100%",
  },
}));

export default useStyles;
