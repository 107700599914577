import { useEffect } from "react";
import { Box } from "@mui/material";
import BadgeWAvatar from "src/components/shared/atoms/badgeWAvatar";
import { useSelector } from "react-redux";
import useStyles from "./Styles";

const Profile = () => {
  const { classes } = useStyles();
  const { me }: any = useSelector((state: any) => state.me);
  const { onlineStatus, picture } = me ?? {};

  useEffect(() => {
    if (me) {
      console.log("Profile data changed:", me);
    }
  }, [me]);

  return (
    <Box className={classes.profile}>
      <BadgeWAvatar login={onlineStatus} picture={picture} />
    </Box>
  );
};

export default Profile;
