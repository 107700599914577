/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import Button from "src/components/shared/atoms/button";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import CONNECT_TWILIO_PHONE from "src/graphql/mutation/settings/connectTwilioPhone";
import DISCONNECT_TWILIO_PHONE from "src/graphql/mutation/settings/disconnectTwilioPhone";
import { Props, TypesForPhoneNumber } from "../../Types";
import useStyle from "../../Styles";

const TwilioBtn: React.FC<Props> = ({
  accountSID,
  authToken,
  data,
  phnNumber,
  setPhnNumber,
}) => {
  const { classes } = useStyle();
  const { enqueueSnackbar } = useSnackbar();
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState<string | null>(
    null,
  );

  const [
    connectTwilioPhoneNumber,
    {
      loading: ConnectTwilioPhoneNumberQueryLoading,
      error: ConnectTwilioPhoneNumberQueryError,
      data: ConnectTwilioPhoneNumberQueryResult,
    },
  ] = useMutation(CONNECT_TWILIO_PHONE);

  useEffect(() => {
    if (
      ConnectTwilioPhoneNumberQueryResult &&
      ConnectTwilioPhoneNumberQueryResult.connectTwilioPhoneNumber
    ) {
      if (
        ConnectTwilioPhoneNumberQueryResult.connectTwilioPhoneNumber.success
      ) {
        const copyOfPhnNumber: TypesForPhoneNumber[] = phnNumber;

        const anothercopy: any =
          Array.isArray(copyOfPhnNumber) &&
          copyOfPhnNumber.map((curr: TypesForPhoneNumber) => {
            return { ...curr, active: false, __typename: "twilioPhone" };
          });

        const objIndex: number | boolean =
          Array.isArray(anothercopy) &&
          anothercopy.findIndex(
            (obj) => obj.phoneNumber === currentPhoneNumber,
          );

        if (objIndex !== -1 && objIndex !== false) {
          anothercopy[objIndex].active = true;
        }

        setPhnNumber(anothercopy);
      } else {
        enqueueSnackbar(
          ConnectTwilioPhoneNumberQueryResult.connectTwilioPhoneNumber.error,
          {
            variant: "error",
          },
        );
      }
    }
  }, [ConnectTwilioPhoneNumberQueryResult]);

  useEffect(() => {
    if (ConnectTwilioPhoneNumberQueryError) {
      ConnectTwilioPhoneNumberQueryError.graphQLErrors?.map(
        ({ message }, i) => {
          enqueueSnackbar(message, { variant: "error" });
        },
      );
    }
  }, [ConnectTwilioPhoneNumberQueryError]);

  const [
    disconnectTwilioPhoneNumber,
    {
      loading: DiscountTwilioPhoneNumberQueryLoading,
      error: DiscountTwilioPhoneNumberQueryError,
      data: DiscountTwilioPhoneNumberQueryResult,
    },
  ] = useMutation(DISCONNECT_TWILIO_PHONE);

  useEffect(() => {
    if (DiscountTwilioPhoneNumberQueryError) {
      DiscountTwilioPhoneNumberQueryError.graphQLErrors?.map(
        ({ message }, i) => {
          enqueueSnackbar(message, { variant: "error" });
        },
      );
    }
  }, [DiscountTwilioPhoneNumberQueryError]);

  useEffect(() => {
    if (
      DiscountTwilioPhoneNumberQueryResult &&
      DiscountTwilioPhoneNumberQueryResult.disconnectTwilioPhoneNumber
    ) {
      if (
        DiscountTwilioPhoneNumberQueryResult.disconnectTwilioPhoneNumber.success
      ) {
        const newArray: any =
          Array.isArray(phnNumber) &&
          phnNumber.map((curr) =>
            curr.phoneNumber === currentPhoneNumber
              ? {
                  ...curr,
                  active: false,
                }
              : { ...curr },
          );

        setPhnNumber(newArray);
      } else {
        enqueueSnackbar(
          DiscountTwilioPhoneNumberQueryResult.disconnectTwilioPhoneNumber
            .error,
          {
            variant: "error",
          },
        );
      }
    }
  }, [DiscountTwilioPhoneNumberQueryResult]);

  const disconnectPhoneNumber = (data: TypesForPhoneNumber) => {
    setCurrentPhoneNumber(data.phoneNumber);
    disconnectTwilioPhoneNumber({
      variables: {
        accountSID,
        authToken,
        phoneNumber: data.phoneNumber,
      },
    });
  };
  const connectPhoneNumber = (data: TypesForPhoneNumber) => {
    connectTwilioPhoneNumber({
      variables: {
        accountSID,
        authToken,
        phoneNumber: data.phoneNumber,
      },
    });
    setCurrentPhoneNumber(data.phoneNumber);
  };

  return (
    <Button
      circular={
        DiscountTwilioPhoneNumberQueryLoading ||
        ConnectTwilioPhoneNumberQueryLoading
      }
      variant="outlined"
      handleClick={() =>
        data.active ? disconnectPhoneNumber(data) : connectPhoneNumber(data)
      }
      className={clsx(classes.button, {
        [classes.buttonStyleDisconnected]: data.active,
        [classes.buttonStyleConnected]: !data.active,
      })}
    >
      {!data.active ? "Connect Phone" : "Disconnect Phone"}
    </Button>
  );
};

export default TwilioBtn;
