import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  form: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
    flexDirection: "column",
    width: "390px",

    [theme.breakpoints.down("lg")]: {
      width: "300px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "250px",
    },
  },
}));
export default useStyles;
