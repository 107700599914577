import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  drawer: false,
};

const drawerSlice = createSlice({
  initialState,
  name: "DRAWER",
  reducers: {
    drawerHandler: (state, action) => {
      return {
        ...state,
        drawer: action.payload,
      };
    },
  },
});

export const { drawerHandler } = drawerSlice.actions;
export default drawerSlice.reducer;
