import { gql } from "@apollo/client";

const ADD_USER_LABEL = gql`
  mutation addUserLabel(
    $label: String!
    $mainSuperAdminId: ID!
    $agentId: ID!
    $type: String!
    $author: String!
  ) {
    addUserLabel(
      label: $label
      mainSuperAdminId: $mainSuperAdminId
      agentId: $agentId
      type: $type
      author: $author
    ) {
      success
      error
      result
    }
  }
`;

export default ADD_USER_LABEL;
