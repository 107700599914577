// eslint-disable-next-line import/no-extraneous-dependencies
import { Helmet } from "react-helmet";

interface IDyamicHead {
  title: string;
}
const DynamicHead: React.FC<IDyamicHead> = ({ title }) => {
  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
    </div>
  );
};

export default DynamicHead;
