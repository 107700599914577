/* eslint-disable import/no-extraneous-dependencies */
/*  eslint-disable */
import { useState, useEffect } from "react";
import { Link as MuiLink } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";

import Box from "src/components/shared/atoms/box";
import TextField from "src/components/shared/atoms/textField/TextField";
import AuthButton from "src/components/pages/auth/shared/button/AuthButton";
import { Link, useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import {
  SignInErrorsProps,
  FormValues,
} from "src/components/pages/auth/login/form/Types";
import { useMutation } from "@apollo/client";
import LOGIN_MUTATION from "src/graphql/mutation/auth/login";
import setAuthDataInLocalStorage from "src/utils/auth/LocalStorage";
import useStyles from "./Styles";

const Form = () => {
  const { classes } = useStyles();
  const [email, setEmail] = useState<string>("");
  const [signInErrors, setSignInErrors] = useState<SignInErrorsProps | null>(
    null,
  );

  const navigate = useNavigate();
  const [
    getLogin,
    {
      loading: loginQueryLoading,
      error: loginQueryError,
      data: loginQueryResult,
    },
  ] = useMutation(LOGIN_MUTATION);

  useEffect(() => {
    if (loginQueryResult && loginQueryResult.login) {
      const isSet = setAuthDataInLocalStorage(loginQueryResult.login);
      if (isSet) navigate("/");
    }
  }, [loginQueryResult, navigate]);

  useEffect(() => {
    if (loginQueryError) {
      if (
        loginQueryError.message === "Failed to fetch" ||
        loginQueryError.message ===
          "An unexpected error occurred. Please try again later."
      ) {
        enqueueSnackbar("Something went wrong. Try again.", {
          variant: "error",
        });
        return;
      }

      const backendErrors = JSON.parse(loginQueryError?.message ?? "");

      setSignInErrors(backendErrors);
      if (backendErrors.message) {
        enqueueSnackbar(backendErrors.message, { variant: "error" });
        navigate("/confirm", { state: { email } });
      }
    }
  }, [loginQueryError, navigate, email]);

  // validation schema using Yup
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(3, "Password must be at least 3 characters")
      .required("Password is required"),
  });
  // if login mutation sends back the result

  const handleSubmit = async (values: FormValues, { setSubmitting }: any) => {
    try {
      setEmail(values.email);
      await getLogin({
        variables: {
          email: values.email,
          password: values.password,
        },
      });
    } catch (err: any) {
      return;
    }
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formik: any) => (
        <Box component="form" onSubmit={formik.handleSubmit}>
          <Box className={classes.marginTop}>
            <TextField
              id="email"
              label="Email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              helperText={
                (signInErrors && signInErrors.email) ||
                (formik.touched.email && formik.errors.email)
              }
            />
          </Box>
          <Box className={classes.marginTop}>
            <TextField
              id="password"
              label="Password"
              name="password"
              typeField="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              helperText={
                (signInErrors && signInErrors.password) ||
                (formik.touched.password && formik.errors.password)
              }
            />
          </Box>
          <Box className={classes.label}>
            <MuiLink>
              <Link className={classes.link} to="/forgetpassword">
                Forgot password?
              </Link>
            </MuiLink>
          </Box>
          <AuthButton text="Sign In" loading={loginQueryLoading} />
        </Box>
      )}
    </Formik>
  );
};

export default Form;
