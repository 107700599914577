/* eslint-disable prettier/prettier */
import * as React from "react";
import {
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Box,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import useStyle from "./Styles";

interface Props {
  loader?: boolean;
  img?: any;
  icon?: any;
  text: any;
  btnText: any;
  modalOpen: boolean;
  // modalClose: React.Dispatch<React.SetStateAction<boolean>>;
  modalClose: () => void;
  removeItem: any;
}

const CustomDialog: React.FC<Props> = ({
  btnText,
  text,
  modalOpen,
  modalClose,
  removeItem,
  img,
  icon,
  loader,
}) => {
  const { classes } = useStyle();
  const { connection }: any = useSelector((state: any) => state.connection);

  const removeHandler = async () => {
    await removeItem();
    // modalClose(false);
  };

  const handleClose = () => {
    // modalClose(false);
    modalClose();
  };

  return (
    <Dialog
      // fullScreen={fullScreen}
      className={classes.dialog}
      open={modalOpen}
      // onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogContent className={classes.content}>
        <Box
          className={classes.DeleteIcon}
          style={{ display: "flex", justifyContent: "center", padding: "10px" }}
        >
          {icon !== undefined ? (
            icon
          ) : (
            <img src={img} alt={btnText} style={{ width: "60px", height: "60px" }} />
          )}
        </Box>
        <Typography component="h1" className={classes.headingStyle}>
          Are you sure?
        </Typography>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ paddingBottom: "20px", justifyContent: "center" }}>
        <Button
          className={`${classes.buttonStyle} ${classes.cancelButton}`}
          variant="contained"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          className={`${classes.buttonStyle} ${classes.deleteButton}`}
          variant="contained"
          onClick={removeHandler}
          disabled={!connection || loader}
          endIcon={
            loader && <CircularProgress sx={{ color: "white" }} size={15} />
          }
        >
          {btnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CustomDialog.defaultProps = {
  img: undefined,
  icon: undefined,
  loader: false,
};

export default CustomDialog;
