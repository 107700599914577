import { gql } from "@apollo/client";

const LogoutQuery = gql`
  mutation {
    logout {
      success
      error
    }
  }
`;
export default LogoutQuery;
