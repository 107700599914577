import React, { useState } from "react";
import {
  // IconButton,
  TableBody,
  TableCell,
  TableRow,
  Box,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useActiveUserDetail from "src/utils/shared/useActiveUserDetail";
import moment from "moment";
import clsx from "clsx";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import useStyle from "../generalLabels/Styles";
import TableRowsLoader from "./TableRowsLoader";

interface Props {
  loading: boolean;
  data: any;
  openDelModal: (value: number) => void;
}

const GeneralizedLabelList: React.FC<Props> = ({
  loading,
  data,
  openDelModal,
}) => {
  const { classes } = useStyle();
  const { panelType } = useActiveUserDetail();
  const SUPERADMIN = "SUPERADMIN";
  const PERSONAL_TYPE = "personal";

  const navigate = useNavigate();
  const [hovered, setHovered] = useState<number>(-1);

  if (loading) {
    return <TableRowsLoader rowsNum={3} />;
  }

  const handleMouseEnter = (id: number) => {
    setHovered(id);
  };

  const handleMouseLeave = () => {
    setHovered(-1);
  };

  const editLabel = (labelData: any) => {
    navigate(`/settings/label?mode=edit`, {
      state: { labelData },
    });
    // setOpenModal(true);
  };

  return (
    <TableBody>
      {data.length > 0 ? (
        data.map((curr: any) => (
          <TableRow
            onMouseEnter={() => handleMouseEnter(curr.id)}
            onMouseLeave={handleMouseLeave}
            key={curr.id}
            className={clsx(
              curr.type === PERSONAL_TYPE
                ? classes.backgroundPersonal
                : classes.backgroundGeneric,
            )}
          >
            <TableCell className={classes.tbody}>
              <Typography component="p" className={classes.tableText}>
                {curr.label}
              </Typography>
            </TableCell>
            <TableCell className={classes.tbody}>
              <Typography component="p" className={classes.tableText}>
                {moment
                  .unix(curr.createdAt / 1000)
                  .format("DD MMM YYYY hh:mm a")}
              </Typography>
            </TableCell>
            <TableCell className={classes.tbody}>
              <Typography component="p" className={classes.tableText}>
                {curr.author}
              </Typography>
            </TableCell>
            <TableCell
              className={classes.tbody}
              align="right"
              style={{ padding: "0px" }}
            >
              {(panelType === SUPERADMIN || curr.type === PERSONAL_TYPE) && (
                <Box
                  className={classes.iconBox}
                  sx={{
                    visibility: curr.id === hovered ? "visible" : "hidden",
                  }}
                >
                  <EditIcon
                    onClick={() => editLabel(curr)}
                    className={classes.icon}
                  />
                  <DeleteIcon
                    onClick={(e) => {
                      e.preventDefault();
                      openDelModal(curr.id);
                    }}
                    className={classes.icon}
                  />
                </Box>
              )}
            </TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={4} align="center">
            <Box p={2}>
              <Typography variant="body1" color="textSecondary">
                No Records Found
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

export default GeneralizedLabelList;
