/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import LogoutIcon from "@mui/icons-material/Logout";
import { Backdrop, CircularProgress, Typography } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import clsx from "clsx";
import ListItemIcon from "@mui/material/ListItemIcon";
import { FC, useEffect, useState } from "react";
import LogoutQuery from "src/graphql/query/auth/logout";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { ListProps } from "../Types";
import useStyles from "../Styles";
import Profile from "../../header/profile";

const LowerList: FC<ListProps> = ({ open }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [logout, { data: logoutQueryResult, loading: logoutQueryLoading }] =
    useMutation(LogoutQuery);
  const handleProfile = () => {
    navigate("settings/profile");
  };
  const handleLogout = () => {
    logout();
  };
  useEffect(() => {
    if (logoutQueryResult && logoutQueryResult.logout.success) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("ActiveUserdetail");
      localStorage.removeItem("userName");
      navigate("login");
      setLoading(false);
    }
  }, [logoutQueryResult]);

  useEffect(() => {
    if (logoutQueryLoading) setLoading(true);
  }, [logoutQueryLoading]);

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="primary" />
        <Typography color="primary">Logging out</Typography>
      </Backdrop>

      <List className={classes.listBelow}>
        <ListItem className={classes.avatar}>
          <Profile />
          <ListItemText
            className={clsx(classes.listLink)}
            onClick={handleProfile}
          >
            <Typography
              className={clsx(classes.help, {
                [classes.logoutTextOpen]: open,
              })}
            >
              Profile
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem className={classes.listLink}>
          <ListItemButton
            onClick={handleLogout}
            disabled={logoutQueryLoading}
            disableRipple
            className={clsx(classes.logoutButton, {
              [classes.logoutButtonOpen]: open,
            })}
          >
            <ListItemIcon
              className={clsx({
                [classes.logoutIcon]: !open,
              })}
            >
              <LogoutIcon color="error" />
            </ListItemIcon>
            <ListItemText className={clsx(classes.listLink)}>
              <Typography
                className={clsx(classes.logoutText, {
                  [classes.logoutTextOpen]: open,
                })}
              >
                {" "}
                Logout
                {logoutQueryLoading && (
                  <CircularProgress
                    sx={{
                      marginLeft: 5,
                      color: "red",
                    }}
                    size={12}
                  />
                )}
              </Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </>
  );
};

export default LowerList;
