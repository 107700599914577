/* eslint-disable react/require-default-props */
import Avatar from "@mui/material/Avatar";
import React, { useState } from "react";

interface Props {
  src: string;
  className?: string;
  variant?: "circular" | "rounded" | "square";
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const KuikwitAvatar: React.FC<Props> = ({
  src,
  className,
  onClick,
  variant,
}) => {
  const [, setLoaded] = useState(false);

  const handleLoad = () => {
    setLoaded(true);
  };

  return (
    <Avatar
      src={src}
      onLoad={handleLoad}
      className={className}
      variant={variant}
      onClick={onClick}
    />
  );
};
KuikwitAvatar.defaultProps = {
  variant: "circular",
};
export default KuikwitAvatar;
