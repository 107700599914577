import { gql } from "@apollo/client";

const GET_PAGES = gql`
  query getAllPages(
    $mainSuperAdminId: ID!
    $source: String!
    $onlySelected: Boolean
  ) {
    getAllPages(
      mainSuperAdminId: $mainSuperAdminId
      source: $source
      onlySelected: $onlySelected
    ) {
      id
      name
      pageId
      accesstoken
      picture
      isSelected
      email
    }
  }
`;
export default GET_PAGES;
