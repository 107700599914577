import { makeStyles } from "tss-react/mui";

const useStyle = makeStyles()((theme) => ({
  div: {
    width: "100%",
    height: "calc(100vh - 230px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      height: "calc(100vh - 260px)",
    },
  },
  progress: {
    marginBottom: "10px",
  },
  cardContent: {
    padding: 0,
    margin: 0,
    paddingTop: 10,
    overflowY: "auto",
    height: "calc(100vh - 180px)",
    [theme.breakpoints.down("md")]: {
      height: "calc(100vh - 220px)",
    },
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 290px)",
    },
  },
}));

export default useStyle;
