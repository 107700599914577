import { Box, CardContent } from "@mui/material";
import useStyle from "../../facebook/Styles";
import NotConnectedBox from "./notConnectedBox";
import ConnectedBox from "./connectedBox";

interface Props {
  loggedInUserDetail: any;
  setloggedInUserDetail: (value: any) => void;
}
const ExternalPage: React.FC<Props> = ({
  loggedInUserDetail,
  setloggedInUserDetail,
}) => {
  const { classes } = useStyle();

  const handleLogout = () => {
    setloggedInUserDetail(null);
  };
  return (
    <>
      <Box
        sx={{
          p: 0,
          m: 0,
          fontWeight: "bold",
          fontSize: 16,
        }}
      >
        Instagram | Messenger
      </Box>
      <CardContent className={classes.cardContent}>
        {!loggedInUserDetail ? (
          <NotConnectedBox />
        ) : (
          <ConnectedBox user={loggedInUserDetail} logout={handleLogout} />
        )}
      </CardContent>
    </>
  );
};

export default ExternalPage;
