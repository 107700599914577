import { gql } from "@apollo/client";

const ADD_TWILIO_CONFIG = gql`
  mutation addTwilioConfiguration(
    $accountSID: String!
    $authToken: String!
    $mainSuperAdminId: ID!
  ) {
    addTwilioConfiguration(
      accountSID: $accountSID
      authToken: $authToken
      mainSuperAdminId: $mainSuperAdminId
    ) {
      result
      success
      error
    }
  }
`;

export default ADD_TWILIO_CONFIG;
