import { Box, CircularProgress } from "@mui/material";
import useStyles from "./Styles";

const Spinner = () => {
  const { classes } = useStyles();
  return (
    <Box className={classes.backdrop}>
      <CircularProgress color="primary" />
    </Box>
  );
};
export default Spinner;
