import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  marginTop: {
    marginTop: "10px",
  },
  terms: {
    // maxWidth: "300px",
  },
  label: {
    marginTop: 10,
    fontSize: "12px",
    //
    [theme.breakpoints.up("xl")]: {
      lineHeight: "25px",
      // fontSize: '1.375rem',
      marginTop: 2,
      fontSize: "12px",
      maxWidth: "100%",
      width: "19vw",
    },
    // width: '300px',
    maxWidth: "300px",
    display: "flex",
    lineHeight: "15px",
    fontWeight: 450,
  },
}));
export default useStyles;
