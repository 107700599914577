/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect } from "react";
import Box from "src/components/shared/atoms/box";
import Typography from "@mui/material/Typography";
import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as GoogleSvgComponent } from "src/assets/images/auth/Google.svg";
import { ReactComponent as FacebookSvgComponent } from "src/assets/images/auth/Facebook.svg";
import { useMutation } from "@apollo/client";
import expressConfig from "src/config/express.json";
import { FacebookFunc } from "src/utils/auth/Facebook";
import FACEBOOKBUSINESSLOGIN from "src/graphql/mutation/auth/facebookbuisnesslogin";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import useStyles from "./Styles";

interface Props {
  type: string;
}
declare global {
  interface Window {
    FB: any;
  }
}

const SocialLoginButton: React.FC<Props> = ({ type }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  useEffect(() => {
    FacebookFunc.fbInt();
  }, []);
  const [facebookBuisnessLogin, { data: facebookBuisnessLoginResult }] =
    useMutation(FACEBOOKBUSINESSLOGIN);
  const buttonDetails = () => {
    const env = process.env.NODE_ENV || "development";

    const config = expressConfig[env];
    // 3. Facebook login with JavaScript SDK

    const { auth_url }: { auth_url: string } = config;
    let icon;
    let text;
    let handleClick;
    if (type === "google") {
      icon = <GoogleSvgComponent />;
      text = "Sign In with Google";
      handleClick = () => {
        window.open(`${auth_url}google`, "_self");
      };
    }
    // function checkLoginState() {
    //   window.FB.getLoginStatus(function (response: any) {
    //     if (response.status === "connected") {
    //       const { accessToken } = response.authResponse;
    //       facebookBuisnessLogin({
    //         variables: {
    //           accesstoken: accessToken,
    //         },
    //       });
    //       console.log(accessToken, "accessToken");
    //     } else {
    //       window.FB.login(function (response: any) {
    //         if (response.authResponse) {
    //           console.log("Welcome!  Fetching your information.... ");
    //           const { accessToken } = response.authResponse;
    //           console.log(accessToken, "accessToken");
    //           facebookBuisnessLogin({
    //             variables: {
    //               accesstoken: accessToken,
    //             },
    //           });
    //         } else {
    //           console.log("User cancelled login or did not fully authorize.");
    //         }
    //       });
    //     }
    //     console.log("ended");
    //   });
    // }
    function launchFBE() {
      window.FB.login(
        function (response: any) {
          if (response.authResponse) {
            // returns a User Access Token with scopes requested
            const { accessToken } = response.authResponse;
            // store access token for later
            facebookBuisnessLogin({
              variables: {
                accesstoken: accessToken,
              },
            });
          } else {
            console.log("User cancelled login or did not fully authorize.");
          }
        },
        {
          scope: "catalog_management,email",
          // refer to the extras object table for details
          extras: {
            setup: {
              external_business_id: "fog-213132323",
              timezone: "America/Los_Angeles",
              currency: "USD",
              business_vertical: "ECOMMERCE",
            },
            business_config: {
              business: {
                name: "Green Maek",
              },
              ig_cta: {
                enabled: true,
                cta_button_text: "Book Now",
                cta_button_url: "https://partner-site.com/foo-business",
              },
            },
            repeat: false,
          },
        },
      );
    }
    if (type === "facebook") {
      icon = <FacebookSvgComponent />;
      text = "Sign In with Facebook";
      handleClick = () => {
        launchFBE();
      };
    }

    return {
      icon,
      text,
      handleClick,
    };
  };

  useEffect(() => {
    if (
      facebookBuisnessLoginResult &&
      facebookBuisnessLoginResult.facebookBusinessLogin
    ) {
      if (facebookBuisnessLoginResult.facebookBusinessLogin.success) {
        navigate("/");
      } else {
        enqueueSnackbar(
          facebookBuisnessLoginResult.facebookBusinessLogin.error,
          {
            variant: "error",
          },
        );
      }
    }
  }, [facebookBuisnessLoginResult]);

  const { icon, text, handleClick } = buttonDetails();
  return (
    <Box className={classes.root} onClick={handleClick}>
      <Box className={classes.box}>
        <SvgIcon>{icon}</SvgIcon>
      </Box>
      <Typography ml={1} className={classes.text}>
        {text}
      </Typography>
    </Box>
  );
};

export default SocialLoginButton;
