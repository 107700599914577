import { styled } from "@mui/material/styles";

const Main = styled("main")(({ theme }) => ({
  ...{
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),

    justifyContent: "center",
    alignItems: "center",
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: `calc(100%)`,
  },
}));

export default Main;
