/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Divider,
  Grid,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
} from "@mui/material";
import _ from "lodash";
import SearchField from "src/components/shared/atoms/SearchField";
import CardContent from "@mui/material/CardContent";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState, useCallback } from "react";
import { useSnackbar } from "notistack";
import clsx from "clsx";
import useActiveUserDetail from "src/utils/shared/useActiveUserDetail";
import removeIcon from "src/assets/images/settings/close-02.svg";
import GET_USER_LABELS from "src/graphql/mutation/settings/getGenericLabel";
import KuikwitDialog from "src/components/shared/atoms/dialog";
import ADD_USER_LABEL from "src/graphql/mutation/settings/addGenericLabel";
import REMOVE_USER_LABEL from "src/graphql/mutation/settings/deleteGenericLabel";
import useStyle from "./Styles";
import Header from "../header";
import Input from "../input";
import GeneralizedLabelList from "../list/generalizedLabelList";

const GeneralLabels = () => {
  const { classes } = useStyle();
  const [data, setData] = useState<any>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [removeModal, setRemoveModal] = useState(false);
  const [labelText, setLabelText] = useState("");
  const closeDialog = () => setRemoveModal(false);
  const [selectedId, setSelectedId] = useState<number>();
  const [updateLabelBoolean, setUpdateLabelBoolean] = useState<boolean>(false);
  const { mainSuperAdminId, agentId, username } = useActiveUserDetail();

  const [
    addUserLabel,
    { loading: addUserLabelLoading, data: addUserLabelResult },
  ] = useMutation(ADD_USER_LABEL);

  useEffect(() => {
    if (addUserLabelResult && addUserLabelResult.addUserLabel) {
      if (addUserLabelResult.addUserLabel.success) {
        setLabelText("");
        enqueueSnackbar("Label Added", {
          variant: "success",
        });
        const newlyAddedLabel = JSON.parse(
          addUserLabelResult.addUserLabel.result,
        );
        setData((prev: any) => [newlyAddedLabel, ...prev]);
      } else {
        enqueueSnackbar(JSON.parse(addUserLabelResult.addUserLabel.error), {
          variant: "error",
        });
      }
    }
  }, [addUserLabelResult]);

  // GET GENERIC LABELS
  const [
    getUserLabels,
    {
      loading: getGenericLabelLoading,
      data: getGenericLabelResult,
      error: getGenericLabelError,
    },
  ] = useLazyQuery(GET_USER_LABELS, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (getGenericLabelError) {
      enqueueSnackbar(getGenericLabelError.message, {
        variant: "error",
      });
    }
  }, [getGenericLabelError]);

  useEffect(() => {
    if (getGenericLabelResult && getGenericLabelResult.getUserLabels) {
      // const res = JSON.parse(getGenericLabelResult.getGenericLabel.result);
      setData(getGenericLabelResult.getUserLabels);
    }
  }, [getGenericLabelResult]);

  // DELETE GENERIC LABEL
  const [
    removeUserLabel,
    { loading: removeUserLabelLoading, data: removeUserLabelResult },
  ] = useMutation(REMOVE_USER_LABEL);

  useEffect(() => {
    if (removeUserLabelResult && removeUserLabelResult.removeUserLabel) {
      if (removeUserLabelResult.removeUserLabel.success) {
        enqueueSnackbar("Label deleted", {
          variant: "success",
        });
        setData((prev: any[]) =>
          prev.filter((item: any) => item.id !== selectedId),
        );
        setUpdateLabelBoolean(false);
        closeDialog();
      }
    }
  }, [removeUserLabelResult]);

  const handleDelete = async () => {
    await removeUserLabel({
      variables: { labelId: selectedId },
    });
  };

  useEffect(() => {
    try {
      getUserLabels({
        variables: {
          mainSuperAdminId,
          agentId,
          type: "all",
        },
      });
    } catch {
      enqueueSnackbar("Something went wrong.", {
        variant: "error",
      });
    }
  }, [mainSuperAdminId, agentId]);

  const openDelModal = (id: number) => {
    setSelectedId(id);
    setRemoveModal(true);
  };

  const addLabelHandler = async (values: any) => {
    const { label, type } = values;
    if (label) {
      setLabelText(label);
      try {
        await addUserLabel({
          variables: {
            label,
            mainSuperAdminId,
            agentId,
            type,
            author: username,
          },
        });
      } catch {
        enqueueSnackbar("Something went wrong.", {
          variant: "error",
        });
      }
    }
  };

  const [searchText, setSearchText] = useState("");

  const searchBarHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const searchFieldProps = {
    value: searchText,
    onChange: searchBarHandle,
    placeholder: "search",
    fieldClass: classes.searchFieldStyle,
    showEndIcon: true,
    endIcon: searchText && getGenericLabelLoading && (
      <CircularProgress size={20} sx={{ marginRight: "10px" }} />
    ),
  };

  const debouncedGetLabels = useCallback(
    _.debounce(async (searchingLabel) => {
      try {
        getUserLabels({
          variables: {
            mainSuperAdminId,
            agentId,
            type: "all",
            search: searchingLabel,
          },
        });
      } catch {
        enqueueSnackbar("Something went wrong.", {
          variant: "error",
        });
      }
    }, 500),
    [searchText],
  );

  useEffect(() => {
    debouncedGetLabels(searchText);
  }, [searchText]);

  return (
    <>
      <Header text="Tags help you categorize and organize your chats and tickets. Use them to filter results in Archives and Tickets, as well as narrow down data in Reports." />
      <Input
        labelText={labelText}
        addLabelHandler={addLabelHandler}
        updateLabelsQueryLoading={addUserLabelLoading && !updateLabelBoolean}
      >
        <SearchField {...searchFieldProps} />
      </Input>
      <Divider />
      <Box className={classes.root}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs="auto" container alignItems="center">
            <Box className={clsx(classes.square, classes.backgroundPersonal)} />
            <Typography className={classes.title}>My Label</Typography>
          </Grid>
          <Grid item xs="auto" container alignItems="center">
            <Box className={clsx(classes.square, classes.backgroundGeneric)} />
            <Typography className={classes.title}>Generic Label</Typography>
          </Grid>
        </Grid>
      </Box>
      <CardContent className={classes.cardContent}>
        <Box className={classes.tableContainer}>
          <Table stickyHeader className={classes.tableStyle}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeadings}>Labels</TableCell>
                <TableCell className={classes.tableHeadings}>
                  Created At
                </TableCell>
                <TableCell colSpan={4} className={classes.tableHeadings}>
                  Created by
                </TableCell>
              </TableRow>
            </TableHead>
            <GeneralizedLabelList
              data={data}
              openDelModal={openDelModal}
              loading={getGenericLabelLoading}
            />
          </Table>
        </Box>
      </CardContent>
      {removeModal && (
        <KuikwitDialog
          btnText="Delete"
          img={removeIcon}
          modalClose={closeDialog}
          modalOpen={removeModal}
          removeItem={handleDelete}
          text="Do you really want to delete this label?"
          loader={removeUserLabelLoading}
        />
      )}
    </>
  );
};

export default GeneralLabels;
