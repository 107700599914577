/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CardContent } from "@mui/material";
import axios from "axios";
import expressConfig from "src/config/express.json";
import useActiveUserDetail from "src/utils/shared/useActiveUserDetail";
import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import ADD_PAGE from "src/graphql/mutation/settings/addPage";
import { useSnackbar } from "notistack";
import Alert from "@mui/material/Alert";
import useStyle from "../Styles";
import NotConnectedBox from "./notConnectedBox";
import ConnectedBox from "./connectedBox";

declare global {
  interface Window {
    FB: any;
    fbq: any;
  }
}

interface Props {
  pageExistingError: string | null;
  handleExistingPagesError: (value: string) => void;
  setPagesData: (value: any) => void;
  loggedInUserDetail: any;
  setloggedInUserDetail: (value: any) => void;
}
const ExternalPage: React.FC<Props> = ({
  handleExistingPagesError,
  setPagesData,
  pageExistingError,
  loggedInUserDetail,
  setloggedInUserDetail,
}) => {
  const { classes } = useStyle();
  const { enqueueSnackbar } = useSnackbar();
  const { mainSuperAdminId } = useActiveUserDetail();
  const [addPages, { data: addPagesMutationResult }] = useMutation(ADD_PAGE);

  const env = process.env.NODE_ENV || "development";
  const config = expressConfig[env];
  const SOURCE = "facebook";
  const RESPONSE_TYPE = "token";

  useEffect(() => {
    if (addPagesMutationResult?.addpages?.error) {
      handleExistingPagesError(
        JSON.parse(addPagesMutationResult.addpages.error),
      );
    }
  }, [addPagesMutationResult]);

  const getAccessTokenByCodeForFacebook = async (
    id: string,
    accessToken: string,
    email: string,
  ) => {
    try {
      const {
        data: { data: pagesData },
      } = await axios.get(
        `https://graph.facebook.com/v19.0/${id}/accounts?fields=name,picture,access_token&access_token=${accessToken}`,
      );

      if (pagesData) {
        await Promise.all(
          pagesData.map(async (item: any) =>
            axios.post(
              `https://graph.facebook.com/${item.id}/subscribed_apps?subscribed_fields=messages,messaging_postbacks,message_reads,messaging_payments,message_deliveries,message_echoes,messaging_checkout_updates,standby,messaging_handovers,message_reactions,feed,inbox_labels,messaging_feedback&access_token=${item.access_token}`,
            ),
          ),
        );

        if (pagesData.length) {
          const createPageObjectValue = pagesData.map((item: any) => ({
            name: item.name,
            pageId: item.id,
            accesstoken: item.access_token,
            picture: item.picture.data.url,
            mainSuperAdminId,
            source: SOURCE,
            email,
          }));

          addPages({
            variables: {
              input: createPageObjectValue,
            },
          });

          setPagesData((prev: any[]) => {
            // Filter new page IDs that are not in the previous state
            const newPages = createPageObjectValue.filter(
              (item: { pageId: any }) =>
                !prev.some((i: any) => i.pageId === item.pageId),
            );

            // Get removed page IDs for the logged-in user
            const removedPageIds = prev
              .filter(
                (item: { email: string; pageId: string }) =>
                  item.email === email,
              )
              .filter(
                (item: { pageId: string }) =>
                  !createPageObjectValue.some(
                    (j: { pageId: string }) => j.pageId === item.pageId,
                  ),
              )
              .map((item: { pageId: string }) => item.pageId);

            // Update the existing pages with the new `isSelected` status
            const updatedPages = prev.map((j: { pageId: any }) => {
              if (removedPageIds.includes(j.pageId)) {
                return {
                  ...j,
                  isSelected: false,
                };
              }
              return j;
            });

            return [...newPages, ...updatedPages];
          });
        }
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong.", {
        variant: "error",
      });
    }
  };

  const openFacebookBuisnessLogin = async () => {
    try {
      const handleFbResponse = async (response: any) => {
        console.log(response, "response");
        if (response.authResponse) {
          const { accessToken } = response.authResponse;
          try {
            const user = await axios.get(
              `https://graph.facebook.com/me?access_token=${accessToken}&fields=id,name,email,picture.width(640).height(640)`,
            );
            setloggedInUserDetail(user.data);
            const { id, email } = user.data;
            const { data } = await axios.get(
              `https://graph.facebook.com/v20.0/oauth/access_token?
    grant_type=fb_exchange_token&
    client_id=${config.facebook_app_id}&
    client_secret=${config.FACEBOOK_APP_SECRET}&
    fb_exchange_token=${accessToken}`,
            );
            console.log(data, "data");
            const newtoken = data.access_token;
            await getAccessTokenByCodeForFacebook(id, newtoken, email);
          } catch (e) {
            console.log(e, "error for access token");
            enqueueSnackbar("Something went wrong.", {
              variant: "error",
            });
          }
        } else {
          enqueueSnackbar("Something went wrong.", {
            variant: "error",
          });
        }
      };

      await new Promise((resolve, reject) => {
        window.FB.login(
          async (response: any) => {
            try {
              const userData = await handleFbResponse(response);
              resolve(userData);
            } catch (error) {
              reject(error);
            }
          },
          {
            config_id: config.FACEBOOK_CONFIG_ID,
            response_type: RESPONSE_TYPE,
            override_default_response_type: true,
          },
        );
      });
    } catch (e) {
      enqueueSnackbar("Something went wrong.", {
        variant: "error",
      });
    }
  };

  const handleLogoutFacebook = () => {
    setloggedInUserDetail(null);
  };

  return (
    <CardContent className={classes.cardContent}>
      <Box
        sx={{
          height: 200,
        }}
      >
        {!loggedInUserDetail ? (
          <NotConnectedBox
            openFacebookBuisnessLogin={openFacebookBuisnessLogin}
          />
        ) : (
          <ConnectedBox
            user={loggedInUserDetail}
            logout={handleLogoutFacebook}
            handleAddMorePages={openFacebookBuisnessLogin}
          />
        )}
      </Box>
      {pageExistingError && (
        <Box mt={1}>
          <Alert severity="warning">{pageExistingError}</Alert>
        </Box>
      )}
    </CardContent>
  );
};

export default ExternalPage;
