import { useEffect, type FC } from "react";
import { Navigate, Outlet } from "react-router-dom";
import useActiveUserDetail from "src/utils/shared/useActiveUserDetail";
import expressConfig from "src/config/express.json";
import LoaderSpinner from "src/components/shared/atoms/SpinnerLoader";
import useAuth from "src/hooks/useAuth";
import MainLayout from "src/layout/MainLayout";
import { Box } from "@mui/material";

interface IProps {
  wsLink: any;
}
const AuthGuard: FC<IProps> = ({ wsLink }) => {
  const { loading, isAuthenticated } = useAuth() as any;
  const { agentId: loggedInUserId, mainSuperAdminId } = useActiveUserDetail();
  let count = 0;
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    count += 1;
    if (count === 1) {
      if (loggedInUserId) {
        if (loggedInUserId) {
          const env = process.env.NODE_ENV || "development";
          const config = expressConfig[env];

          wsLink.subscriptionClient.url = `${config.graphql_subscription_domain}:${config.port}/${config.graphql_subscription_endpoint}?userId=${loggedInUserId}&mainSuperAdminId=${mainSuperAdminId}`;
          wsLink.subscriptionClient.connect();
        } else {
          wsLink.subscriptionClient.url = undefined;
          wsLink.subscriptionClient.close(true, true);
        }
      }
    }
  }, [loggedInUserId]);

  if (loading) {
    // You can render a loading indicator here if needed
    return (
      <Box
        style={{
          height: "100vh",
          width: "100vw",
          backgroundColor: "#e6d9ec",
        }}
      >
        <LoaderSpinner />
      </Box>
    );
  }

  return isAuthenticated ? (
    <MainLayout>
      <Outlet />
    </MainLayout>
  ) : (
    <Navigate to="/login" />
  );
};

export default AuthGuard;
