/* eslint-disable react-hooks/exhaustive-deps */
// /* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import MeQuery from "src/graphql/query/auth/me";
import { useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { meHandler } from "src/store/reducers/meSlice";
import setAuthDataInLocalStorage from "src/utils/auth/LocalStorage";
import { enqueueSnackbar } from "notistack";

const useAuth = () => {
  const dispatch = useDispatch();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const [
    MeResponse,
    { data: meQueryResult, error: meQueryError, loading: meQueryLoading },
  ] = useLazyQuery(MeQuery, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        await MeResponse();
      } catch (error) {
        // Handle error as needed
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (meQueryResult && meQueryResult.me) {
      if (meQueryResult.me.status === "BLOCKED") {
        setIsAuthenticated(false);
        enqueueSnackbar("User is Blocked", {
          variant: "error",
        });
        return;
      }
      setAuthDataInLocalStorage(meQueryResult.me);
      //
      dispatch(meHandler(meQueryResult.me));
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [meQueryResult]);

  useEffect(() => {
    setLoading(meQueryLoading);
  }, [meQueryLoading]);

  useEffect(() => {
    if (meQueryError?.message) {
      setIsAuthenticated(false);
    }
  }, [meQueryError]);

  return { loading, isAuthenticated };
};

export default useAuth;
