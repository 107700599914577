import { gql } from "@apollo/client";

const UPDATE_PASSWORD = gql`
  mutation updatePassword(
    $id: ID!
    $password: String
    $oldPassword: String
    $confirmPassword: String
  ) {
    updatePassword(
      id: $id
      password: $password
      oldPassword: $oldPassword
      confirmPassword: $confirmPassword
    ) {
      success
      error
      result
    }
  }
`;
export default UPDATE_PASSWORD;
