import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { connectionHandler } from "src/store/reducers/connectionSlice";

const useNetwork = () => {
  const [isOnline, setNetwork] = useState(window.navigator.onLine);
  const dispatch = useDispatch();
  const updateNetwork = () => {
    setNetwork(window.navigator.onLine);
    dispatch(connectionHandler(window.navigator.onLine));
  };

  useEffect(() => {
    window.addEventListener("offline", updateNetwork);
    window.addEventListener("online", updateNetwork);

    return () => {
      window.removeEventListener("offline", updateNetwork);

      window.removeEventListener("online", updateNetwork);
    };
  });

  return isOnline;
};

export default useNetwork;
