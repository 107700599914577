/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable func-names */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-template */
/* eslint-disable no-console */
/* eslint-disable no-restricted-syntax */
import { WebSocketLink } from "@apollo/client/link/ws";
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  split,
  HttpLink,
} from "@apollo/client";
// import ApolloLinkTimeout from "apollo-link-timeout";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { getMainDefinition } from "@apollo/client/utilities";
import { enqueueSnackbar } from "notistack";
import expressConfig from "./express.json";

const apolloConfig = () => {
  const env = process.env.NODE_ENV || "development";
  const config = expressConfig[env];
  // const timeoutLink = new ApolloLinkTimeout(3000); // 2 second timeout

  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: Infinity,
      retryIf: (error, _operation) => {
        console.log("Error Details:");
        Object.keys(error).forEach((key) => {
          console.log(`${key}: ${error[key]}`);
        });
        if (error?.result?.errors?.AuthenticationError) {
          window.location.href = "/login";
        }
        console.log(
          "Retry condition triggered:",
          error,
          "_operation",
          _operation,
        );
        if (error === "Failed to fetch" || error?.message === "Failed to fetch")
          return true;
        return false;
      },
    },
  });

  const httpLink = new HttpLink({
    uri: `${config.graphql_domain}:${config.port}/${config.graphql_endpoint}`,
    credentials: "include",
  });

  // const timeoutHttpLink = timeoutLink.concat(httpLink);

  const wsLink = new WebSocketLink({
    uri: `${config.graphql_subscription_domain}:${config.port}/${config.graphql_subscription_endpoint}`,
    options: {
      timeout: 600000,
      minTimeout: 600000,
      reconnect: true,
      lazy: true,
    },
  });

  wsLink.subscriptionClient.on("connecting", () => {
    // dispatch(connectionHandler(false));
    console.log("connecting subs " + new Date().toString());
  });

  wsLink.subscriptionClient.on("connected", () => {
    // dispatch(connectionHandler(false));
    console.log("connected subs " + new Date().toString());
  });

  wsLink.subscriptionClient.on("reconnecting", () => {
    // dispatch(connectionHandler(true));
    console.log("reconnecting subs " + new Date().toString());
  });

  wsLink.subscriptionClient.on("reconnected", () => {
    console.log("reconnected subs " + new Date().toString());
  });

  wsLink.subscriptionClient.on("disconnected", () => {
    console.log("disconnected subs " + new Date().toString());
  });
  wsLink.subscriptionClient.on("onError", ({ message }) => {
    // store.dispatch(setChatBoxSubscriptionStatus(false));
    console.log(message + "  " + new Date().toString());
  });

  wsLink.subscriptionClient.maxConnectTimeGenerator.duration = () =>
    wsLink.subscriptionClient.maxConnectTimeGenerator.max;

  const logoutLink = onError(({ graphQLErrors, networkError }) => {
    if (networkError) {
      console.log(`[Network error]: ${networkError}`, networkError);
      return;
    }
    console.log(graphQLErrors, "graphQLErrors");

    // in session expired case graphQLErrors is {AuthenticationError : "Session is Expire"}
    if (graphQLErrors && graphQLErrors?.AuthenticationError) {
      enqueueSnackbar(graphQLErrors.AuthenticationError, {
        variant: "error",
      });
      window.location.href = "/login";
    }
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink,
    // timeoutHttpLink,
  );

  function linkErrorHandler({ graphQLErrors, networkError }) {
    if (graphQLErrors) {
      graphQLErrors?.forEach(({ message, locations, path }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
      });
    }

    if (networkError) {
      console.log("Unable to connect to server contact admin.");
    }
  }
  const apolloClient = new ApolloClient({
    link: ApolloLink.from([retryLink, logoutLink, splitLink]),
    onError: linkErrorHandler,
    cache: new InMemoryCache(),
  });

  window.Object.freeze = function (obj) {
    return obj;
  };

  return { apolloClient, wsLink };
};

export default apolloConfig;
