/* eslint-disable react/require-default-props */
import React from "react";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

interface CustomAvatarProps {
  imageUrl: string | undefined | null;
  onClick: any;
  sx?: React.CSSProperties;
  id?: string;
  handleLoad?: any;
  className?: any;
}

const AddImageAvatar: React.FC<CustomAvatarProps> = ({
  imageUrl,
  onClick,
  sx,
  id,
  handleLoad,
  className,
}) => {
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      badgeContent={<PhotoCameraIcon />}
      onClick={onClick}
    >
      <Avatar
        className={className}
        onLoad={handleLoad}
        src={imageUrl || ""}
        sx={sx}
        id={id}
      />
    </Badge>
  );
};
export default AddImageAvatar;
