import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    flexDirection: "column",
    boxSizing: "border-box",
    padding: "35px",
  },
  box: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // height: "80%",
  },
  form: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
    flexDirection: "column",
  },
}));

export default useStyles;
