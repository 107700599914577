/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { meHandler } from "src/store/reducers/meSlice";
import AddImageAvatar from "src/components/shared/atoms/addImageAvatar/AddImageAvatar";
import { enqueueSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import KuikTextField from "src/components/shared/atoms/textField/TextField";
import LinesSkeleton from "src/components/shared/molecules/linesSkeleton";
import { useFormik } from "formik";
import * as Yup from "yup";
import UPDATE_USER from "src/graphql/mutation/user/updateUser";
import { useMutation } from "@apollo/client";
import useActiveUserDetail from "src/utils/shared/useActiveUserDetail";
import expressConfig from "src/config/express.json";
import KuikwitButton from "src/components/shared/atoms/button";
import { FormValuesProps } from "./Types";
import useStyles from "./Styles";

const Details = ({
  meQueryResult,
  MeQueryLoading,
}: {
  meQueryResult: any;
  MeQueryLoading: boolean;
}) => {
  const { classes } = useStyles();
  const [file, setFile] = useState<any>(null);
  const [updloadFileLoading, setUpdloadFileLoading] = useState<any>(false);
  const [newImageUploaded, setNewImageUploaded] = useState(false);
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);

  const dispatch = useDispatch();

  const { agentId } = useActiveUserDetail();
  const [preview, setPreview] = useState<any>(null);
  const [initialFormikValues] = useState({
    name: "",
    pname: "",
    phone: "",
  });
  useEffect(() => {
    if (meQueryResult && meQueryResult.me) {
      const { picture, number, pseudonym, username } = meQueryResult.me;
      formik.setValues({
        name: username || "",
        pname: pseudonym || "",
        phone: number || "",
      });
      setPreview(picture);
    }
  }, [meQueryResult.me]);

  const openFileUpload = () => {
    setFile(null);
    setNewImageUploaded(true);
    if (hiddenFileInput.current !== null) {
      hiddenFileInput?.current.click();
    }
  };

  useEffect(() => {
    if (!file) {
      // setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);
    // eslint-disable-next-line consistent-return
    // return () => URL.revokeObjectURL(objectUrl);
    //
    setUpdloadFileLoading(true);
    const env = process.env.NODE_ENV || "development";
    const config = expressConfig[env];

    const formData = new FormData();
    formData.append(
      "operations",
      JSON.stringify({
        query:
          "mutation ($file: Upload!, $agentId: ID!) { profileUpload(file: $file, agentId: $agentId) {result,success,error}}",
        variables: {
          file: null,
          agentId,
        },
      }),
    );
    formData.append("map", JSON.stringify({ "0": ["variables.file"] }));
    formData.append("0", file);
    let imagePath = preview;

    if (newImageUploaded) {
      fetch(
        `${config.graphql_domain}:${config.port}/${config.graphql_endpoint}`,
        {
          method: "POST",
          body: formData,
        },
      )
        .then((response) => response.json())
        .then(({ data }) => {
          console.log("image uploaded data", data);
          if (data.profileUpload.success) {
            imagePath = data.profileUpload.result;
            console.log("imagePath__", imagePath);
            //
            const newData = {
              id: agentId,
              picture: imagePath,
            };
            updateUser({
              variables: newData,
            });
            //
          } else {
            enqueueSnackbar("File Upload Failed, Retry", {
              variant: "error",
            });
          }
        })
        .then(() => {
          setUpdloadFileLoading(false);
          dispatch(meHandler({ picture: imagePath }));
        })
        .catch((error) => {
          console.error("Error during file upload:", error);
          enqueueSnackbar("An error occurred during file upload.", {
            variant: "error",
          });
        });
    }
  }, [file]);

  const handleSelectImage: any = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const val = event.target.value;
    const file2 = event.target.files?.[0];
    const regex = /(\.jpg|\.jpeg|\.png)$/i;
    if (!regex.exec(val)) {
      enqueueSnackbar("File type must be JPG, JPEG or PNG", {
        variant: "error",
      });
      return;
    }
    if (file2) {
      setFile(file2);
    }
  };

  const [
    updateUser,
    {
      loading: updateMutationLoading,
      error: updateMutationError,
      data: updateMutationResult,
    },
  ] = useMutation(UPDATE_USER);
  useEffect(() => {
    if (updateMutationError && updateMutationError) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  }, [updateMutationError]);

  useEffect(() => {
    if (updateMutationResult && updateMutationResult.updateuser?.success) {
      enqueueSnackbar("Profile updated successfully", { variant: "success" });
    }
  }, [updateMutationResult]);

  const formikValidationSchema = Yup.object().shape({
    name: Yup.string().required("name is required"),
  });

  const handleFormikSubmit = async (
    FormValues: FormValuesProps,
    formikHelpers: any,
  ) => {
    formikHelpers.setSubmitting(false);
    try {
      const newData = {
        id: agentId,
        username: FormValues.name,
        pseudonym: FormValues.pname,
        number: String(FormValues.phone),
      };
      await updateUser({
        variables: newData,
      });
      setNewImageUploaded(false);
    } catch (error) {
      formikHelpers.setSubmitting(false);
    } finally {
      setUpdloadFileLoading(false);
    }
  };
  const formik = useFormik({
    initialValues: initialFormikValues,
    validationSchema: formikValidationSchema,
    onSubmit: handleFormikSubmit,
  });

  return (
    <>
      {!MeQueryLoading ? (
        <Box>
          <Box sx={{ mb: 2 }}>
            <AddImageAvatar
              id="uploadFile"
              onClick={openFileUpload}
              // imageUrl={!preview ? "" : preview}
              imageUrl={preview}
              className={classes.avatar}
            />
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleSelectImage}
              id="uploadFile"
              name="uploadFile"
              style={{ display: "none" }}
            />
          </Box>
          <Box>
            <Typography variant="subtitle2">User Name</Typography>
            <KuikTextField
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              helperText={formik.touched.name && formik.errors.name}
              label="Enter your full name"
            />
          </Box>
          <Box mt={2}>
            <Typography variant="subtitle2">Pseudonym Name</Typography>
            <KuikTextField
              id="pname"
              name="pname"
              value={formik.values.pname}
              onChange={formik.handleChange}
              helperText={formik.touched.pname && formik.errors.pname}
              label="Enter Pseudonym"
            />
          </Box>
          <Box mt={2}>
            <Typography variant="subtitle2">Contact number</Typography>
            <KuikTextField
              id="phone"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              helperText={formik.touched.phone && formik.errors.phone}
              label="Enter your contact number"
            />
          </Box>
          <Box mt={2}>
            <KuikwitButton
              type="submit"
              handleClick={formik.handleSubmit}
              className={classes.button}
              variant="contained"
              circular={updateMutationLoading || updloadFileLoading}
            >
              Save Changes
            </KuikwitButton>
          </Box>
        </Box>
      ) : (
        <LinesSkeleton noOfLines={6} />
      )}
    </>
  );
};

export default Details;
