import { makeStyles } from "tss-react/mui";

const useStyle = makeStyles()((theme: any) => ({
  iconBox: {
    display: "flex",
    height: "37px",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    height: "24px",
    width: "24px",
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
  card: {
    height: "calc(100vh - 112px)",
    borderRadius: "10px",
    boxShadow: "0px 5px 10px rgba(127, 63, 152, 0.1)",
    padding: "20px",
    minWidth: 275,
    [theme.breakpoints.down("md")]: {
      height: "calc(100vh - 160px)",
    },
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 220px)",
    },
  },
  root: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    alignItems: "center",
  },
  square: {
    width: 12,
    height: 12,
    marginRight: theme.spacing(1),
  },
  backgroundPersonal: {
    backgroundColor: "#D1E9F6",
  },
  backgroundGeneric: {
    backgroundColor: "#F1D3CE",
  },
  title: {
    fontWeight: "bold",
    fontSize: "14px",
    color: theme.palette.text.primary,
  },
  cardContent: {
    marginTop: 20,
    width: "100%",
    padding: 0,
    overflow: "auto",
  },
  tableContainer: {
    padding: 0,
    margin: 0,
    width: "100%",
    overflow: "auto",
    [theme.breakpoints.down("lg")]: {
      height: "calc(100vh - 460px)",
    },
    [theme.breakpoints.up("mxl")]: {
      height: "calc(100vh - 440px)",
    },
    height: "calc(100vh - 460px)",
    // scrollbar styling
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc",
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
  },
  tableStyle: {
    // minWidth: "600px",
    padding: "0px",
  },
  headerText: {
    fontWeight: "500",
    fontSize: "16px",
    LineHeight: "24px",
    padding: "0px",
  },
  subHeading: {
    fontWeight: "400",
    fontSize: "14px",
    LineHeight: "21px",
    color: "#838383",
  },
  text: {
    fontSize: "12px",
    lineHeight: "16px",
    color: "#838383",
  },
  labelInput: {
    height: "40px",
    width: "100%",
    top: "0px",
    padding: 0,
    margin: 0,
    borderRadius: "5px",
  },
  addButton: {
    height: "40px",
    width: "100%",
    padding: "0px",
    margin: "0px",
    textTransform: "capitalize",
    borderRadius: "5px",
    fontSize: 14,
    fontWeight: "500",
    textAlign: "center",
  },
  inputBox: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      width: "100%", // Full width on medium and larger screens
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%", // Full width on small screens
    },
  },
  fieldStyle: {
    width: "100%",
    color: theme.palette.primary.main,
    fontSize: 14,
    padding: 0,
    margin: 0,
    fontWeight: 400,
    // borderRadius: "15px",
    border: `1px solid ${theme.palette.primary.dark}`,
    boxShadow: "none",
    "& .MuiSelect-select": {
      padding: "7px 7px", // Adjust padding if needed
      display: "flex",
      alignItems: "center",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.primary.dark}`,
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.primary.dark}`,
      // borderRadius: "15px",
    },
  },

  searchFieldStyle: {
    color: "#838383",
    fontSize: 14,
    fontWeight: 400,
    borderRadius: "15px",
    // width: 230,
    height: 40,
  },

  tableHeadings: {
    fontWeight: "bold",
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.primary,
    minWidth: "250px",
    padding: theme.spacing(1),
  },
  tbody: {
    height: "50px",
    padding: "0px 0px 0px 10px",
    borderBottom: "1px solid #DFE2E5",
  },
  tableText: {
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#272525",
  },
  deleteIconStyle: {
    color: "#838383",
    cursor: "pointer",
  },
  labelCancelButton: {
    textTransform: "capitalize",
    height: "30px",
    fontSize: "11px",
    fontWeight: "500",
    lineHeight: "18px",
    background: "#F5F5F5",
    color: "#777777",
    "&:hover": {
      background: "#F5F5F5",
      color: "#777777",
    },
    marginRight: "5px",
  },
  labelRemoveButton: {
    textTransform: "capitalize",
    height: "30px",
    fontSize: "11px",
    fontWeight: "500",
    lineHeight: "18px",
    marginLeft: "5px",
  },
  loadingCircularProgress: {
    margin: "auto",
    display: "block",
    marginTop: 20,
  },
}));

export default useStyle;
