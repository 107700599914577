import { gql } from "@apollo/client";

const ADD_USER_CANNED_RESPONSE = gql`
  mutation addUserCannedResponse(
    $mainSuperAdminId: ID!
    $agentId: ID!
    $type: String!
    $short: String!
    $long: String!
  ) {
    addUserCannedResponse(
      mainSuperAdminId: $mainSuperAdminId
      agentId: $agentId
      type: $type
      short: $short
      long: $long
    ) {
      success
      error
      result
    }
  }
`;

export default ADD_USER_CANNED_RESPONSE;
