/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Typography from "src/components/shared/atoms/typography";
import Box from "src/components/shared/atoms/box";
import { useNavigate } from "react-router-dom";
import useStyles from "./Styles";

const Footer = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <Box className={classes.box}>
      <Typography className={classes.footer}>
        Already have an account? &nbsp;{" "}
        <span
          style={{ color: "#0d6efd", fontWeight: 400, cursor: "pointer" }}
          onClick={() => navigate("/login")}
        >
          Sign in
        </span>
      </Typography>
    </Box>
  );
};

export default Footer;
