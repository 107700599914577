/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Typography, TableRow, TableCell } from "@mui/material";
import Avatar from "src/components/shared/atoms/avatar";
import clsx from "clsx";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "src/components/shared/atoms/button";
import IconButton from "@mui/material/IconButton";
import useStyle from "../../Styles";

interface Props {
  curr: any;
  connectedHandler: any;
  disconnectedHandler: any;
  closeHandler: any;
  loading: boolean;
  disabled: boolean;
}

const InternalRow: React.FC<Props> = ({
  curr,
  closeHandler,
  connectedHandler,
  disconnectedHandler,
  loading,
  disabled,
}) => {
  const { classes } = useStyle();

  return (
    <TableRow>
      <TableCell sx={{ display: "flex", alignItems: "center" }}>
        <Avatar
          src={curr.picture}
          variant="square"
          className={classes.avatar}
        />
        <Typography
          component="p"
          sx={{ display: "inline-block" }}
          className={classes.pageName}
        >
          {curr.name}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Button
          variant="outlined"
          handleClick={
            curr.isSelected
              ? () => disconnectedHandler(curr)
              : () => connectedHandler(curr)
          }
          className={clsx({
            [classes.colorDisconnected]: curr.isSelected,
            [classes.colorConnected]: !curr.isSelected,
          })}
          disabled={disabled}
          circular={loading}
        >
          {curr.isSelected ? "Disconnect Page" : "Connect Page"}
        </Button>
      </TableCell>
      <TableCell align="left">
        <IconButton onClick={() => closeHandler(curr)}>
          <DeleteIcon className={classes.closeIcon} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default InternalRow;
