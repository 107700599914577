import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  footer: {
    fontSize: "12px",
    width: "100%",
    //
    //
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "100%",
    },
    color: theme.palette.text.primary,
    fontWeight: 500,
    lineHeight: "18px",
  },
  box: {
    display: "flex",
    marginTop: 30,
  },
}));
export default useStyles;
