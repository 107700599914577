import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  appbar: {
    zIndex: 100,
    background: "#E6D9EC",
    position: "sticky",
    left: "auto",
    right: "auto",
  },
}));
export default useStyles;
