import { makeStyles } from "tss-react/mui";

const useStyle = makeStyles()(() => ({
  button: {
    height: "32px",
    width: "150px",
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: "400",
    // "&:hover": { backgroundColor: "none" },
  },
  buttonStyleDisconnected: {
    color: "#E34D59",
    backgroundColor: "#fff2f3",
    borderColor: "#E34D59",
    borderRadius: "3px",
    // width: "130px",
    padding: 10,
    height: "32px",
    textTransform: "none",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "18px",
  },
  buttonStyleConnected: {
    color: "#22AA61",
    backgroundColor: "#ecf7f3",
    borderColor: "#22AA61",
    borderRadius: "3px",
    // width: "130px",
    height: "32px",
    padding: 10,
    textTransform: "none",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "18px",
  },
  headerBox: {
    display: "flex",
    alignItems: "center",
  },
  avtarStyle: {
    height: "46px",
    width: "46px",
    marginRight: "10px",
  },
  headerHeading: {
    fontSize: "16px",
    fontWeight: "500",
    // height: "24px",
    marginBottom: "5px",
  },
  headerSubHeading: {
    // width: '401px',
    width: "100%",
    fontWeight: "400",
    fontSize: "14px",
    // height: "21px",
    color: "#838383",
  },
  formControl: {
    display: "block",
  },
  formInput: {
    padding: "0px",
    // width: '60%',
    maxWidth: "350px",
    width: "100%",
    height: "40px",
    borderRadius: "3px",
    // color:'#E8E8E8'
  },
  inputLabel: {
    // width: '60px',
    width: "100%",
    height: "18px",
    lineHeight: "18px",
    display: "block",
    fontSize: "12px",
    fontWeight: "400",
    color: "#777777",
  },
  buttonStyle: {
    height: "40px",
    maxWidth: "350px",
    width: "100%",
    borderRadius: "3px",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "18px",
    marginTop: "10px",
  },
  text: {
    width: "100%",
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: "400",
    color: "#838383",
    marginTop: "10px",
  },
  replySection: {
    fontSize: "24px",
    fontWeight: "600",
    lineHeight: "26px",
    textAlign: "center",
    color: "#272525",
    height: "52px",
    // width: "278px",
    borderRadius: "nullpx",
    // marginLeft: "130px",
  },
  internalCard: {
    height: "400px",
    width: "100%",
    margin: "20px 0px 148px 0px",
    borderRadius: "5px",
    boxShadow: "0px 2px 5px 0px rgba(0, 0, 0, 0.05)",
    marginLeft: "-20px",
  },
}));

export default useStyle;
