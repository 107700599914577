import React from "react";
import { Box } from "@mui/material";
import Heading from "src/components/shared/atoms/heading/Heading";
import { Text } from "src/components/shared/atoms/text/Text";
import SocialLoginButton from "src/components/pages/auth/shared/socialLoginButton";
import Footer from "src/components/pages/auth/shared/loginFooter";
import Form from "src/components/pages/auth/login/form";
import Divider from "@mui/material/Divider";
import DynamicHead from "src/components/shared/atoms/helmet";

import BoxDesign from "src/components/pages/auth/shared/boxDesign";
import useStyles from "./Styles";

const Login: React.FC = () => {
  const { classes } = useStyles();
  return (
    <>
      <DynamicHead title="Login" />
      <Box className={classes.root}>
        <BoxDesign />
        <Heading heading="Hey, welcome Back" />
        <Text text="Enter your credentials to access your account" />
        <Box className={classes.form}>
          {/* sign in form */}
          <Form />
          <Box className={classes.style}>
            <Box className={classes.divider}>
              <Divider className={classes.dividerText}>or</Divider>
            </Box>
            <Box className={classes.style}>
              <SocialLoginButton type="google" />
              <SocialLoginButton type="facebook" />
            </Box>
            {/* footer */}
            <Footer />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Login;
