import { gql } from "@apollo/client";

const GETNOTIFICATIONS = gql`
  query getNotifications($inboxId: ID) {
    getNotifications(inboxId: $inboxId) {
      success
      error
      result
    }
  }
`;

export default GETNOTIFICATIONS;
