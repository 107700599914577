import { gql } from "@apollo/client";

const GET_USER_LABELS = gql`
  query getUserLabels(
    $mainSuperAdminId: ID!
    $agentId: ID!
    $type: String!
    $search: String
  ) {
    getUserLabels(
      mainSuperAdminId: $mainSuperAdminId
      agentId: $agentId
      type: $type
      search: $search
    ) {
      id
      label
      mainSuperAdminId
      agentId
      createdAt
      author
      type
    }
  }
`;

export default GET_USER_LABELS;
