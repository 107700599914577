/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Box from "src/components/shared/atoms/box";
import TextField from "src/components/shared/atoms/textField/TextField";
import AuthButton from "src/components/pages/auth/shared/button/AuthButton";
import { enqueueSnackbar } from "notistack";
import FORGET_PASSWORD from "src/graphql/mutation/auth/forgetPassword";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { BackendErrorProps, FormValues } from "./Types";
import useStyles from "./Styles";

const Form = () => {
  const [backendError, setBackendError] = useState<BackendErrorProps | null>(
    null,
  );
  const { classes } = useStyles();
  const navigate = useNavigate();

  const [
    requestResetToken,
    {
      loading: requestResetTokenQueryLoading,
      data: requestResetTokenQueryResult,
    },
  ] = useMutation(FORGET_PASSWORD);

  useEffect(() => {
    if (
      requestResetTokenQueryResult &&
      requestResetTokenQueryResult.requestresettoken
    ) {
      if (!requestResetTokenQueryResult.requestresettoken.success) {
        const error = JSON.parse(
          requestResetTokenQueryResult.requestresettoken.error,
        );
        setBackendError(error);
      } else {
        enqueueSnackbar(requestResetTokenQueryResult.requestresettoken.error, {
          variant: "success",
        });
        navigate("/login");
      }
    }
  }, [requestResetTokenQueryResult]);

  // validation schema using Yup
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });
  const handleSubmit = async (
    values: FormValues,
    // { setSubmitting }: FormikHelpers<FormValues>
    { setSubmitting }: any,
  ) => {
    // handle form submission
    setSubmitting(false);
    try {
      await requestResetToken({
        variables: {
          email: values.email,
        },
      });
    } catch (err: any) {
      enqueueSnackbar("Something went wrong. Try again.", { variant: "error" });
    }
  };

  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formik: any) => (
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          className={classes.form}
        >
          <TextField
            id="email"
            label="Email Address"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            helperText={
              (backendError && backendError.email) ||
              (formik.touched.email && formik.errors.email)
            }
          />
          <AuthButton
            text="Send instructions"
            loading={requestResetTokenQueryLoading}
          />
        </Box>
      )}
    </Formik>
  );
};

export default Form;
