/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { useTheme } from "@mui/material/styles";
import { Box, CssBaseline, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import Header from "src/components/layout/mainLayout/header";
import LeftNavigationList from "src/components/layout/mainLayout/navigationList";
import BottomNavigationList from "src/components/layout/mainLayout/bottomNavigation";
import { useState, useEffect, type FC, type ReactNode } from "react";
import Main from "src/components/layout/mainLayout/main/Main";
import useNetwork from "src/hooks/useNetwork";
import useStyles from "./Styles";

interface MainLayoutProps {
  children?: ReactNode;
}

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const theme = useTheme();
  const isOnline = useNetwork();
  const [prevIsOnline, setPrevIsOnline] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { classes } = useStyles();

  useEffect((): any => {
    if (!isOnline) {
      enqueueSnackbar("Internet connection lost. Please check your network.", {
        variant: "error",
      });
    } else if (isOnline && prevIsOnline) {
      enqueueSnackbar("Internet connection recovered.", {
        variant: "success",
      });
    }
  }, [isOnline, prevIsOnline, enqueueSnackbar]);

  useEffect(() => {
    if (!isOnline) setPrevIsOnline(true);
  }, [isOnline]);

  return (
    <Box className={classes.box}>
      <CssBaseline />
      <Grid className={classes.grid}>
        <LeftNavigationList />
      </Grid>
      <Grid className={classes.gridg}>
        <Grid className={classes.gridHeader}>
          {" "}
          <Header />
        </Grid>
        <Grid className={classes.gridMain}>
          <Main theme={theme}>
            <Box className={classes.mainBox}>{children}</Box>
          </Main>
        </Grid>
        <BottomNavigationList />
      </Grid>
    </Box>
  );
};

export default MainLayout;
