/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-constant-condition */
import React, { useEffect, useState } from "react";
import { Box, Chip, MenuItem, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import BadgeWAvatar from "src/components/shared/atoms/badgeWAvatar";
import useActiveUserDetail from "src/utils/shared/useActiveUserDetail";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Button from "src/components/shared/atoms/button";
import LogoutIcon from "@mui/icons-material/Logout";
import { useMutation } from "@apollo/client";
import LogoutQuery from "src/graphql/query/auth/logout";
import { useSelector } from "react-redux";
import useStyles from "./Styles";

const ProfileBottom = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { classes } = useStyles();
  const { panelType } = useActiveUserDetail();

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [logout, { data: logoutQueryResult }] = useMutation(LogoutQuery);
  useEffect(() => {
    if (logoutQueryResult && logoutQueryResult.logout.success) {
      localStorage.removeItem("accessToken");
      window.location.href = "/login";
    }
  }, [logoutQueryResult]);

  const { me }: any = useSelector((state: any) => state.me);

  const { name, picture, onlineStatus } = me ?? {};

  return (
    <>
      <Box className={classes.profile} onClick={handleOpen}>
        <AccountCircleIcon />
        <Typography className={classes.type}>Profile</Typography>
      </Box>

      {/* Menu */}
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        className={classes.menu}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              width: 230,
              height: 200,
              overflow: "visible",
              overflowY: "auto",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "top" }}
      >
        <MenuItem className={classes.menuItem} disableRipple>
          <Box className={classes.box}>
            <BadgeWAvatar
              login={onlineStatus}
              picture={picture}
              onClick={handleOpen}
              className={classes.avatar}
            />
          </Box>
        </MenuItem>
        <Typography className={classes.profileName}>{name}</Typography>
        <Box className={classes.boxChip}>
          <Chip className={classes.chip} label={panelType} />
        </Box>

        <MenuItem
          className={`${classes.menuItem} ${classes.paddingTop}`}
          disableRipple
          disableTouchRipple
        >
          <Button
            variant="outlined"
            className={classes.button}
            handleClick={() => logout()}
          >
            <LogoutIcon className={classes.icon} />
            <Typography className={classes.marginLeft}>Logout</Typography>
          </Button>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileBottom;
