import { gql } from "@apollo/client";

const ADDORUPDATENOTIFICATION = gql`
  mutation addOrUpdateNotification(
    $inboxId: ID!
    $read: Boolean
    $dateandtime: String
    $customerName: String
    $customerPicture: String
    $update: Boolean
  ) {
    addOrUpdateNotification(
      inboxId: $inboxId
      read: $read
      dateandtime: $dateandtime
      customerName: $customerName
      customerPicture: $customerPicture
      update: $update
    ) {
      success
      error
      result
    }
  }
`;

export default ADDORUPDATENOTIFICATION;
