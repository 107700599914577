import React from "react";
import { FormControl, FormHelperText, OutlinedInput } from "@mui/material";
import useStyle from "../Styles";

interface Props {
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  helperText: string;
}
const TwilioInputUI: React.FC<Props> = ({
  label,
  onChange,
  value,
  helperText,
}) => {
  const { classes } = useStyle();
  return (
    <FormControl className={classes.formControl} sx={{ marginBottom: "10px" }}>
      <label className={classes.inputLabel} htmlFor={label}>
        {label}
      </label>
      <OutlinedInput
        id="accountSID"
        className={classes.formInput}
        name={label}
        onChange={onChange}
        value={value}
      />
      <FormHelperText error id="accountId-error">
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default TwilioInputUI;
