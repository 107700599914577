/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { Box, Typography } from "@mui/material";
import UPDATE_PASSWORD from "src/graphql/mutation/settings/updatePassword";
import KuikwitButton from "src/components/shared/atoms/button";
import { enqueueSnackbar } from "notistack";
import useActiveUserDetail from "src/utils/shared/useActiveUserDetail";
import KuikTextField from "src/components/shared/atoms/textField/TextField";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import * as Yup from "yup";
import { BackendErrorProps, PasswordProps } from "./Types";
import useStyles from "./Styles";

const ChangePassword = () => {
  const { classes } = useStyles();

  const [initialFormikValuesForPass] = useState({
    oldpass: "",
    newpass: "",
    conpass: "",
  });
  const { agentId } = useActiveUserDetail();

  const [
    updatePassword,
    {
      loading: updatePasswordMutationLoading,
      data: updatePasswordMutationResult,
    },
  ] = useMutation(UPDATE_PASSWORD);

  const [backendError, setBackendError] = useState<BackendErrorProps | null>(
    null,
  );

  useEffect(() => {
    if (updatePasswordMutationResult) {
      if (!updatePasswordMutationResult.updatePassword.success) {
        const backendErrors = JSON.parse(
          updatePasswordMutationResult.updatePassword.error,
        );
        setBackendError(backendErrors);
      } else {
        enqueueSnackbar(updatePasswordMutationResult.updatePassword.error, {
          variant: "success",
        });
        setBackendError(null);
        handleClear();
      }
    }
  }, [updatePasswordMutationResult]);

  const passwordValidationSchema = Yup.object().shape({
    oldpass: Yup.string()
      .min(3, "password must be at least 8 characters")
      .required("old password is required"),
    newpass: Yup.string()
      .min(8, "password must be at least 8 characters")
      .required("new password is required"),
    conpass: Yup.string()
      .oneOf([Yup.ref("newpass")], "password must match")
      .required("please confirm your password"),
  });

  const passwordHandleSubmit = async (
    values: PasswordProps,
    formikHelpers: any,
  ) => {
    try {
      formikHelpers.setSubmitting(false);
      await updatePassword({
        variables: {
          id: agentId,
          oldPassword: values.oldpass,
          password: values.newpass,
          confirmPassword: values.conpass,
        },
      });
    } catch (err: any) {
      enqueueSnackbar("Something went wrong! Try again.", {
        variant: "error",
      });
      formikHelpers.setSubmitting(false);
    }
  };
  const formikForChangePass = useFormik({
    initialValues: initialFormikValuesForPass,
    validationSchema: passwordValidationSchema,
    onSubmit: passwordHandleSubmit,
  });
  const handleClear = () => {
    formikForChangePass.resetForm();
  };

  return (
    <>
      <Typography mt={3} variant="subtitle1">
        Change password
      </Typography>
      <Box mt={2}>
        <KuikTextField
          id="old"
          name="oldpass"
          typeField="password"
          value={formikForChangePass.values.oldpass}
          onChange={formikForChangePass.handleChange}
          helperText={
            (backendError && backendError.oldPassword) ||
            (formikForChangePass.touched.oldpass &&
              formikForChangePass.errors.oldpass)
          }
          label="Enter your old password"
        />
      </Box>
      <Box mt={2}>
        <KuikTextField
          id="newpass"
          typeField="password"
          name="newpass"
          value={formikForChangePass.values.newpass}
          onChange={formikForChangePass.handleChange}
          helperText={
            (backendError && backendError.newPassword) ||
            (formikForChangePass.touched.newpass &&
              formikForChangePass.errors.newpass)
          }
          label="Enter your new password"
        />
      </Box>
      <Box mt={2}>
        <KuikTextField
          id="conpass"
          typeField="password"
          name="conpass"
          value={formikForChangePass.values.conpass}
          onChange={formikForChangePass.handleChange}
          helperText={
            (backendError && backendError.confirmedPassword) ||
            (formikForChangePass.touched.conpass &&
              formikForChangePass.errors.conpass)
          }
          label="Re-enter your new password"
        />
      </Box>
      <Box mt={2} mb={5}>
        <KuikwitButton
          type="submit"
          handleClick={formikForChangePass.handleSubmit}
          variant="contained"
          className={classes.button}
          disabled={updatePasswordMutationLoading}
        >
          Change Password
        </KuikwitButton>
      </Box>
    </>
  );
};

export default ChangePassword;
