import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  outlinedInput: {
    width: "100%",
    height: "30px",
    border: "0.0px solid #E8E8E8",
    borderRadius: "3px",
    // padding: "6px 10px 6px 10px",
    marginTop: "5px",
    marginBottom: "0px",
    "& input::-webkit-input-placeholder": {
      fontWeight: "500",
      fontFamily: "poppins",
      fontSize: "12px",
      lineHeight: "15px",
      color: "#272525",
    },
  },
  button: {
    height: "40px",
    width: "200px",
  },
  avatar: {
    height: "80px !important",
    width: "80px!important",
    cursor: "pointer",
    margin: "0 auto",
  },
  headerText: {
    p: 0,
    m: 0,
    marginBottom: 10,
    fontWeight: "bold",
    fontSize: 16,
  },
  box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "left",
    overflowY: "auto",
    flexDirection: "column",
    width: "40%",
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "98%",
    },
  },
  cardContent: {
    width: "100%",
    // overflowY: "auto",
    height: "100%",
    padding: 0,
  },
  marginTop: {
    marginTop: "1rem",
  },
}));

export default useStyles;
