import { Box, CardContent, Divider } from "@mui/material";
import MeQuery from "src/graphql/query/auth/me";
import CircularProgress from "src/components/shared/atoms/circularProgress/CircularProgress";
import { useQuery } from "@apollo/client";
import useStyles from "./Styles";
import ChangePassword from "./ChangePassword";
import Details from "./Details";

const ProfileSettings = () => {
  const { classes } = useStyles();
  const { data: meQueryResult, loading: MeQueryLoading } = useQuery(MeQuery, {
    fetchPolicy: "network-only",
  });
  return (
    <>
      <Box className={classes.headerText}> Profile Details</Box>
      <CardContent className={classes.cardContent}>
        {!MeQueryLoading ? (
          <Box className={classes.box}>
            <Details
              meQueryResult={meQueryResult}
              MeQueryLoading={MeQueryLoading}
            />
            <Divider className={classes.marginTop} />
            <ChangePassword />
          </Box>
        ) : (
          <Box
            sx={{
              height: "90%",
              display: "flex",
              overflow: "hidden",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </CardContent>
    </>
  );
};

export default ProfileSettings;
