import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  box: {
    p: 0,
    m: 0,
    fontWeight: "bold",
    fontSize: 16,
    alignItems: "center",
    display: "flex",
  },
  backButton: {
    marginRight: 2,
    padding: 0,
    cursor: "pointer",
    border: "2px solid transparent",
    "&:hover": {
      opacity: 0.7,
    },
  },
  heading: {
    width: "100%",
    fontWeight: "500",
    fontFamily: "poppins",
    fontSize: "16px",
    color: "#272525",
  },
  fieldStyle: {
    marginTop: 10,
    width: "100%",
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 400,
    borderRadius: "15px",
    border: `1px solid ${theme.palette.primary.dark}`,
    boxShadow: "none",
    "& .MuiSelect-select": {
      padding: "10px 14px", // Adjust padding if needed
      display: "flex",
      alignItems: "center",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.primary.dark}`,
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.primary.dark}`,
      borderRadius: "15px",
    },
  },
  textArea: {
    width: "100%",
    height: "100px",
    resize: "none",
    marginTop: 10,
    background: "#FFFFFF",
    border: `2px solid ${theme.palette.primary.dark}`,
    borderRadius: "15px",
    padding: 10,
    fontFamily: "poppins",
    fontWeight: "400",
    fontSize: 14,
    lineHeight: "18px",
    color: "#272525",
    // "&:focus": {
    //   outline: "none",
    //   border: `1px solid ${theme.palette.primary.dark}`, // Ensure the border remains the same on focus
    // },
  },
  savebutton: {
    display: "flex",
    height: 45,
    width: "100%",
    justifyContent: "center",
    borderRadius: 15,
    marginTop: "15px",
  },
  formikText: {
    fontWeight: "400",
    fontFamily: "poppins",
    fontSize: "12px",
    height: "18px",
  },

  cardContent: {
    padding: 0,
    margin: 0,
    // border: "10px solid green",
    // margin: "20px 0px",
  },
  typography: {
    fontFamily: "poppins",
    fontWeight: "400",
    fontSize: "14px",
    color: "#838383",
    marginTop: "5px",
  },
  box2: {
    marginTop: "20px",
    marginLeft: "20px",
  },
  headerText: {
    fontWeight: "500",
    fontSize: "16px",
    LineHeight: "24px",
    padding: "0px",
  },
  subHeading: {
    fontWeight: "400",
    fontSize: "14px",
    marginTop: 10,
    LineHeight: "21px",
    color: "#838383",
  },
  addIcon: {
    fontSize: "18px",
  },
  span: { textTransform: "capitalize", fontSize: 14 },
  loadingCircularProgress: {
    margin: "auto",
    display: "block",
    marginTop: 20,
    color: "#55A530",
  },
  countBoxTop: {
    width: "100%",
    // paddingLeft: "20px",
    [theme.breakpoints.down("md")]: {
      marginTop: 20,
    },
  },
  countBox: {
    padding: "7px 0px 8px 10px",
    backgroundColor: "#F5F5F5",
    borderRadius: "3px",
    fontFamily: "poppins",
    fontSize: "12px",
    lineHeight: "18px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10px",
  },
  countBoxTypo: {
    width: "100%",
    fontWeight: "500",
    fontFamily: "poppins",
    fontSize: "12px",
    lineHeight: "18px",
    height: "15px",
  },
  bottomBox: {
    marginTop: "2px",
    // marginBottom: 20,
    height: "calc(100vh - 300px)",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc",
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    [theme.breakpoints.down("md")]: {
      marginRight: 0,
    },
  },
  noCountBox: {
    fontFamily: "poppins",
    fontSize: "16px",
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  cannedMain: {
    width: "70%",
    marginTop: "20px",
    padding: "10px",
  },
  whatAreHeading: {
    fontFamily: "poppins",
    fontSize: "14px",
    padding: "5px 0px",
    borderBottom: "1px solid rgba(119, 119, 119,.5)",
  },
  whatArePara: {
    fontFamily: "poppins",
    fontSize: "12px",
    color: "rgba(119, 119, 119,1)",
    marginBottom: "30px",
  },
  addCannedResponseMain: {
    width: "80%",
  },
  addCannedResponseShortcutMain: {
    display: "grid",
    gridTemplateColumns: "65% auto",
    gridGap: "7px",
    marginBottom: "5px",
  },
  addCannedResponseShortcutInput: {
    fontFamily: "poppins",
    fontSize: "12px",
    color: "#777777",
    border: "1px solid rgba(119,119,119,.5)",
    borderRadius: "3px",
    paddingLeft: "5px",
  },
  addCannedResponseShortcutBtn: {
    border: "none",
    borderRadius: "3px",
    color: "white",
    background: "#55A530",
    fontFamily: "poppins",
    fontSize: "12px",
    padding: "6px 15px",
    cursor: "pointer",
  },
  addCannedResponseMainLong: {
    display: "grid",
    gridTemplateColumns: "65%",
  },
  addCannedResponseLongInput: {
    fontFamily: "poppins",
    fontSize: "12px",
    color: "#777777",
    border: "1px solid rgba(119,119,119,.5)",
    borderRadius: "3px",
    paddingLeft: "5px",
  },
  addCannedResponseLongPara: {
    marginTop: "10px",
    fontFamily: "poppins",
    fontSize: "11.5px",
    color: "#777777",
  },
  addCannedResponseListSearch: {
    display: "flex",

    paddingBottom: "20px",
    borderBottom: "1px solid rgba(119,119,119,.5)",
  },
  addCannedResponseListSearchInput: {
    fontFamily: "poppins",
    fontSize: "12px",
    color: "#777777",
    border: "1px solid rgba(119,119,119,.5)",
    borderRadius: "3px",
    paddingLeft: "5px",
    width: "200px",
    height: "29px",
  },
  addCannedResponseListItemMain: {
    padding: "15px 0px 10px 0",
    borderBottom: "1px solid rgba(119,119,119,.5)",
  },
  addCannedResponseListItemLongText: {
    color: "black",
    fontSize: "13.5px",
    fontFamily: "poppins",
    fontWeight: "500",
    marginBottom: "5px",
  },
  addCannedResponseListItemShortTextMain: {
    fontFamily: "poppins",
    fontSize: "12px",
    color: "#777777",
  },
  addCannedResponseListItemShortText: {
    marginLeft: "5px",
    border: "1px solid rgba(119,119,119,.5)",
    padding: "1px 6px",
    fontSize: "12px",
    color: "black",
  },
  addCannedResponseListItemBtnMain: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "10px",
    marginTop: "-25px",
  },
  addCannedResponseListItemBtnEdit: {
    border: "none",
    background: "none",
    fontFamily: "poppins",
    fontSize: "13px",
    color: "green",
  },
  addCannedResponseListItemBtnDelete: {
    border: "none",
    background: "none",
    fontFamily: "poppins",
    fontSize: "13px",
    color: "red",
  },
  focusRemove: {
    width: "160px",
    height: "30px",
    fontFamily: "poppins",
    fontSize: "13px",
    marginLeft: "10px",
    outline: "none",
    boxShadow: "none !important",
  },
  displayNone: {
    display: "none",
  },
}));

export default useStyles;
