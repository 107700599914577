import React from "react";
import Box from "@mui/material/Box/Box";
import BoxDesign from "src/components/pages/auth/shared/boxDesign";
import { Text } from "src/components/shared/atoms/text/Text";
import DynamicHead from "src/components/shared/atoms/helmet";
import Heading from "src/components/shared/atoms/heading/Heading";
import Footer from "src/components/pages/auth/shared/loginFooter";
import Form from "src/components/pages/auth/forget/form";
import useStyles from "./Styles";

const AuthForgetPassword: React.FC = () => {
  const { classes } = useStyles();

  return (
    <>
      <DynamicHead title="Forget Password" />
      <Box className={classes.root}>
        <Box className={classes.box}>
          <BoxDesign />
          <Heading heading="Forgot Password" />
          <Text text="No worries, we’ll send you reset instructions" />
          <Box className={classes.form}>
            <Form />
            <Footer />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AuthForgetPassword;
