/* eslint-disable prettier/prettier */
import React from "react";
import { Box, Typography, Button, Avatar } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { User } from "../../../facebook/upperComponent/types";

interface ConnectedFbProps {
  user: User;
  logout: () => void;
}

const ConnectedFb: React.FC<ConnectedFbProps> = ({ user, logout }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Avatar
        alt={user?.name || ""}
        src={user?.picture?.data?.url || ""}
        sx={{ width: 60, height: 60 }}
      />
      <Box mb={1} sx={{ maxWidth: "100%" }}>
        <Typography
          variant="h6"
          noWrap
          sx={{
            maxWidth: 350,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {user?.name || ""}
        </Typography>
      </Box>

      <Button variant="outlined" onClick={logout}>
        Disconnect
      </Button>
    </Box>
  );
};

export default ConnectedFb;
