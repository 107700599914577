import List from "@mui/material/List";
import Box from "@mui/material/Box";
import NoRecordFound from "src/components/shared/molecules/NoRecordFound";
import Response from "./response";

interface ListDataProp {
  id: number;
  short: string;
  long: string;
}

interface Props {
  data: any;
  loading: boolean;
  handleDeleteResponse: (id: number) => void;
  setRemoveModal: any;
}

const ListItems: React.FC<Props> = ({
  data,
  handleDeleteResponse,
  loading,
  setRemoveModal,
}) => {
  return (
    <List
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        height: "100%",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#ccc",
          borderRadius: "3px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent",
        },
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {data.length > 0 ? (
        data.map((curr: ListDataProp) => {
          return (
            <Response
              response={curr}
              loading={loading}
              handleDeleteResponse={handleDeleteResponse}
              setRemoveModal={setRemoveModal}
            />
          );
        })
      ) : (
        <Box mt={3}>
          <NoRecordFound />
        </Box>
      )}
    </List>
  );
};

export default ListItems;
