import { makeStyles } from "tss-react/mui";

const useStyle = makeStyles()((theme) => ({
  dynamicHeightLoggedIn: {
    height: "calc(100vh - 300px)",
    overflowY: "auto",
  },
  dynamicHeightLoggedOut: {
    height: "calc(100vh - 370px)",
    overflowY: "auto",
  },
  div: {
    width: "100%",
    height: "calc(100vh - 230px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      height: "calc(100vh - 260px)",
    },
    "&:hover": {
      backgroundColor: "#FFFFFF",
      color: "#000000",
    },
  },
  progress: {
    marginBottom: "10px",
  },
  cardContent: {
    padding: 0,
    margin: 0,
    paddingTop: 10,
  },
  root: {
    // height: "50vh",
    padding: 0,
    margin: 0,
    overflowY: "auto",
  },
}));

export default useStyle;
