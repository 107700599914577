/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { Avatar, Grid, Hidden, Link, Typography } from "@mui/material";
import TwilioGif from "src/assets/images/settings/twilio.gif";
import Twillio from "src/assets/images/settings/twilio.svg";
import { useMutation } from "@apollo/client";
import Button from "src/components/shared/atoms/button";
import ADD_TWILIO_CONFIG from "src/graphql/mutation/settings/addTwilioConfig";
import { useSnackbar } from "notistack";
import { Formik } from "formik";
import * as Yup from "yup";
import useActiveUserDetail from "src/utils/shared/useActiveUserDetail";
import useStyle from "../Styles";
import TwilioInputUI from "./TwilioInputUI";

interface Props {
  setTwilioConfiguration: any;
  setIsAuth: React.Dispatch<React.SetStateAction<boolean>>;
}
interface FormValues {
  sid: string;
  token: string;
}
const Configuration: React.FC<Props> = ({
  setIsAuth,
  setTwilioConfiguration,
}) => {
  const { classes } = useStyle();
  const { enqueueSnackbar } = useSnackbar();
  const { mainSuperAdminId } = useActiveUserDetail();

  const [
    addTwilioConfiguration,
    {
      loading: addTwilioConfigurationQueryLoading,
      data: addTwilioConfigurationQueryResult,
    },
  ] = useMutation(ADD_TWILIO_CONFIG);

  useEffect(() => {
    if (
      addTwilioConfigurationQueryResult &&
      addTwilioConfigurationQueryResult.addTwilioConfiguration
    ) {
      const { success, error, result } =
        addTwilioConfigurationQueryResult.addTwilioConfiguration;
      if (result) {
        setTwilioConfiguration(JSON.parse(result));
        // props.setTwilioConfig(result)
      }
      if (success) {
        setIsAuth(true);
      } else {
        enqueueSnackbar(JSON.parse(error), {
          variant: "error",
        });
      }
    }
  }, [addTwilioConfigurationQueryResult]);

  const saveConfiguration = async (
    values: FormValues,
    { setSubmitting }: any,
  ) => {
    if (values.sid && values.token) {
      try {
        await addTwilioConfiguration({
          variables: {
            accountSID: values.sid,
            authToken: values.token,
            mainSuperAdminId,
          },
        });
      } catch (error: any) {
        enqueueSnackbar("Something went wrong! Try again.", {
          variant: "error",
        });
      }
    }
    setSubmitting(false);
  };
  // validation schema using Yup
  const validationSchema = Yup.object().shape({
    sid: Yup.string().required("Account Sid is required"),
    token: Yup.string().required("Auth Token is required"),
  });

  return (
    <>
      <Box className={classes.headerBox}>
        <Avatar src={Twillio} className={classes.avtarStyle} />
        <Box>
          <Typography component="p" className={classes.headerHeading}>
            Add Twilio to Kuikwit
          </Typography>
          <Typography component="p" className={classes.headerSubHeading}>
            Provide Account SID and Auth token to connect your Twilio account
          </Typography>
        </Box>
      </Box>
      <Grid container xs={12}>
        <Grid item xs={12} md={5}>
          <Formik
            initialValues={{
              sid: "",
              token: "",
            }}
            validationSchema={validationSchema}
            onSubmit={saveConfiguration}
          >
            {(formik: any) => (
              <Box component="form" onSubmit={formik.handleSubmit}>
                <TwilioInputUI
                  label="sid"
                  onChange={formik.handleChange}
                  value={formik.values.sid}
                  helperText={formik.touched.sid && formik.errors.sid}
                />
                <TwilioInputUI
                  label="token"
                  onChange={formik.handleChange}
                  value={formik.values.token}
                  helperText={formik.touched.token && formik.errors.token}
                />
                <Button
                  variant="contained"
                  type="submit"
                  className={classes.buttonStyle}
                  circular={addTwilioConfigurationQueryLoading}
                >
                  Authorize App
                </Button>
                <Typography component="p" className={classes.text}>
                  Don't have Twilio account?
                  <Link
                    href="https://www.twilio.com/try-twilio"
                    target="_blank"
                  >
                    {" "}
                    Click here{" "}
                  </Link>
                  to create one.
                </Typography>
              </Box>
            )}
          </Formik>
        </Grid>
        <Hidden smDown>
          <Grid item md={7}>
            <Box mt={2}>
              <Typography component="p" className={classes.replySection}>
                Reply to SMS in Kuikwit with Twilio
              </Typography>
            </Box>
            <Box sx={{ textAlign: "end" }}>
              <img src={TwilioGif} width="100%" alt="" />
            </Box>
          </Grid>
        </Hidden>
      </Grid>
    </>
  );
};

export default Configuration;
