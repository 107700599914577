import { createTheme } from "@mui/material/styles";
// import { grey } from "@mui/material/colors";
// import pxToRem from "src/theme/functions/pxToRem";
import breakpoints from "src/theme/base/breakpoints";

declare module "@mui/material/styles" {
  // fix the type error when referencing the Theme object in your styled component
  interface Theme {
    custom?: {
      boxShadow?: string;
      background?: string;
      opacity?: number;
    };
  }
  // fix the type error when calling `createTheme()` with a custom theme option
  interface ThemeOptions {
    custom?: {
      boxShadow?: string;
      background?: string;
      opacity?: number;
    };
  }

  // Extend the Breakpoints interface with custom breakpoints
  interface Breakpoints {
    mxl: boolean;
  }
  // Extend the BreakpointsOptions interface with custom breakpoints
  interface BreakpointsOptions {
    mxl?: number;
  }
}
const theme = createTheme({
  breakpoints: { ...breakpoints },
  palette: {
    primary: {
      main: "#4D1277",
      light: "#E6D9EC",
    },
    secondary: {
      main: "#6BE900",
      dark: "#777777",
    },
    common: {
      black: "#272525",
      white: "#fff",
    },
    text: {
      primary: "#272525",
      secondary: "#838383",
    },
    success: {
      main: "#4D1277",
    },
    error: {
      main: "rgba(240, 52, 52, 1)",
    },
    info: {
      main: "#F5F5F5",
    },
  },
  typography: {
    fontFamily: "Poppins",
  },
  custom: {
    boxShadow:
      "43px 58px 29px rgba(0, 0, 0, 0.01), 24px 32px 24px rgba(0, 0, 0, 0.03), 11px 14px 18px rgba(0, 0, 0, 0.04), 3px 4px 10px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05)",
    opacity: 0.1,
  },
});

export default theme;
