/* eslint-disable prettier/prettier */
import { createContext, ReactNode, useState, useContext, useMemo } from "react";
import { IChatNote } from "src/components/pages/chat/ChatDetails/NotesBox/Types";

interface IChatNotesLoader {
  getChatNotesLoading: boolean;
  updateChatNotesLoading: boolean;
}

export interface IChatItem {
  id: string;
  messageId: string;
  source: string;
  messagetext: string;
  messagetimestamp: string;
  customerName: string;
  customerPicture: string;
  chatorder: null | string;
  labels: string;
  marknottoaddinchatcircle: string;
  read: number;
  salesDone: string;
  customerId: string;
  pageId: string;
  pageName: string;
  accesstoken: string;
}

interface ISavedData {
  id: string;
  short: string;
  long: string;
  count?: number | null;
}

interface IReplyMode {
  mode: "add" | "edit" | "delete" | "count";
  item: ISavedData | null;
  formMode?: "add" | "edit" | null;
}

// createContext
interface IContextData {
  showUserDetail: boolean;
  setShowUserDetail: React.Dispatch<React.SetStateAction<boolean>>;
  //
  userData: { name: string; userId: number } | null;
  setUserData: React.Dispatch<
    React.SetStateAction<{ name: string; userId: number } | null>
  >;
  // chatnotes
  chatNoteData: IChatNote[];
  setChatNoteData: React.Dispatch<React.SetStateAction<IChatNote[]>>;
  //
  renderingChatNoteData: IChatNote[];
  setRenderingChatNoteData: React.Dispatch<React.SetStateAction<IChatNote[]>>;
  //
  update: boolean;
  setUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  // searchbar
  showSearchBar: boolean;
  setShowSearchBar: React.Dispatch<React.SetStateAction<boolean>>;
  // tabMonitor
  tabMonitor: number;
  setTabMonitor: React.Dispatch<React.SetStateAction<number>>;
  // list search value
  listSearchValue: string;
  setListSearchValue: React.Dispatch<React.SetStateAction<string>>;
  // searchList Loader
  searchListLoader: boolean;
  setSearchListLoader: React.Dispatch<React.SetStateAction<boolean>>;
  // chatList Loader
  chatListLoader: boolean;
  setChatListLoader: React.Dispatch<React.SetStateAction<boolean>>;
  //
  replyMode: IReplyMode | null;
  setReplyMode: React.Dispatch<React.SetStateAction<IReplyMode | null>>;
  //
  responseList: ISavedData[] | undefined;
  setResponseList: React.Dispatch<
    React.SetStateAction<ISavedData[] | undefined>
  >;
  //
  fieldText: string;
  setFieldText: React.Dispatch<React.SetStateAction<string>>;
  //
  uploadedFile: File | null;
  setUploadedFile: React.Dispatch<React.SetStateAction<File | null>>;
  //
  showCustomerMessages: IChatItem | null;
  setShowCustomerMessages: React.Dispatch<
    React.SetStateAction<IChatItem | null>
  >;
  //
  chatNoteEditMode: IChatNote | null;
  setChatNoteEditMode: React.Dispatch<React.SetStateAction<IChatNote | null>>;
  //
  chatNoteLoading: IChatNotesLoader;
  setChatNoteLoading: React.Dispatch<React.SetStateAction<IChatNotesLoader>>;
  //
  emoji: string;
  setEmoji: React.Dispatch<React.SetStateAction<string>>;

  topTabValue: number;
  setTopTabValue: React.Dispatch<React.SetStateAction<number>>;

  agentId: string;
  setAgentId: React.Dispatch<React.SetStateAction<string>>;
  // phoneNumber: string;
  // setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  removeTransferChat: string | undefined | null;
  setRemoveTransferChat: React.Dispatch<
    React.SetStateAction<string | undefined | null>
  >;

  fbUnreadCount: number;
  setFbUnreadCount: React.Dispatch<React.SetStateAction<number>>;
}

const context = createContext<IContextData>({
  showUserDetail: false,
  setShowUserDetail: () => {},
  userData: null,
  setUserData: () => {},
  // chatnotes
  chatNoteData: [],
  setChatNoteData: () => {},
  //
  renderingChatNoteData: [],
  setRenderingChatNoteData: () => {},
  //
  update: false,
  setUpdate: () => {},
  // searchbar
  showSearchBar: false,
  setShowSearchBar: () => {},
  // tabmonitor
  tabMonitor: 0,
  setTabMonitor: () => {},
  // list search value
  listSearchValue: "",
  setListSearchValue: () => {},
  // searchList Loader
  searchListLoader: false,
  setSearchListLoader: () => {},
  // chatList Loader
  chatListLoader: false,
  setChatListLoader: () => {},
  //
  replyMode: null,
  setReplyMode: () => {},
  //
  responseList: undefined,
  setResponseList: () => {},
  //
  fieldText: "",
  setFieldText: () => {},
  //
  uploadedFile: null,
  setUploadedFile: () => {},
  //
  showCustomerMessages: null,
  setShowCustomerMessages: () => {},
  //
  chatNoteEditMode: null,
  setChatNoteEditMode: () => {},
  //
  chatNoteLoading: {
    getChatNotesLoading: false,
    updateChatNotesLoading: false,
  },
  setChatNoteLoading: () => {},
  //
  emoji: "",
  setEmoji: () => {},

  topTabValue: 0,
  setTopTabValue: () => {},

  fbUnreadCount: 0,
  setFbUnreadCount: () => {},

  agentId: "",
  setAgentId: () => {},

  // phoneNumber: "",
  // setPhoneNumber: () => { },

  removeTransferChat: null,
  setRemoveTransferChat: () => {},
});

// contextProvider
interface IProvider {
  children: ReactNode;
}

const ContextProvider = ({ children }: IProvider) => {
  const [showUserDetail, setShowUserDetail] = useState(false);

  const [userData, setUserData] = useState<{
    name: string;
    userId: number;
  } | null>(null);

  // for chatnotes
  const [chatNoteData, setChatNoteData] = useState<IChatNote[]>([]);
  const [renderingChatNoteData, setRenderingChatNoteData] = useState<
    IChatNote[]
  >([]);

  const [update, setUpdate] = useState<boolean>(false);

  const [showSearchBar, setShowSearchBar] = useState<boolean>(false);
  //
  const [tabMonitor, setTabMonitor] = useState<number>(0);
  const [listSearchValue, setListSearchValue] = useState<string>("");
  // searchloader
  const [chatListLoader, setChatListLoader] = useState<boolean>(false);
  const [searchListLoader, setSearchListLoader] = useState<boolean>(false);
  //
  const [replyMode, setReplyMode] = useState<IReplyMode | null>(null);
  const [responseList, setResponseList] = useState<ISavedData[] | undefined>(
    [],
  );
  const [fieldText, setFieldText] = useState<string>("");
  //
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  //
  const [showCustomerMessages, setShowCustomerMessages] =
    useState<IChatItem | null>(null);
  //
  const [chatNoteEditMode, setChatNoteEditMode] = useState<IChatNote | null>(
    null,
  );
  //
  const [chatNoteLoading, setChatNoteLoading] = useState<IChatNotesLoader>({
    getChatNotesLoading: false,
    updateChatNotesLoading: false,
  });
  //
  const [emoji, setEmoji] = useState<string>("");

  // tab value for top most header all messags , fb ,wa
  const [topTabValue, setTopTabValue] = useState(0);

  const [fbUnreadCount, setFbUnreadCount] = useState(0);

  const [agentId, setAgentId] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState("");
  const [removeTransferChat, setRemoveTransferChat] = useState<
    string | undefined | null
  >(null);

  const contextValue = useMemo(
    () => ({
      showUserDetail,
      setShowUserDetail,
      userData,
      setUserData,
      //
      chatNoteData,
      setChatNoteData,
      //
      renderingChatNoteData,
      setRenderingChatNoteData,
      //
      update,
      setUpdate,
      //
      showSearchBar,
      setShowSearchBar,
      //
      tabMonitor,
      setTabMonitor,
      // list search value
      listSearchValue,
      setListSearchValue,
      // searchListLoader
      searchListLoader,
      setSearchListLoader,
      // chatListLoader
      chatListLoader,
      setChatListLoader,
      //
      replyMode,
      setReplyMode,
      //
      responseList,
      setResponseList,
      //
      fieldText,
      setFieldText,
      //
      uploadedFile,
      setUploadedFile,
      //
      showCustomerMessages,
      setShowCustomerMessages,
      //
      chatNoteEditMode,
      setChatNoteEditMode,
      //
      chatNoteLoading,
      setChatNoteLoading,
      //
      emoji,
      setEmoji,
      topTabValue,
      setTopTabValue,
      agentId,
      setAgentId,
      // phoneNumber,
      // setPhoneNumber,
      removeTransferChat,
      setRemoveTransferChat,
      //
      fbUnreadCount,
      setFbUnreadCount,
    }),
    [
      showUserDetail,
      userData,
      chatNoteData,
      renderingChatNoteData,
      update,
      showSearchBar,
      tabMonitor,
      listSearchValue,
      searchListLoader,
      chatListLoader,
      replyMode,
      responseList,
      fieldText,
      uploadedFile,
      //
      showCustomerMessages,
      chatNoteEditMode,
      //
      chatNoteLoading,
      emoji,
      topTabValue,
      agentId,
      // phoneNumber,
      removeTransferChat,
      fbUnreadCount,
    ],
  );

  return <context.Provider value={contextValue}>{children}</context.Provider>;
};

// useContext
const ChatContext = () => {
  return useContext(context);
};

export { ContextProvider, ChatContext };
