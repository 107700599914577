/* eslint-disable prettier/prettier */
import * as React from "react";
import { OutlinedInput, Box, Typography, Chip } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import CardContent from "@mui/material/CardContent";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import Button from "src/components/shared/atoms/button";
import UPDATE_LABEL_MUTATION from "src/graphql/mutation/settings/updateUserLabel";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import useStyles from "./Styles";

interface IlabelData {
  agentId: string;
  author: string;
  createdAt: string; // Use string if the timestamp is stored as a string, or use number if you want it to be a number.
  id: string;
  label: string;
  mainSuperAdminId: string;
  type: "personal" | "admin"; // Use a union type to restrict to specific string values.
}

interface UpdateLabelProps {
  labelData: IlabelData; // Ensure that the prop type matches the expected data structure
}

const UpdateLabel = ({ labelData }: UpdateLabelProps) => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [initialFormikValues] = React.useState({
    text: (labelData && labelData.label) || "",
    // type: "personal",
  });

  const [
    updateLabel,
    {
      data: updateLabelMutationResult,
      loading: updateLabelMutationLoading,
      error: updateLabelMutationError,
    },
  ] = useMutation(UPDATE_LABEL_MUTATION);

  React.useEffect(() => {
    console.log("error in uploading the data", updateLabelMutationError);
    if (updateLabelMutationError) {
      enqueueSnackbar("Something went wrong.", {
        variant: "error",
      });
    }
  }, [updateLabelMutationError]);

  React.useEffect(() => {
    if (
      updateLabelMutationResult &&
      updateLabelMutationResult.updateUserLabel
    ) {
      navigate("/settings/label");
    }
  }, [updateLabelMutationResult]);

  const validationSchema = Yup.object().shape({
    text: Yup.string().required("text is required").min(6),
  });

  interface FormValuesProps {
    text: string;
  }

  const updatedHandleSubmit = async (
    values: FormValuesProps,
    formikHelpers: any,
  ) => {
    formikHelpers.setSubmitting(false);
    try {
      updateLabel({
        variables: {
          labelId: labelData.id,
          label: values.text,
        },
      });
    } catch (error) {
      enqueueSnackbar("Something went wrong.", {
        variant: "error",
      });
    }
  };
  const formik = useFormik({
    initialValues: initialFormikValues,
    validationSchema,
    onSubmit: updatedHandleSubmit,
  });

  return (
    <Box>
      <Chip
        icon={<EditIcon />}
        label="Edit Label"
        variant="filled"
        color="primary"
        style={{
          padding: 10,
          fontSize: "16px",
          fontWeight: "400",
          marginTop: 25,
        }}
      />
      <CardContent className={classes.cardContent}>
        <Box component="form" onSubmit={formik.handleSubmit}>
          <Typography variant="subtitle2" mt={1}>
            Label
          </Typography>

          <OutlinedInput
            className={classes.fieldStyle}
            name="text"
            value={formik.values.text}
            onChange={formik.handleChange}
            onError={formik.touched.text && Boolean(formik.errors.text)}
          />
          <Box className={classes.formikText}>
            {formik.touched.text && formik.errors.text && (
              <FormHelperText error>{formik.errors.text}</FormHelperText>
            )}
          </Box>
          <Button
            size="large"
            variant="contained"
            color="primary"
            type="submit"
            className={classes.savebutton}
            circular={updateLabelMutationLoading}
            disabled={formik.values.text === labelData.label}
          >
            Save
          </Button>
        </Box>
      </CardContent>
    </Box>
  );
};

export default UpdateLabel;
