import { gql } from "@apollo/client";

const DISCONNECT_TWILIO = gql`
  mutation disconnectTwilioConfiguration(
    $accountSID: String!
    $authToken: String!
  ) {
    disconnectTwilioConfiguration(
      accountSID: $accountSID
      authToken: $authToken
    ) {
      success
      error
    }
  }
`;

export default DISCONNECT_TWILIO;
