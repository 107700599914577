import React from "react";
import { Box, Typography, Button } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useTheme } from "@mui/material/styles";

interface NotConnectedBoxProps {
  openFacebookBuisnessLogin: () => void;
}

const NotConnectedBox: React.FC<NotConnectedBoxProps> = ({
  openFacebookBuisnessLogin,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.background.paper,
        textAlign: "center",
        gap: theme.spacing(2),
      }}
    >
      <FacebookIcon sx={{ fontSize: 60, color: "#1877F2" }} />
      <Typography variant="h6">Connect your Facebook page</Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={openFacebookBuisnessLogin}
        sx={{
          backgroundColor: "#1877F2",
          "&:hover": {
            backgroundColor: "#145dbf",
          },
        }}
      >
        Connect Facebook Pages
      </Button>
    </Box>
  );
};

export default NotConnectedBox;
