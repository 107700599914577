/* eslint-disable prettier/prettier */
import { Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import GeneralLabels from "./generalLabels";
import UpdateLabel from "./list/updateLabel";
import useStyles from "./Styles";

const Labels = () => {
  const { classes } = useStyles();

  const [searchParams] = useSearchParams();
  const mode = searchParams.get("mode");
  const location = useLocation();
  const { labelData } = location?.state ?? {};
  console.log("labeldata is shown here", mode, labelData);

  const navigate = useNavigate();

  const handleCancel = () => {
    navigate("/settings/label");
  };
  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
      }}
    >
      <Box className={classes.box} onClick={handleCancel}>
        {mode === "edit" && <ArrowBackIcon />} Labels | Tags
      </Box>
      {/* <GeneralLabels /> */}
      {mode === "edit" ? (
        <UpdateLabel labelData={labelData} />
      ) : (
        <GeneralLabels />
      )}
    </Box>
  );
};

export default Labels;
