/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-empty-pattern */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */

import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import ReactTimeAgo from "react-timeago";
import { Box, IconButton, MenuItem, Typography } from "@mui/material";
import Fade from "@mui/material/Fade";
import DELETENOTIFICATION from "src/graphql/mutation/chat/deleteNotification";
import ADDORUPDATENOTIFICATION from "src/graphql/mutation/chat/addOrUpdateNotification";
import { useDispatch } from "react-redux";
import { notificationHandler } from "src/store/reducers/notificaitonSlice";
import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import useStyles from "./Styles";
import ListSkeleton from "./ListSkeleton";

interface Props {
  notificationData: any[];
  loading: boolean;
  name: string;
  setNotificationData: (a: any) => void;
  setUnreadNotificationData?: (a: any) => void;
}
const NotificationsList: React.FC<Props> = ({
  name,
  notificationData,
  loading,
  setNotificationData,
  setUnreadNotificationData,
}) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedNotificationId, setSelectedNotificationId] =
    useState<number>();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    event.stopPropagation();
  };
  const open = Boolean(anchorEl);
  const [addOrUpdateNotification, { data: addOrUpdateNotificationResult }] =
    useMutation(ADDORUPDATENOTIFICATION);

  const [deleteNotification, { data: deleteNotificationResult }] =
    useMutation(DELETENOTIFICATION);

  useEffect(() => {
    if (
      addOrUpdateNotificationResult &&
      addOrUpdateNotificationResult.addOrUpdateNotification
    ) {
      if (addOrUpdateNotificationResult.addOrUpdateNotification.success) {
        setAnchorEl(null);
        const dat: any = notificationData.filter(
          (item: { id: number | undefined }) =>
            item.id !== selectedNotificationId,
        );
        setNotificationData(dat);
      } else {
        enqueueSnackbar(
          addOrUpdateNotificationResult.addOrUpdateNotification.error,
          {
            variant: "error",
          },
        );
      }
    }
  }, [addOrUpdateNotificationResult]);

  useEffect(() => {
    if (
      deleteNotificationResult &&
      deleteNotificationResult.deleteNotification
    ) {
      if (deleteNotificationResult.deleteNotification.success) {
        setAnchorEl(null);
        const dat: any = notificationData.filter(
          (item: { id: number | undefined }) =>
            item.id !== selectedNotificationId,
        );
        setNotificationData(dat);
        if (setUnreadNotificationData)
          setUnreadNotificationData((prev: any[]) =>
            prev.filter(
              (item: { id: number | undefined }) =>
                item.id !== selectedNotificationId,
            ),
          );
      } else {
        enqueueSnackbar(deleteNotificationResult.deleteNotification.error, {
          variant: "error",
        });
      }
    }
  }, [deleteNotificationResult]);

  const handleDeleteNotification = (
    event: React.MouseEvent<HTMLElement>,
    id: number,
  ) => {
    setAnchorEl(null);
    setSelectedNotificationId(id);
    event.stopPropagation();
    deleteNotification({
      variables: {
        id,
      },
    });
  };
  const readNotification = (inboxId: string) => {
    addOrUpdateNotification({
      variables: {
        inboxId,
        read: true,
        update: true,
      },
    });
  };
  const handleReadNotification = (
    event: React.MouseEvent<HTMLElement>,
    inboxId: string,
    id: number,
  ) => {
    setAnchorEl(null);
    setSelectedNotificationId(id);
    event.stopPropagation();
    readNotification(inboxId);
  };

  const handleNotificationClick = (
    event: React.MouseEvent<HTMLElement>,
    id: number,
  ) => {
    const data = notificationData.filter((item) => item.id === id);
    const dataObj = data[0];
    setSelectedNotificationId(id);
    readNotification(dataObj.inbox_id);
    dispatch(notificationHandler(dataObj));
  };

  if (loading && notificationData.length === 0) return <ListSkeleton />;

  if (!loading && notificationData.length === 0)
    return (
      <Box className={classes.noRecordBox}>
        <Typography className={classes.noRecordText}>
          No record found
        </Typography>
      </Box>
    );
  return (
    <List
      sx={{
        width: "100%",
        maxWidth: 300,
        bgcolor: "background.paper",
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      {notificationData.map((item, index) => {
        return (
          <ListItem
            key={index}
            onClick={(event) => handleNotificationClick(event, item.id)}
            alignItems="flex-start"
            sx={{
              margin: 0,
              // padding: 0,
              paddingLeft: "5px !important",
              paddingRight: "5px !important",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              "&: hover": {
                backgroundColor: "#f5f5f5",
              },
              border: "1px solid #E6D9EC",
              borderRadius: "3px",
            }}
          >
            <ListItemAvatar>
              <Avatar
                sx={{ width: 56, height: 56 }}
                alt={item.customerName}
                src={item.customerPicture}
              />
            </ListItemAvatar>
            <ListItemText
              sx={{
                marginLeft: 1,
              }}
              primary={item.customerName}
              secondary={
                <ReactTimeAgo
                  className={classes.time}
                  date={moment
                    .unix(new Date(item.dateandtime).getTime() / 1000)
                    .toDate()}
                />
              }
            />
            <IconButton
              id="notification_listitem"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <MoreVertIcon sx={{ alignSelf: "flex-start" }} />
              <Menu
                id="notification_listitem"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                aria-controls={open ? "fade-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                {name === "unread" && (
                  <MenuItem
                    disableTouchRipple
                    sx={{ "&:hover": { backgroundColor: "white" } }}
                    onClick={(event) =>
                      handleReadNotification(event, item.inbox_id, item.id)
                    }
                  >
                    Read
                  </MenuItem>
                )}
                {name === "all" && (
                  <MenuItem
                    sx={{ "&:hover": { backgroundColor: "white" } }}
                    disableTouchRipple
                    onClick={(event) =>
                      handleDeleteNotification(event, item.id)
                    }
                  >
                    Delete
                  </MenuItem>
                )}
              </Menu>
            </IconButton>
          </ListItem>
        );
      })}
    </List>
  );
};
export default NotificationsList;
