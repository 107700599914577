/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable react/require-default-props */
import React from "react";
import Badge from "@mui/material/Badge";
import Avatar from "src/components/shared/atoms/avatar";

interface IProps {
  picture: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  login: boolean;
  className?: any;
  onLoad?: any;
}

const BadgeWAvatar: React.FC<IProps> = ({
  picture,
  onClick,
  login,
  className,
  onLoad,
}) => {
  return (
    <Badge
      color={login ? "secondary" : "error"}
      badgeContent=" "
      variant="dot"
      overlap="circular"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <Avatar
        // onLoad={onLoad}
        className={className}
        src={picture}
        onClick={onClick}
      />
    </Badge>
  );
};

export default BadgeWAvatar;
