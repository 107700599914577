import { gql } from "@apollo/client";

const NOTIFICATION_FOUND = gql`
  subscription notificationFound {
    notificationFound {
      success
      result
      error
    }
  }
`;

export default NOTIFICATION_FOUND;
