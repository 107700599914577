import { gql } from "@apollo/client";

const FORGET_PASSWORD = gql`
  mutation requestresettoken($email: String!) {
    requestresettoken(email: $email) {
      success
      error
    }
  }
`;
export default FORGET_PASSWORD;
