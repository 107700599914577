/* eslint-disable prettier/prettier */
import { Box, Grid, Typography } from "@mui/material";
import { clsx } from "clsx";
import useStyles from "../Styles";

interface CountProps {
  count: number;
}
const Count: React.FC<CountProps> = ({ count }) => {
  const { classes } = useStyles();

  return (
    <>
      <Box className={classes.countBox}>
        <Typography
          className={classes.countBoxTypo}
          variant="button"
          display="block"
          gutterBottom
        >
          {count} canned responses
        </Typography>
      </Box>
      <Box className={classes.root}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs="auto" container alignItems="center">
            <Box className={clsx(classes.square, classes.backgroundPersonal)} />
            <Typography className={classes.title}>
              My Canned Responses
            </Typography>
          </Grid>
          <Grid item xs="auto" container alignItems="center">
            <Box className={clsx(classes.square, classes.backgroundGeneric)} />
            <Typography className={classes.title}>
              Generic Canned Responses
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Count;
