/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import { Formik } from "formik";
import { useMutation } from "@apollo/client";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import TextField from "src/components/shared/atoms/textField/TextField";
import REGISTER_MUTATION from "src/graphql/mutation/auth/signup";
import Box from "src/components/shared/atoms/box";
import AuthButton from "src/components/pages/auth/shared/button/AuthButton";
import { enqueueSnackbar } from "notistack";

import useStyles from "./Styles";
import { FormValues, SignUpErrorsProps } from "./Types";

const Form = () => {
  const { classes } = useStyles();
  const [signUpErrors, setSignUpErrors] = useState<SignUpErrorsProps | null>(
    null,
  );
  const [email, setEmail] = useState<string>("");
  const navigate = useNavigate();

  const [
    register,
    {
      loading: registerMutationLoading,
      data: registerMutationResult,
      // error: registerMutationError,
    },
  ] = useMutation(REGISTER_MUTATION);

  useEffect(() => {
    if (registerMutationResult && registerMutationResult.register) {
      if (!registerMutationResult.register.success) {
        const backendErrors = JSON.parse(registerMutationResult.register.error);
        setSignUpErrors(backendErrors);
      } else {
        setSignUpErrors(null);
        enqueueSnackbar(registerMutationResult.register.error, {
          variant: "success",
        });
        navigate("/confirm", { state: { email } });
      }
    }
  }, [registerMutationResult]);

  // validation schema using Yup
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required("Please confirm your password"),
    check: Yup.boolean().oneOf(
      [true],
      "You must accept the terms and conditions.",
    ),
  });

  const handleSubmit = async (values: FormValues, { setSubmitting }: any) => {
    // handle form submission
    try {
      setEmail(values.email);
      await register({
        variables: {
          username: values.name,
          password: values.password,
          confirmedPassword: values.confirmPassword,
          email: values.email,
        },
      });
      setSubmitting(false);
    } catch (err: any) {
      enqueueSnackbar("Something went wrong. Try again.", { variant: "error" });
    }
    setSubmitting(false);
    // settng email
    // e.preventDefault()
  };

  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        check: true,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formik: any) => (
        <Box component="form" onSubmit={formik.handleSubmit}>
          <Box className={classes.marginTop}>
            <TextField
              id="name"
              label="Name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              helperText={
                (signUpErrors && signUpErrors.name) ||
                (formik.touched.name && formik.errors.name)
              }
            />
          </Box>

          <Box className={classes.marginTop}>
            <TextField
              id="email"
              label="Email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              helperText={
                (signUpErrors && signUpErrors.email) ||
                (formik.touched.email && formik.errors.email)
              }
            />
          </Box>
          <Box className={classes.marginTop}>
            <TextField
              id="password"
              label="Password"
              name="password"
              typeField="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              helperText={
                (signUpErrors && signUpErrors.password) ||
                (formik.touched.password && formik.errors.password)
              }
            />
          </Box>
          <Box className={classes.marginTop}>
            <TextField
              id="confirmPassword"
              label="Confirm Password"
              name="confirmPassword"
              typeField="password"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              helperText={
                (signUpErrors && signUpErrors.confirmPassword) ||
                (formik.touched.confirmPassword &&
                  formik.errors.confirmPassword)
              }
            />
          </Box>
          <Box className={classes.terms}>
            <FormControlLabel
              style={{ marginRight: "0px" }}
              control={
                <Checkbox
                  id="check"
                  name="check"
                  onChange={formik.handleChange}
                  checked={formik.values.check}
                />
              }
              label={
                <Typography className={classes.label}>
                  I agree to Kuikwit’s Terms & Conditions and Privacy Policy
                </Typography>
              }
              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
            />
            <FormHelperText sx={{ color: "red" }}>
              {formik.errors.check}
            </FormHelperText>
          </Box>

          <AuthButton text="Sign Up" loading={registerMutationLoading} />
        </Box>
      )}
    </Formik>
  );
};

export default Form;
