import { gql } from "@apollo/client";

const DELETENOTIFICATION = gql`
  mutation deleteNotification($id: ID!) {
    deleteNotification(id: $id) {
      success
      error
      result
    }
  }
`;

export default DELETENOTIFICATION;
