import { gql } from "@apollo/client";

const UPDATE_LABEL_MUTATION = gql`
  mutation updateUserLabel($labelId: ID!, $label: String!) {
    updateUserLabel(labelId: $labelId, label: $label) {
      success
      error
      result
    }
  }
`;

export default UPDATE_LABEL_MUTATION;
