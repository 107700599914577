/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { useSnackbar } from "notistack";
import DescriptionIcon from "@mui/icons-material/Description";
import ForumIcon from "@mui/icons-material/Forum";
import HomeIcon from "@mui/icons-material/Home";
import { useState, useEffect } from "react";
import useNetwork from "src/hooks/useNetwork";
import { useLocation, useNavigate } from "react-router-dom";
import ProfileBottom from "src/components/layout/mainLayout/navigationList/profile";
import SettingsIcon from "@mui/icons-material/Settings";
import useStyles from "./Styles";

const MainLayout = () => {
  const isOnline = useNetwork();
  const navigate = useNavigate();
  const location = useLocation();
  const locationValue = location.pathname;
  const [prevIsOnline, setPrevIsOnline] = useState(false);
  const [value, setValue] = useState(locationValue);
  const { enqueueSnackbar } = useSnackbar();
  const { classes } = useStyles();

  useEffect((): any => {
    if (!isOnline) {
      enqueueSnackbar("Internet connection lost. Please check your network.", {
        variant: "error",
      });
    } else if (isOnline && prevIsOnline) {
      enqueueSnackbar("Internet connection recovered.", {
        variant: "success",
      });
    }
  }, [isOnline, prevIsOnline, enqueueSnackbar]);

  useEffect(() => {
    if (!isOnline) setPrevIsOnline(true);
  }, [isOnline]);
  return (
    <Paper className={classes.paper} elevation={3}>
      <BottomNavigation
        className={classes.bottomNav}
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          navigate(`${newValue}`);
        }}
      >
        <BottomNavigationAction
          className={classes.bottomNavDif}
          value="/"
          label="Dashboard"
          icon={<HomeIcon />}
        />
        <BottomNavigationAction
          className={classes.hide}
          value="/chats"
          label="Chats"
          icon={<ForumIcon />}
        />
        <BottomNavigationAction
          value="/reports"
          label="Reports"
          icon={<DescriptionIcon />}
        />
        <BottomNavigationAction
          className={classes.hide}
          value="/settings"
          label="Settings"
          icon={<SettingsIcon />}
        />
        <ProfileBottom />
      </BottomNavigation>
    </Paper>
  );
};

export default MainLayout;
