interface ILoggedInUser {
  id: string;
  designation: {
    paneltype: string;
  };
  mainSuperAdminId: string;
  accessToken: string;
  name: string;
  picture: string | null;
  number: string;
  pseudonym: string;
  username: string;
}

const setAuthDataInLocalStorage = (user: ILoggedInUser): boolean => {
  if (!user) return false;
  try {
    const {
      id,
      designation,
      mainSuperAdminId,
      name,
      accessToken,
      picture,
      number,
      pseudonym,
      username,
    } = user;
    const paneltype = designation?.paneltype;
    localStorage.setItem("userName", name);
    const ActiveUserData = {
      id,
      paneltype,
      mainSuperAdminId,
      picture,
      number,
      pseudonym,
      username,
    };
    localStorage.setItem("ActiveUserdetail", JSON.stringify(ActiveUserData));
    localStorage.setItem("accessToken", accessToken);
    return true;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("Error while setting data in localStorage:", err);
    return false;
  }
};

export default setAuthDataInLocalStorage;
