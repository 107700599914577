/* eslint-disable prettier/prettier */
import * as React from "react";
import {
  OutlinedInput,
  Box,
  Typography,
  TextareaAutosize,
  Chip,
  IconButton,
} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import CardContent from "@mui/material/CardContent";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MenuItem from "@mui/material/MenuItem";
import useActiveUserDetail from "src/utils/shared/useActiveUserDetail";
import Select from "@mui/material/Select";
import { useLocation, useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";
import Button from "src/components/shared/atoms/button";
import useStyles from "./Styles";

const UpdateResponses = ({
  mode,
  handle,
  loading,
}: {
  id?: string | null;
  mode: string | null;
  handle: (a: any) => void;
  loading: boolean;
}) => {
  const { classes } = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { panelType } = useActiveUserDetail();
  const { enqueueSnackbar } = useSnackbar();

  // eslint-disable-next-line no-unsafe-optional-chaining
  const { response } = location?.state ?? {};
  const id = response?.id || null;
  const SUPERADMIN = "SUPERADMIN";

  const [initialFormikValues] = React.useState({
    text: response?.long || "",
    shortcut: response?.short || "",
    type: "personal",
  });

  const validationSchema = Yup.object().shape({
    text: Yup.string().required("text is required").min(6),
    shortcut: Yup.string()
      .required("shortcut is required")
      .max(60, "text limit exceeded!"),
  });

  interface FormValuesProps {
    text: string;
    shortcut: string;
    type: string;
  }

  const updatedHandleSubmit = async (
    values: FormValuesProps,
    formikHelpers: any,
  ) => {
    formikHelpers.setSubmitting(false);
    try {
      const data = {
        id,
        type: values.type,
        long: values.text,
        short: values.shortcut,
      };
      handle(data);
    } catch (error) {
      enqueueSnackbar("Something went wrong.", {
        variant: "error",
      });
    }
  };
  const formik = useFormik({
    initialValues: initialFormikValues,
    validationSchema,
    onSubmit: updatedHandleSubmit,
  });

  const handleCancel = () => {
    navigate("/settings/canned-response");
  };

  return (
    <Box>
      <Box className={classes.box}>
        <IconButton
          onClick={handleCancel}
          color="primary"
          className={classes.backButton}
        >
          <ArrowBackIcon />
        </IconButton>
        Canned | Responses
      </Box>

      <Chip
        icon={mode === "edit" ? <EditIcon /> : <AddIcon />}
        label={mode === "edit" ? "Edit Response" : "Add Response"}
        variant="filled"
        color="primary"
        style={{
          padding: 10,
          fontSize: "16px",
          fontWeight: "400",
          marginTop: 25,
        }}
      />
      <CardContent className={classes.cardContent}>
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          // className={classes.boxForm}
        >
          {panelType === SUPERADMIN && mode === "add" && (
            <>
              <Typography variant="body1" mt={2}>
                Type
              </Typography>
              <Select
                name="type"
                value={formik.values.type}
                className={classes.fieldStyle}
                onChange={formik.handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {selected === "personal" && (
                      <>
                        <PersonIcon color="primary" sx={{ mr: 3 }} />
                        <Typography variant="body2">My Response</Typography>
                      </>
                    )}
                    {selected === "generic" && (
                      <>
                        <GroupsIcon color="primary" sx={{ mr: 3 }} />
                        <Typography variant="body2">
                          Generic Response
                        </Typography>
                      </>
                    )}
                  </Box>
                )}
              >
                <MenuItem value="personal">
                  <PersonIcon color="primary" sx={{ mr: 3 }} />
                  <Typography variant="body2">My Response</Typography>
                </MenuItem>
                <MenuItem value="generic">
                  <GroupsIcon color="primary" sx={{ mr: 3 }} />
                  <Typography variant="body2">Generic Response</Typography>
                </MenuItem>
              </Select>
            </>
          )}
          <Typography variant="subtitle2" mt={2}>
            Response text
          </Typography>

          <TextareaAutosize
            className={classes.textArea}
            name="text"
            value={formik.values.text}
            onChange={formik.handleChange}
            onError={formik.touched.text && Boolean(formik.errors.text)}
            minRows={5}
            maxRows={5}
            // placeholder="write here"
          />
          <Box className={classes.formikText}>
            {formik.touched.text && formik.errors.text && (
              <FormHelperText error>{formik.errors.text}</FormHelperText>
            )}
          </Box>

          <Typography variant="subtitle2" mt={1}>
            Shortcut
          </Typography>

          <OutlinedInput
            className={classes.fieldStyle}
            name="shortcut"
            value={formik.values.shortcut}
            onChange={formik.handleChange}
            onError={formik.touched.shortcut && Boolean(formik.errors.shortcut)}
            // placeholder="Add shortcut"
          />
          <Box className={classes.formikText}>
            {formik.touched.shortcut && formik.errors.shortcut && (
              <FormHelperText error>{formik.errors.shortcut}</FormHelperText>
            )}
          </Box>
          <Button
            size="large"
            variant="contained"
            color="primary"
            type="submit"
            className={classes.savebutton}
            circular={loading}
          >
            Save
          </Button>
        </Box>
      </CardContent>
    </Box>
  );
};

export default UpdateResponses;
