import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "@mui/material/styles";
import { Routes, Route, Navigate } from "react-router-dom";
import theme from "src/theme";
import LoaderSpinner from "src/components/shared/atoms/SpinnerLoader";
import Login from "src/pages/auth/login/Login";
import Signup from "src/pages/auth/signup/Signup";
import AuthForgetPassword from "src/pages/auth/ForgetPassword";
import Facebook from "src/components/pages/settings/rightPanel/channels/facebook";
import Whatsapp from "src/components/pages/settings/rightPanel/channels/whatsapp";
import CannedResponses from "src/components/pages/settings/rightPanel/canned";
import NotSelected from "src/components/pages/settings/rightPanel/notSelected";
import Twilio from "src/components/pages/settings/rightPanel/channels/twilio";
import Instagram from "src/components/pages/settings/rightPanel/channels/instagram";
import Label from "src/components/pages/settings/rightPanel/labels";
import ProfileSettings from "src/components/pages/settings/rightPanel/profileSetting";
import { store } from "./store/store";
import apolloConfig from "./config/apolloupdate";
import Layout from "./layout/Layout";
import AuthGuard from "./components/hoc/AuthGuard";
import GuestGuard from "./components/hoc/GuestGuard";
import Privacy from "./pages/auth/privacy";
import "./App.css";

const AuthEmailConfirm = React.lazy(
  () => import("src/pages/auth/EmailConfirm"),
);
const InviteUser = React.lazy(() => import("src/pages/auth/InviteUser"));
const ResetPassword = React.lazy(() => import("src/pages/auth/resetPassword"));

const Dashboard = React.lazy(() => import("src/pages/dashboard"));
const Users = React.lazy(() => import("src/pages/users"));
const Reports = React.lazy(() => import("src/pages/reports"));
const Chat = React.lazy(() => import("src/pages/chat"));
const Setting = React.lazy(() => import("src/pages/settings"));

const App = () => {
  const { apolloClient, wsLink } = apolloConfig() as any;

  return (
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Suspense fallback={<LoaderSpinner />}>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route element={<GuestGuard />}>
                  <Route path="login" element={<Login />} />
                  <Route path="signup" element={<Signup />} />
                  <Route
                    path="forgetpassword"
                    element={<AuthForgetPassword />}
                  />
                  <Route path="confirm" element={<AuthEmailConfirm />} />
                  <Route path="/join" element={<InviteUser />} />
                  <Route
                    path="/resetpassword/:token/:email"
                    element={<ResetPassword />}
                  />
                </Route>
                <Route path="privacy-policy" element={<Privacy />} />
                <Route element={<AuthGuard wsLink={wsLink} />}>
                  <Route path="" element={<Dashboard />} />
                  <Route path="users" element={<Users />} />
                  <Route path="chats" element={<Chat />} />
                  <Route path="reports" element={<Reports />} />
                  <Route path="/settings" element={<Setting />}>
                    <Route path="" element={<NotSelected />} />
                    <Route path="facebook" element={<Facebook />} />
                    <Route path="instagram" element={<Instagram />} />
                    <Route path="whatsapp" element={<Whatsapp />} />
                    <Route path="twilio" element={<Twilio />} />
                    <Route path="label" element={<Label />} />
                    <Route path="*" element={<Navigate to="/settings" />} />
                    <Route
                      path="canned-response"
                      element={<CannedResponses />}
                    />
                    <Route path="profile" element={<ProfileSettings />} />
                  </Route>
                </Route>
                <Route path="*" element={<Navigate to="/" />} />
              </Route>
            </Routes>
          </Suspense>
        </ThemeProvider>
      </Provider>
    </ApolloProvider>
  );
};

export default App;
