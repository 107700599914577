import { gql } from "@apollo/client";

const ADD_PAGE = gql`
  mutation addPages($input: [pages_insertion_data!]) {
    addpages(input: $input) {
      success
      error
    }
  }
`;

export default ADD_PAGE;
