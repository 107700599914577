import { gql } from "@apollo/client";

const CONNECT_TWILIO_PHONE = gql`
  mutation connectTwilioPhoneNumber(
    $accountSID: String!
    $authToken: String!
    $phoneNumber: String!
  ) {
    connectTwilioPhoneNumber(
      accountSID: $accountSID
      authToken: $authToken
      phoneNumber: $phoneNumber
    ) {
      success
      error
    }
  }
`;

export default CONNECT_TWILIO_PHONE;
