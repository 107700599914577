/* eslint-disable prettier/prettier */
import { Box, Skeleton } from "@mui/material";
import useStyles from "./Styles";

const ListSkeleton = () => {
    const data = [1, 2, 3, 4];
    const { classes } = useStyles();

    return (
        <Box className={classes.skeletonBox}>
            {data.map((item) => (
                <Box key={item} className={classes.itemSkeleton}>
                    <Skeleton animation="wave" variant="rounded" className={classes.avatar} />
                    <Skeleton animation="wave" variant="text" sx={{ marginLeft: 1 , width: "100%" }} />
                </Box>
            ))}
        </Box>
    );
};

export default ListSkeleton;
