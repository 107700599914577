import { makeStyles } from "tss-react/mui";

const useStyle = makeStyles()((theme) => ({
  DeleteIcon: {
    textAlign: "center",
  },
  dialog: {
    [theme.breakpoints.down("sm")]: {
      width: 320,
    },
  },
  content: {
    padding: "25px 35px 25px 35px",
  },
  deleteIconStyle: {
    color: "#E34D59",
    fontSize: "55px",
  },
  headingStyle: {
    // fontSize: '16px',
    fontSize: "20px",
    fontWeight: "500",
    lineHeight: "24px",
    textAlign: "center",
    margin: "10px 0px 5px 0px",
  },
  textStyle: {
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
    textAlign: "center",
  },
  buttonStyle: {
    // height: '40px',
    // width: '30%',
    borderRadius: "3px",
    textTransform: "capitalize",
    height: "32px",
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: "400",
    // width: "135px",
    minWidth: "135px",
    width: "fit-content",
    [theme.breakpoints.down("sm")]: {
      minWidth: "50px",
    },
  },
  deleteButton: {
    // background: "#E34D59",
    background: "#4d1277",
    color: "#FFFFFF",
    "&:hover": {
      background: "#4d1277",
      color: "#FFFFFF",
    },
    textTransform: "uppercase",
  },
  cancelButton: {
    background: "#F5F5F5",
    color: "#777777",
    "&:hover": {
      background: "#F5F5F5",
      color: "#777777",
    },
    borderRadius: "3px",
    // textTransform: "capitalize",
    textTransform: "uppercase",
    height: "32px",
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: "400",
  },
}));

export default useStyle;
