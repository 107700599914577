import React from "react";
import { Box, Typography, Button, Avatar } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import { User } from "../types";

interface ConnectedFbProps {
  user: User;
  logout: () => void;
  handleAddMorePages: () => void;
}

const ConnectedFb: React.FC<ConnectedFbProps> = ({
  user,
  logout,
  handleAddMorePages,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Avatar
        alt={user?.name || ""}
        src={user?.picture?.data?.url || ""}
        sx={{ width: 60, height: 60 }}
      />
      <Box mb={1} sx={{ maxWidth: "100%" }}>
        <Typography
          variant="h6"
          noWrap
          sx={{
            maxWidth: 350,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {user?.name || ""}
        </Typography>
      </Box>

      <Button variant="outlined" onClick={logout}>
        Disconnect
      </Button>

      <Box
        sx={{
          marginTop: theme.spacing(2),
        }}
      >
        <Button
          variant="contained"
          onClick={handleAddMorePages}
          startIcon={<AddIcon />}
        >
          Add pages
        </Button>
      </Box>
    </Box>
  );
};

export default ConnectedFb;
