/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/prefer-default-export */
/* eslint-disable prefer-const */
import expressConfig from "src/config/express.json";

declare global {
  interface Window {
    fbAsyncInit: any;
  }
}
export const FacebookFunc = {
  fbInt() {
    const env = process.env.NODE_ENV || "development";
    const config = expressConfig[env];

    window.fbAsyncInit = function () {
      window.FB.init({
        appId: config.facebook_app_id,
        cookie: true, // enable cookies to allow the server to access
        // the session
        xfbml: true, // parse social plugins on this page
        version: "v20.0", // use version 2.1
      });
    };

    //
    // const sessionInfoListener = (event: any) => {
    //   if (
    //     event.origin !== "https://www.facebook.com" &&
    //     event.origin !== "https://web.facebook.com"
    //   ) {
    //     return;
    //   }

    //   try {
    //     const data = JSON.parse(event.data);
    //     if (data.type === "WA_EMBEDDED_SIGNUP") {
    //       // if user finishes the Embedded Signup flow
    //       if (data.event === "FINISH") {
    //         // eslint-disable-next-line @typescript-eslint/naming-convention
    //         const { phone_number_id, waba_id } = data.data;
    //         console.log("finished data", phone_number_id, waba_id);
    //       }
    //       // if user cancels the Embedded Signup flow
    //       else {
    //         // eslint-disable-next-line @typescript-eslint/naming-convention
    //         const { current_step } = data.data;
    //         console.log("next step", current_step);
    //       }
    //     }
    //   } catch {
    //     // Don’t parse info that’s not a JSON
    //     console.log("Non JSON Response", event.data);
    //   }
    // };
    // window.addEventListener("message", sessionInfoListener);
    //
    // Load the SDK asynchronously
    (function (d, s, id) {
      let js: any;
      const fjs: any = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  },
};
