import { gql } from "@apollo/client";

const REMOVE_USER_LABEL = gql`
  mutation removeUserLabel($labelId: ID!) {
    removeUserLabel(labelId: $labelId) {
      success
      error
      result
    }
  }
`;

export default REMOVE_USER_LABEL;
